import { computed } from 'vue';
import { useStore } from 'vuex';
import useFormatter from '@/composables/formatter';
import useSearch from '@/composables/search';

export default function useMatchBets() {
  const store = useStore();
  const {
    formatBetNameAndOutcomes,
    formatBetOutcomesName,
    formatBetSpecialValue,
    sortBetsBySpecialValue,
  } = useFormatter();
  const { searchQuery } = useSearch('matchBet');

  const selectedBetGroupId = computed(() => store.getters.selectedBetGroupId);

  const matchBetInSearch = (bet) => {
    if (!searchQuery.value) return true;

    const betName = `${bet.betName} ${bet.name}`;
    return _.includes(_.toLower(betName), searchQuery.value);
  };

  const isBetInactive = (baseBet, matchBet) => (
    !matchBet.mbActive
    || !baseBet.betActive
    || _.isEmpty(matchBet.mbOutcomes));

  const getMatchBetsToDisplay = (baseSportBets, matchBets) => {
    const uniqueBets = [];
    const groupedBets = {};

    _.each(matchBets, (matchBet) => {
      const baseSportBet = baseSportBets.value[matchBet.idBet];

      if (isBetInactive(baseSportBet, matchBet)) return;

      _.assign(matchBet.mbOutcomes, formatBetOutcomesName(matchBet.mbOutcomes, baseSportBet));
      _.assign(matchBet, formatBetNameAndOutcomes(baseSportBet, matchBet));

      if (baseSportBet.betGroupingEnabled) {
        if (!groupedBets[matchBet.idBet]) groupedBets[matchBet.idBet] = [];
        groupedBets[matchBet.idBet].push(matchBet);

        if (!_.find(uniqueBets, { idBet: matchBet.idBet })) uniqueBets.push(matchBet);
        return;
      }
      uniqueBets.push(matchBet);
    });
    _.each(uniqueBets, (matchBet) => {
      _.assign(matchBet, formatBetSpecialValue(matchBet));

      if (!_.isEmpty(groupedBets[matchBet.idBet])) {
        _.assign(matchBet, {
          grouped: true,
          groupedBets: sortBetsBySpecialValue(groupedBets[matchBet.idBet]),
        });
      }
    });
    return _.orderBy(
      _.filter(uniqueBets, (uniqueBet) => uniqueBet.mbActive && matchBetInSearch(uniqueBet)),
      ['mbPosition', 'sv'],
    );
  };

  const getBetGroupsForSport = (idSport, bets) => (_.orderBy(
    _.filter(
      store.getters.betGroups,
      (group) => (group.idSport) === _.parseInt(idSport)
        && group.betGroupActive
        && _.some(group.bets, (id) => _.find(bets.value, { idBet: id })),
    ), ['betGroupPosition', 'betGroupShortName', 'betGroupName'],
  ));

  const betAssistEnabled = computed(() => store.getters.config?.srWidgets?.betAssist.enabled);

  const getBetAssistName = (idSport, idBet) => _.find(
    store.getters.betsBySport(idSport), { idBet },
  )?.betAssist?.name || '';

  return {
    getBetGroupsForSport,
    getMatchBetsToDisplay,
    selectedBetGroupId,
    betAssistEnabled,
    getBetAssistName,
  };
}
