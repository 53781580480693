import axios from 'axios';
import routes from './env';

const TC_DOMAIN = 'SportsbookSM.Web';

const getUrl = (language, companyUuid) => {
  const baseUrl = routes.tc;
  const lang = `/languages/${language}`;
  const url = `${baseUrl}/domains/${TC_DOMAIN}${lang}`;
  return companyUuid ? `${url}/clients/${companyUuid}` : url;
};

export default {
  get({ language, companyUuid }) {
    const url = getUrl(language, companyUuid);
    return axios.get(url)
      .then((response) => response.data)
      .catch((err) => {
        console.warn('Error getting translations: ', err.toJSON());
        return err.toJSON();
      });
  },
  TC_DOMAIN,
};
