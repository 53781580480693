<template>
  <div class="app-loader">
     <div class="app-loader-spinner"/>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
};
</script>
