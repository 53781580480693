<template>
  <div class="generated-ticket-system-combinations-group">
    <SystemsGroupHeader :combinations-header-columns="combinationsHeaderColumns" />

    <div class="generated-ticket-system-combinations-group-items">
      <SystemsGroupItem
        v-for="(ticketCombinationGroup, i) in ticketCombinationGroups"
        :key="i"
        :ticket-combination-group="ticketCombinationGroup"
        :combinations-header-columns="combinationsHeaderColumns"
        :number-of-bankers="numberOfBankers"
      />
    </div>
  </div>
</template>

<script>
import SystemsGroupHeader from './SystemsGroupHeader';
import SystemsGroupItem from './SystemsGroupItem';

export default {
  name: 'SystemsGroup',
  props: {
    ticketCombinationGroups: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    numberOfBankers: {
      type: Number,
      required: true,
    },
  },
  components: {
    SystemsGroupHeader,
    SystemsGroupItem,
  },
  setup() {
    const combinationsHeaderColumns = [
      'system',
      'combination',
      'general_pay_in',
      'min_winning',
      'max_winning',
    ];

    return { combinationsHeaderColumns };
  },
};
</script>
