<template>
  <div class="eventview">
    <div
      class="eventview-aside scrollbar"
      ref="eventviewSidebar">
      <EventviewSidebar :scroll-buttons-enabled="scrollButtonsEnabled" />
    </div>
    <div :class="['eventview-content', { 'lmt scrollbar': isLmtRdy }]">
      <EventviewHeader :match="match"/>
      <MatchTracker
        v-if="isLmtVisible"
        id="match-tracker-eventview" />
      <EventviewBetGroups
        v-if="displayedBets.length"
        :sport-bet-groups="sportBetGroups"
        :id-sport="match.idSport"
      />
      <EventviewBets
        v-if="displayedBets.length"
        :selected-bet-group="selectedBetGroup || {}"
        :match-betting-status="match.bettingStatus"
        :id-match="match.idMatch"
        :bets="displayedBets"
        :betslip-button-visible="betslipButtonVisible"
        :class="{'bottom-padding': betslipButtonVisible && !scrollButtonsEnabled }"
        :scroll-buttons-enabled="scrollButtonsEnabled"
        :id-sport="match.idSport"
        :all-bets-count="allBetsCount"
      />
      <EmptyListMessage
        v-if="!displayedBets.length"
        message="no_match_active_bets" />
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  onActivated,
  onDeactivated,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import useMatches from '@/composables/matches';
import useMatchBets from '@/composables/matchBets';
import { useEventviewMatchFocus } from '@/composables/matchFocus';
import EmptyListMessage from '@/components/common/EmptyListMessage';
import EventviewSidebar from './sidebar/EventviewSidebar';
import EventviewHeader from './header/EventviewHeader';
import EventviewBetGroups from './bet-groups/EventviewBetGroups';
import EventviewBets from './bets/EventviewBets';
import MatchTracker from '@/components/match-tracker/MatchTracker';
import { useScrollButtons } from '@/composables/scrollButtons';
import useBreakpoints from '@/composables/breakpoints';
import useLiveMatchTracker from '@/composables/liveMatchTracker';

export default {
  name: 'AppEventview',
  props: {
    betslipButtonVisible: {
      type: [Boolean, Number],
      required: true,
      default: false,
    },
  },
  components: {
    EmptyListMessage,
    EventviewSidebar,
    EventviewHeader,
    EventviewBetGroups,
    EventviewBets,
    MatchTracker,
  },
  setup() {
    const store = useStore();
    const { firstMatch } = useMatches();
    const {
      getBetGroupsForSport,
      getMatchBetsToDisplay,
      selectedBetGroupId,
    } = useMatchBets();
    const { initFocusedMatchWatcher } = useEventviewMatchFocus();

    const eventviewSidebar = ref('');

    const selectedMatchId = computed(() => store.getters.selectedMatchId);

    const match = computed(() => {
      if (_.isEmpty(store.getters.matches)) {
        store.dispatch('setActiveView', 'overview');
        return {};
      }
      if (!selectedMatchId.value || !store.getters.matches[selectedMatchId.value]) {
        store.dispatch('unsubscribeMatch');

        if (!firstMatch.value) {
          store.dispatch('setActiveView', 'overview');
          return {};
        }
        // Select first match
        store.dispatch('subscribeMatch', {
          requireState: true,
          matches: [firstMatch.value.idMatch],
        });
        // Reset bet group
        store.dispatch('selectBetGroup', 'all');
      }
      return store.getters.matches[selectedMatchId.value];
    });

    const matchBets = computed(() => store.getters.matchesBets[match.value.idMatch]);
    const baseSportBets = computed(() => store.getters.betsBySport(match.value.idSport));
    const displayedBets = computed(() => getMatchBetsToDisplay(baseSportBets, matchBets.value));

    const sportBetGroups = computed(() => {
      if (!match.value) return [];
      return getBetGroupsForSport(match.value.idSport, displayedBets);
    });
    const selectedBetGroup = computed(() => _.find(sportBetGroups.value,
      { idBetGroup: selectedBetGroupId.value }));

    const activeView = computed(() => store.getters.activeView);

    const allBetsCount = computed(() => [
      activeView.value,
      displayedBets.value.length,
      selectedBetGroup.value]);

    const { scrollButtonsEnabled } = useScrollButtons();
    initFocusedMatchWatcher(eventviewSidebar);

    onActivated(() => {
      store.dispatch('selectBetGroup', 'all');
      store.dispatch('setFocusedMatchSportId', { idSport: match.value.idSport, animationPrevented: false });
      store.dispatch('setFocusedMatch', { idMatch: match.value.idMatch, animationPrevented: false });
    });

    onDeactivated(() => {
      store.dispatch('unsubscribeMatch');
    });

    const displayedBetsHasLength = computed(() => !!displayedBets.value.length);
    watch(displayedBetsHasLength, () => {
      store.dispatch('setIsContentScrolled', displayedBetsHasLength.value);
    });

    const { liveMatchTrackerEnabled, isLmtRdy } = useLiveMatchTracker();
    const { breakpoints } = useBreakpoints();
    const isLmtVisible = computed(() => (
      liveMatchTrackerEnabled.value
      && selectedMatchId.value
      && breakpoints.value.mediumAndBellow
    ));

    return {
      displayedBets,
      match,
      selectedBetGroup,
      sportBetGroups,
      eventviewSidebar,
      scrollButtonsEnabled,
      allBetsCount,
      isLmtVisible,
      isLmtRdy,
      liveMatchTrackerEnabled,
      selectedMatchId,
    };
  },
};
</script>
