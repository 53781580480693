<template>
  <component :is="themeComponent">
    <slot v-for="(index, name) in $slots" :name="name" />
  </component>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useTheme from '@/composables/theme';
import queryParser from '@/utils/queryParser';

export default {
  name: 'ThemeProvider',
  setup() {
    const theme = queryParser.getUrlParameter('theme') || 'dark';
    const companyName = queryParser.getUrlParameter('company') || 'prvafirma';
    const companyUuid = queryParser.getUrlParameter('companyUuid') || '6f70074d-28f5-4941-89ba-2199d794cb9f';

    const { setThemeComponent } = useTheme();

    const themeComponent = setThemeComponent(theme, companyName, companyUuid);

    return {
      themeComponent: defineAsyncComponent(themeComponent),
    };
  },
};
</script>

<style lang="scss">
</style>
