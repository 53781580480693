<template>
  <div class="ticket-check-header">
    <table>
      <tbody>
        <tr>
          <td>{{ $translate.key('sports_betting') }}</td>
          <td>{{ $translate.key('ticket_id') }}</td>
          <td>{{ $translate.key('payment') }}</td>
          <td v-if="ticket.payinTax">{{ $translate.key('payin_tax') }}</td>
          <td>{{ $translate.key('general_stake') }}</td>
        </tr>

        <tr>
          <td>{{ $translate.key(ticketTypeTransMapper[ticket.type]) }}</td>
          <td>{{ ticket.id }}</td>
          <td>{{ ticket.payin.toFixed(2) }}</td>
          <td v-if="ticket.payinTax">{{ ticket.payinTax.toFixed(2) }}</td>
          <td>{{ ticket.payinTax ? ticket.bet : ticket.payin?.toFixed(2) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ticketTypeTransMapper: {
        1: 'combo',
        2: 'system',
        3: 'single',
      },
    };
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
};
</script>
