<template>
  <div class="server-icons">
    <div
      v-if="resultLabel"
      class="team"
    ></div>

    <div
      class="team"
      v-for="team in teams"
      :key="team.id"
    >
      <i
        class="icon icon-circle-b"
        :class="{'visible': team.isServer}"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServerIcons',
  props: {
    teams: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    resultLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
