import { computed, watch } from 'vue';
import { useStore } from 'vuex';

import comboCalculations from '@/services/ticket-calculations/comboTicket';
import singleCalculations from '@/services/ticket-calculations/singleTicket';
import systemCalculations from '@/services/ticket-calculations/systemTicket';
import { getTotalOdds, getTax, getSingleStake } from '@/services/ticket-calculations/helper';

import useBetslip from './betslip';

export default function useTicketCalculations() {
  const store = useStore();
  const {
    betBuilderMinBetCountValid, betBuilderOdd, orderedBets, selectedTicketTypeId, stake,
  } = useBetslip();

  const betslipConfig = computed(() => store.getters.betslipConfig);
  const bonusConfig = computed(() => store.getters.bonusConfig);
  const bonusType = computed(() => bonusConfig.value?.type);
  const oddPercentageBonus = computed(() => _.toUpper(bonusType.value) === 'ODD_PERCENTAGE_BONUS');
  const taxPolicy = computed(() => betslipConfig.value?.taxes?.payin?.policy);
  const payoutTaxPolicy = computed(() => betslipConfig.value?.taxes?.payout?.policy);
  const ticketSystems = computed(() => store.getters.ticketSystems);
  const winningsBonusConfig = computed(() => (
    oddPercentageBonus.value ? bonusConfig.value.oddCountRules : undefined
  ));

  const calculateComboWinnings = () => (
    comboCalculations.calculateComboWinnings({
      betslipConfig: betslipConfig.value,
      stake: stake.value,
      bets: orderedBets.value,
      bonusConfig: winningsBonusConfig.value,
      betBuilderOdd: betBuilderOdd.value,
    })
  );

  const calculateSystemWinnings = () => (
    systemCalculations.calculateSystemWinnings({
      bets: orderedBets.value,
      systems: ticketSystems.value,
      taxPolicy: taxPolicy.value,
      stake: stake.value,
      bonusConfig: winningsBonusConfig.value,
    })
  );

  const calculateSingleWinnings = () => (
    singleCalculations.calculateSingleWinnings({
      stake: stake.value,
      bets: orderedBets.value,
      betslipConfig: betslipConfig.value,
      bonusConfig: winningsBonusConfig.value,
    })
  );

  const winnings = computed(() => {
    const emptyWinings = { max: 0, min: 0, bonus: 0 };

    // Reset winnings for BetBuilder Bets if Min Bet Rule not valid
    if (betBuilderOdd.value && !betBuilderMinBetCountValid.value) {
      return emptyWinings;
    }
    if (selectedTicketTypeId.value === 1) {
      return calculateComboWinnings();
    }
    if (selectedTicketTypeId.value === 2) {
      return calculateSystemWinnings();
    }
    if (selectedTicketTypeId.value === 3) {
      return calculateSingleWinnings();
    }
    return emptyWinings;
  });

  const totalOdds = computed(() => {
    // Reset odds for BetBuilder Bets if Min Bet Rule not valid
    if (betBuilderOdd.value && !betBuilderMinBetCountValid.value) return 0;
    return getTotalOdds(orderedBets.value, betBuilderOdd.value);
  });

  const payinTax = computed(() => getTax(taxPolicy.value, stake.value));

  const payoutTax = computed(() => getTax(payoutTaxPolicy.value, (winnings.value?.max || 0)));

  const singleStake = computed(() => {
    if (!stake.value) return 1; // Set default real value after rules are implemented
    return getSingleStake({
      stake: stake.value,
      betsLength: orderedBets.value.length,
      bonusConfig: winningsBonusConfig.value,
    });
  });

  watch(
    () => winnings.value.max,
    () => {
      store.dispatch('updateBetslipRulesNotifications', winnings.value.max);
    },
  );

  return {
    payinTax,
    payoutTax,
    totalOdds,
    winnings,
    singleStake,
  };
}
