const companiesByUuid = {
  'b99752b3-443c-4c80-b559-945a95c4b805': 'prvafirma',
  'fa4bb98e-a314-4389-b7c1-75f41eac2595': 'demo',
  '4f54c6aa-82a9-475d-bf0e-dc02ded89225': 'balkanbet',
  'fb68cb4c-0002-42fb-abc3-1cdd7aea0356': 'superbetro',
  '0fb6d44f-ba05-451c-8d20-9850d03c0578': 'superbetpoland',
  '86afd4cc-c0ab-4745-bf04-e8e7f10839f6': 'livebet1x2',
  '92aaebf9-4fb3-4863-98ee-eb75e48fbf21': 'senator',
  '6c78d15c-4421-40e7-92f0-4def495b1a18': 'bingobet',
  '4b737004-ec63-4e05-8bdc-3e09c1b31e29': 'totalbet',
  '04301c5a-6b6c-4694-aaf5-f81bf665498c': 'winner',
  '18563d1c-7b9d-403c-af32-e1c285ca33e0': 'powerbet',
  '682e6a38-b5ad-4c58-a743-3b06c79e55cd': 'stanleybet',
};

export default {
  getCompanyByUuid(cpvUuid) {
    return companiesByUuid[cpvUuid];
  },
};
