<template>
  <div
    class="overview-header-bet"
    @click.stop="toggleSelectionModal"
  >
    <span class="overview-header-bet-name">
      {{ removeBetNamePlaceholders(displayedBet.betShortName || displayedBet.betName) }}
    </span>

    <i
      v-if="hasMoreBets"
      class="icon icon-arrow-down-a"
    />

    <SelectionModal
      v-if="selectionModalOpen && hasMoreBets"
      header-title="select_market"
      :options="arrangedDisplayedBets"
      @select-option="selectBetOption"
      @close-selection-modal="toggleSelectionModal">
      <template #option="{ option }">
        <i :class="['icon icon-circle',
          {'icon-circle-a active': displayedBet.idBet === option.idBet}]" />
        <span class="selection-modal-body-meta-label">
          {{ removeBetNamePlaceholders(option.betShortName || option.betName) }}
        </span>
      </template>
    </SelectionModal>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import useSportBets from '@/composables/sportBets';
import SelectionModal from '@/components/common/SelectionModal';
import formatter from '@/services/formatter';

export default {
  name: 'OverviewHeaderBet',
  components: {
    SelectionModal,
  },
  props: {
    displayedBet: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    hasMoreBets: {
      type: Boolean,
      required: true,
    },
    sportId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      moreBets,
      selectDisplayedBet,
    } = useSportBets(props.sportId);

    const selectionModalOpen = ref(false);
    const toggleSelectionModal = () => {
      selectionModalOpen.value = !selectionModalOpen.value;
    };

    const selectBetOption = (option) => {
      selectDisplayedBet(option);
      toggleSelectionModal();
    };

    const arrangedDisplayedBets = computed(() => _.orderBy(
      moreBets.value,
      ['betPosition', 'betShortName' || 'betName'],
      ['asc'],
    ));

    return {
      selectBetOption,
      arrangedDisplayedBets,
      selectionModalOpen,
      toggleSelectionModal,
      removeBetNamePlaceholders: formatter.removeBetNamePlaceholders,
    };
  },
};
</script>
