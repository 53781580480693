import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useLiveMatchTracker() {
  const store = useStore();

  const activeView = computed(() => store.getters.activeView);
  const liveMatchTrackerEnabled = computed(() => store.getters.liveMatchTrackerEnabled);
  const liveMatchTrackerActive = computed(() => store.getters.liveMatchTrackerActive);
  const liveMatchTrackerError = computed(() => store.getters.liveMatchTrackerError);

  const isLmtRdy = computed(() => liveMatchTrackerEnabled.value
      && activeView.value === 'eventview'
      && !liveMatchTrackerError.value
      && liveMatchTrackerActive.value);

  return {
    liveMatchTrackerEnabled,
    liveMatchTrackerActive,
    liveMatchTrackerError,
    isLmtRdy,
  };
}
