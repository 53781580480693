import statusMapper from '@/utils/statusMapper';

export default function useStatusFormatter() {
  const getStatusColor = (status) => {
    const currentIcon = statusMapper[status];

    switch (currentIcon) {
      case 'close-o': return 'status-lost';
      case 'check': return 'status-won';
      case 'won-void': return 'status-half-won';
      case 'lost-void': return 'status-half-lost';
      case 'whistle-a': return 'status-void';
      default: return 'status-open';
    }
  };

  return {
    getStatusColor,
  };
}
