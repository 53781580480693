<template>
  <div
    class="eventview-bet-group-additional"
    :class="{'selected': selectedBetGroupId === selectedAdditionalGroup.idBetGroup}"
    @click.prevent="toggleSelectionModal">
    <span>
      {{ selectedAdditionalGroup.betGroupName || selectedAdditionalGroup.betGroupShortName }}
      <i class="icon icon-arrow-down" />
    </span>
    <SelectionModal
      v-if="selectionModalOpen"
      header-title="select_market"
      :options="betGroups"
      @select-option="selectBetGroup"
      @close-selection-modal="toggleSelectionModal">
      <template #option="{ option }">
        <i :class="['icon icon-circle',
          {'icon-circle-a active': selectedAdditionalGroup.idBetGroup === option.idBetGroup}]" />
        <span class="selection-modal-body-meta-label">
          {{ option.betGroupName || option.betGroupShortName }}
        </span>
      </template>
    </SelectionModal>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import useMatch from '@/composables/match';
import useMatchBets from '@/composables/matchBets';
import SelectionModal from '@/components/common/SelectionModal';

export default {
  name: 'EventviewBetGroupAdditional',
  components: {
    SelectionModal,
  },
  props: {
    betGroups: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  setup() {
    const store = useStore();
    const { selectedMatchId } = useMatch();
    const { selectedBetGroupId } = useMatchBets();

    const selectionModalOpen = ref(false);
    const toggleSelectionModal = () => {
      selectionModalOpen.value = !selectionModalOpen.value;
    };

    const additionalGroupPlaceholder = {
      idBetGroup: 'more',
      betGroupShortName: store.getters.translate('general_more'),
    };
    const selectedAdditionalGroup = ref(additionalGroupPlaceholder);

    const selectBetGroup = (group) => {
      if (!group) return;

      store.dispatch('selectBetGroup', group.idBetGroup);
      selectedAdditionalGroup.value = group;
      toggleSelectionModal();
    };

    watch(
      () => selectedMatchId.value,
      () => {
        selectedAdditionalGroup.value = additionalGroupPlaceholder;
      },
    );

    return {
      selectBetGroup,
      selectedAdditionalGroup,
      selectedBetGroupId,
      selectionModalOpen,
      toggleSelectionModal,
    };
  },
};
</script>
