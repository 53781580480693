// DISCLAIMER: BetBuilder was formerly known as CustomBet.
import socketio from 'socket.io-client';
import routes from './env';

let socket = {
  disconnect: () => {},
};
let company = null;
let feed = null;
let lang = null;
let storeDispatch = null;

export default {
  init({
    companyUuid, feedId, language, dispatch,
  }) {
    company = companyUuid;
    feed = feedId;
    lang = language;
    storeDispatch = dispatch;
    this.connect();
  },
  connect() {
    const url = routes.betBuilder;

    if (!company || !feed) return;

    const socketOptions = {
      transports: ['websocket'],
      path: '/custombet/live/api/v1/calculation/client/distribution/sio2',
      query: `companyUuid=${company}&feedId=${feed}&language=${lang}`,
      forceNew: true,
    };

    socket = socketio(url, socketOptions);
    socket.connect();
    socket.on('connect', () => {
      this.subscribeSelections();
      console.log('Bet builder connected');
    });
    socket.on('reconnect', () => {
      console.log('Bet builder reconnect');
    });
    socket.on('current_selections', (data) => {
      const formattedEventSelections = _.reduce(data?.eventSelections,
        (eventSelectionsObj, event) => {
          // eslint-disable-next-line no-param-reassign
          eventSelectionsObj[event.id] = event;
          return eventSelectionsObj;
        }, {});
      storeDispatch('setBetBuilderEventSelection', formattedEventSelections);
    });
    socket.on('event_selections', (data) => {
      storeDispatch('updateBetBuilderEventSelection', data);
    });
    socket.on('event_calculation', (data) => {
      storeDispatch('setBetBuilderEventCalculation', data);
    });
    socket.on('subscribe_error', (data) => {
      console.log('Subscribe error: ', data);
    });
    socket.on('disconnect', () => {
      console.log('Bet builder disconnected');
    });
  },
  subscribeSelections() {
    socket.emit('subscribe_selections', {});
  },
  subscribeCalculation(payload) {
    socket.emit('subscribe_calculation', payload);
  },
  unsubscribeCalculation(id) {
    socket.emit('unsubscribe_calculation', { id });
  },
  disconnect() {
    socket.disconnect();
  },
  reconnect() {
    if (socket) {
      this.disconnect();
      this.connect();
    }
  },
};
