import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useSearch(type) {
  const store = useStore();
  const searchQueryStore = {
    matchName: {
      action: 'setMatchNameSearchQuery',
      getter: 'matchNameSearchQuery',
    },
    matchBet: {
      action: 'setMatchBetSearchQuery',
      getter: 'matchBetSearchQuery',
    },
  };
  const searchTypeByView = {
    overview: 'matchName',
    eventview: 'matchBet',
  };

  const activeView = computed(() => store.getters.activeView);
  const searchType = computed(() => type || searchTypeByView[activeView.value]);

  const searchQueryAction = computed(() => searchQueryStore[searchType.value].action);
  const searchQueryGetter = computed(() => searchQueryStore[searchType.value].getter);
  const searchQuery = computed(() => store.getters[searchQueryGetter.value]);
  const searchNotEmpty = computed(() => searchQuery.value !== '');

  const clearSearch = () => store.dispatch(searchQueryAction.value, '');
  const setSearchQuery = (value) => store.dispatch(searchQueryAction.value, value);

  return {
    clearSearch,
    searchNotEmpty,
    searchQuery,
    setSearchQuery,
  };
}
