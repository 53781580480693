<template>
  <div class="overview">
    <div class="overview-aside scrollbar">
      <OverviewSidebar />
       <ScrollButtons
        v-if="scrollButtonsEnabled"
        :key="sportMatchesCount"
        scroll-content="overview-aside" />
    </div>
    <div
      ref="overviewContent"
      class="overview-content scrollbar"
      :class="[
        {'bottom-padding': betslipButtonVisible && !scrollButtonsEnabled },
        {'back-to-top-padding': !scrollButtonsEnabled && isScrolled}]"
    >
        <OverviewSport
          v-for="sport in selectedSportWithMatches"
          :key="sport.id"
          :sport="sport"
          :enable-sport-color="enableSportColor"
          :focused-match="focusedMatch"
        />
        <EmptyListMessage
          v-if="emptyListMessage"
          :message="emptyListMessage" />
        <ScrollButtons
          v-if="scrollButtonsEnabled"
          :key="sportMatchesCount"
          :class="{'bottom-position-adjust': betslipButtonVisible }"
          scroll-content="overview-content"
          :has-scroll-to-top="true" />
    </div>
  </div>
</template>

<script>
import {
  provide, computed, ref, watchEffect, watch, onActivated,
} from 'vue';
import OverviewSidebar from './sidebar/OverviewSidebar';
import OverviewSport from './OverviewSport';
import EmptyListMessage from '@/components/common/EmptyListMessage';
import ScrollButtons from '@/components/common/ScrollButtons';
import useMatches from '@/composables/matches';
import useSports from '@/composables/sports';
import { useOverviewMatchFocus } from '@/composables/matchFocus';
import useBreakpoints from '@/composables/breakpoints';
import useViewScrollPosition from '@/composables/viewScrollPosition';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  name: 'AppOverview',
  props: {
    betslipButtonVisible: {
      type: [Boolean, Number],
      required: true,
      default: false,
    },
  },
  components: {
    OverviewSidebar,
    OverviewSport,
    EmptyListMessage,
    ScrollButtons,
  },
  setup() {
    const overviewContent = ref('');

    const {
      selectedSport, selectedSportWithMatches, sportMatchesCount, activeMatchesCount,
    } = useMatches();
    const { enableSportColor, setActiveSport } = useSports();
    const { focusedMatch, initFocusedMatchWatcher } = useOverviewMatchFocus();
    const { breakpoints } = useBreakpoints();
    const { applyOverviewScrollPosition } = useViewScrollPosition();
    const { scrollButtonsEnabled, isScrolled } = useScrollButtons();

    const displayedBetsNumber = computed(() => {
      const {
        small,
        medium,
        large,
        portraitSmall,
      } = breakpoints.value;

      if (small || portraitSmall) return 1;
      if (large || medium) return 2;

      return 3;
    });

    const matchCount = computed(() => (
      selectedSportWithMatches.value.filter((sport) => sport[activeMatchesCount.value] > 0).length
    ));

    const emptyListMessage = computed(() => {
      if (!selectedSportWithMatches.value?.length) return 'no_matches_available';
      if (matchCount.value === 0) return 'no_match_search_results';
      return '';
    });

    provide('displayedBetsNumber', displayedBetsNumber);

    initFocusedMatchWatcher(overviewContent);

    watchEffect(() => {
      if (selectedSport.value !== 'all'
        && !selectedSportWithMatches.value?.length) setActiveSport('all');
    });

    watch(selectedSport, () => {
      applyOverviewScrollPosition(true);
    });

    onActivated(() => {
      applyOverviewScrollPosition();
    });

    return {
      emptyListMessage,
      overviewContent,
      focusedMatch,
      enableSportColor,
      selectedSportWithMatches,
      scrollButtonsEnabled,
      sportMatchesCount,
      isScrolled,
    };
  },
};
</script>
