<template>
  <div
    class="betslip-toggle-button"
    :class="{ 'active': betslipOpen}"
    @click="toggleBetslip"
  >
    <div
      v-if="!betslipOpen"
      class="betslip-toggle-button-content"
    >
      <div class="betslip-toggle-button-winning">
        <div class="betslip-toggle-button-winning-label">{{ $translate.key('max_winning') }}</div>
        <div class="betslip-toggle-button-winning-value">{{ winnings.max.toFixed(2) }}</div>
      </div>
      <div class="betslip-toggle-button-bets-count">
        <i class="icon icon-betslip" />
        <span>{{ ticketBets.length }}</span>
      </div>
    </div>
    <i
      v-else
      class="icon icon-arrow-right"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';
import useTicketCalculations from '@/composables/ticketCalculations';

export default {
  name: 'BetslipToggleButton',
  props: {
    betslipOpen: {
      required: true,
    },
  },
  emits: ['handleBackToTopAnim'],
  setup(props, { emit }) {
    const store = useStore();
    const { ticketBets } = useBetslip();
    const { winnings } = useTicketCalculations();
    const toggleBetslip = () => {
      store.dispatch('toggleBetslip');
      emit('handleBackToTopAnim', !props.betslipOpen ? 'move-in' : 'move-out');
    };

    return {
      ticketBets,
      winnings,
      toggleBetslip,
    };
  },
};
</script>
