<template>
  <div :class="ratioClass">
    <div
      class="flag"
      :class="[`flag-${flagCode}`]"></div>
  </div>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    flagCode: {
      type: String,
      required: true,
    },
    ratioClass: {
      type: String,
      required: true,
      default: '0.5',
    },
  },
};
</script>
