export default {
  getErrorMessage(errorData) {
    if (_.isEmpty(errorData.details)) {
      return errorData.message || errorData.string || errorData;
    }

    const messages = [];
    const exceptions = errorData.details;

    _.each(exceptions, (exception) => {
      if (exception.message) {
        messages.push(exception.message);
      } else {
        if (_.isEmpty(exception)) return;
        messages.push(exception);
      }
    });

    if (_.isEmpty(messages)) messages.push(errorData.message);

    return messages.join(' ').trim();
  },
};
