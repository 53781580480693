import { computed } from 'vue';
import { useStore } from 'vuex';
import useFormatter from '@/composables/formatter';
import useNotifications from '@/composables/notifications';

export default function useBetslip() {
  const store = useStore();
  const { betslipRulesNotifications, noConnectionNotification } = useNotifications();
  const { formatTicketBet, formatRemovedMatchTicketBet } = useFormatter();

  const matches = computed(() => store.getters.matches);
  const appOnline = computed(() => store.getters.appOnline);
  const ticketTypes = computed(() => store.getters.ticketTypes);
  const ticketProcessing = computed(() => store.getters.ticketProcessing);
  const selectedTicketTypeId = computed(() => store.getters.selectedTicketTypeId);
  const betslipOddsOrStatusChanged = computed(() => store.getters.betslipOddsOrStatusChanged);

  const selectedTicketType = computed(() => (
    _.find(ticketTypes.value, { id: selectedTicketTypeId.value })
  ));

  const stake = computed(() => store.getters.stake);
  const updateStake = (({ target }) => store.dispatch('updateGlobalStake', target.value));

  const balance = computed(() => store.getters.terminal?.balance);
  const balanceValid = computed(() => parseFloat(balance.value) >= parseFloat(stake.value));

  const getMetaBets = (idSport) => store.getters.betsBySport(idSport);

  const matchesBets = computed(() => store.getters.matchesBets);

  const ticketBets = computed(() => store.getters.ticketBets);

  const formattedTicketBets = computed(() => (
    _.map(ticketBets.value, (bet) => {
      if (bet.removedMatch) {
        return formatRemovedMatchTicketBet(bet);
      }
      const match = matches.value?.[bet.idMatch];
      const metaBets = getMetaBets(match?.idSport);
      const matchBets = matchesBets.value[match.idMatch];
      const matchBet = _.find(matchBets, { idMb: bet.idMb }) || {};
      const idBet = matchBet ? matchBet.idBet : '';
      const metaBet = idBet ? metaBets[idBet] || {} : {};
      return formatTicketBet(match, bet, metaBet, matchBet);
    })
  ));
  const orderedBets = computed(() => _.orderBy(
    formattedTicketBets.value,
    [{ banker: true }, { isBetBuilderBet: true }], ['desc', 'desc'],
  ));

  const lockedBets = computed(() => _.filter(formattedTicketBets.value, { locked: true }));

  const betBuilderBet = computed(() => orderedBets.value[0]?.isBetBuilderBet);
  const betBuilderCalculation = computed(() => store.getters.betBuilderEventCalculation);
  const betBuilderOdd = computed(() => (
    betBuilderBet.value ? betBuilderCalculation.value?.odd : null));

  const betBuilderMinBetCountValid = computed(() => (orderedBets.value.length
    >= store.getters.betBuilderBetslipRulesValues.minBetCount));

  const ticketSystems = computed(() => store.getters.ticketSystems);
  const selectedSystems = computed(() => store.getters.selectedTicketSystems);

  const totalCombinations = computed(() => (
    selectedSystems.value?.reduce((acc, item) => acc + (item?.combinations || 0), 0)
  ));

  const bankersNumber = computed(() => store.getters.bankers.length);
  const nonBankersNumber = computed(() => store.getters.nonBankers.length);

  const rulesRestrictions = computed(() => {
    if (_.isEmpty(betslipRulesNotifications.value)) return false;

    const maxBetRule = _.find(betslipRulesNotifications.value, { id: 12 });
    const maxBetBuilderBetRule = _.find(betslipRulesNotifications.value, { id: 999 });

    // * This computed is used only in betslipBtnDisabled, so we made a lil' hack here. *
    // Allow displaying one betslip notification (`Max Bet Count` rule warning),
    // without disabling payin button.
    return !(betslipRulesNotifications.value.length === 1 && (maxBetRule || maxBetBuilderBetRule));
  });

  const betslipBtnDisabled = computed(() => {
    const betBuilderCalculationError = betBuilderBet.value
      && !betBuilderCalculation.value.available;

    return (selectedTicketType.value.system && !selectedSystems.value.length)
      || rulesRestrictions.value
      || ticketProcessing.value
      || !_.isEmpty(lockedBets.value)
      || betBuilderCalculationError;
  });

  const payinDisabled = computed(() => !balanceValid.value || betslipBtnDisabled.value);

  const showBetBuilderTicketTypeError = () => {
    store.dispatch('addNotification', {
      id: 'bet_builder_error_ticket_type',
      content: store.getters.translate('bet_builder_error_ticket_type'),
      type: 'error',
    });
  };

  const selectTicketType = (id) => {
    if (ticketProcessing.value) return;

    if (betBuilderBet.value && id !== 1) {
      showBetBuilderTicketTypeError();
      return;
    }

    store.dispatch('selectTicketType', id);
  };

  const clearBetslip = () => {
    if (ticketProcessing.value) return;
    store.dispatch('clearBetslip');
  };

  const payin = (bets, winnings) => {
    if (payinDisabled.value) return;

    if (!appOnline.value) {
      store.dispatch('addNotification', noConnectionNotification);
      return;
    }

    store.dispatch('handleTicketPayin', { bets, winnings });
  };

  const generateCode = (bets) => {
    if (betslipBtnDisabled.value) return;
    store.dispatch('handleGenerateCode', bets);
  };

  const isChangedBet = (bet) => {
    const {
      locked, oddChanged, originalOdd, newOdd,
    } = bet;

    const isOddChanged = (oddChanged === 'up' || oddChanged === 'down') && originalOdd !== newOdd;

    return locked || isOddChanged;
  };

  const moneyDenominations = computed(() => store.getters.moneyDenominations);

  const workingTimeBlocker = computed(() => store.getters.workingTimeBlocker);

  return {
    stake,
    betBuilderBet,
    betBuilderCalculation,
    betBuilderMinBetCountValid,
    betBuilderOdd,
    ticketBets,
    ticketTypes,
    orderedBets,
    bankersNumber,
    payinDisabled,
    ticketSystems,
    nonBankersNumber,
    ticketProcessing,
    totalCombinations,
    betslipBtnDisabled,
    selectedTicketType,
    selectedTicketTypeId,
    betslipOddsOrStatusChanged,
    isChangedBet,
    payin,
    updateStake,
    clearBetslip,
    generateCode,
    selectTicketType,
    moneyDenominations,
    balance,
    workingTimeBlocker,
  };
}
