export default {
  appSnoozed: false,
  activeTicket: null,
  activeView: 'overview',
  appOnline: true,
  authorizationToken: '',
  autoCheckerData: {
    count: 0,
    repeat: 5,
    interval: 15000,
    active: true,
  },
  balance: 0,
  betBuilderAvailableSelections: null,
  betBuilderBetslipRulesValues: {
    minBetCount: 2,
    maxBetCount: 10,
  },
  betBuilderEventCalculation: {},
  betBuilderEventSelection: {},
  betBuilderSelectedOutcomes: [],
  betGroups: {},
  betshop: {},
  betAssistPopupId: '',
  betslipConfig: {},
  workingTimeBlocker: '',
  betslipGeneralNotifications: [],
  betslipOddsOrStatusChanged: false,
  betslipRulesNotifications: [],
  betslipOpen: false,
  bonusConfig: [],
  cachedBetGroups: false,
  categories: {},
  company: {},
  config: {},
  cpvUuid: '',
  device: {},
  focusedMatch: {
    idMatch: '',
    animationPrevented: true,
  },
  focusedMatchSportId: {
    idSport: '',
    animationPrevented: true,
  },
  generatedTicket: {
    code: '',
    active: false,
    ticket: {},
  },
  gridViews: [
    {
      id: 1,
      name: 'overview',
    },
    {
      id: 2,
      name: 'eventview',
    },
  ],
  language: 'en',
  lastTickets: [],
  lastTicketsLoading: false,
  matches: {},
  matchBetSearchQuery: '',
  matchNameSearchQuery: '',
  moneyDenominations: [10, 20, 50],
  oddFormatterActive: false,
  oddType: 1,
  overviewScrollPosition: 0,
  pendingTicketRequestUuid: '',
  product: '',
  selectedBet: '',
  selectedBetGroupId: 'all',
  selectedMatchId: '',
  selectedSport: 'all',
  selectedTicketTypeId: 1,
  selectedTab: 'betslip',
  serverServiceTimeout: 30000,
  serverTimeoutId: null,
  sportDisplayedBets: {},
  sports: {},
  sportsBets: {},
  stake: '1.00',
  terminal: {},
  ticketBets: [],
  ticketProcessing: false,
  ticketSystems: [],
  tournaments: {},
  translations: {},
  useCachedBets: false,
  notifications: [],
  betslipNotifications: [],
  checkedTicket: {},
  isScrolled: false,
  resetTrigger: false,
  matchesOrder: [
    'tournamentPosition',
    'categoryName',
    'matchDateTime',
    'team1Name',
  ],
  operatorLoggedIn: false,
  companyName: '',
  matchesBets: {},
  matchesBySport: {},
  sportsWithMatches: {},
  liveMatchTracker: null,
  liveMatchTrackerError: false,
  liveMatchTrackerActive: true,
};
