import { computed } from 'vue';
import { useStore } from 'vuex';
import useSearch from '@/composables/search';
import useFormatter from '@/composables/formatter';

export default function useMatches() {
  const store = useStore();
  const { searchQuery } = useSearch('matchName');
  const {
    formatSportWithMatches, formatSidebarSportMatches, sortMatches, formatMatch,
  } = useFormatter();

  const selectedSport = computed(() => store.getters.selectedSport);

  const categories = computed(() => store.getters.categories);
  const categoryActive = ({ idCategory }) => categories.value?.[idCategory]?.categoryActive;

  const tournaments = computed(() => store.getters.tournaments);
  const tournamentActive = ({ idTournament }) => (
    tournaments.value?.[idTournament]?.tournamentActive
  );

  const matches = computed(() => store.getters.matches);

  // Note: Use enriched `formattedSportsWithMatches` in components, not this.
  const sportsWithMatches = computed(() => store.getters.sportsWithMatches);

  const activeMatchesCount = computed(() => (
    searchQuery.value ? 'filteredMatchesCount' : 'matchesCount'
  ));

  const matchNameInSearch = (match) => {
    if (!searchQuery.value) return true;

    const matchNames = `
      ${match.team1ShortName} ${match.team1Name}
      ${match.team2ShortName} ${match.team2Name}
    `;

    return matchNames.toLowerCase().includes(searchQuery.value);
  };

  const formattedSportsWithMatches = computed(() => {
    if (_.isEmpty(sportsWithMatches.value)) return [];
    return sportsWithMatches.value?.map(formatSportWithMatches);
  });

  const sidebarSports = computed(() => _.map(
    formattedSportsWithMatches.value, (sport) => ({
      ...sport,
      matches: formatSidebarSportMatches(sport.matches),
    }),
  ));

  const selectedSportWithMatches = computed(() => {
    if (selectedSport.value === 'all') return formattedSportsWithMatches.value;
    return _.filter(formattedSportsWithMatches.value, { id: selectedSport.value });
  });

  const allMatchesCount = computed(() => (
    _.reduce(formattedSportsWithMatches.value, (total, sport) => (
      total + sport[activeMatchesCount.value]
    ), 0)
  ));

  const firstMatch = computed(() => {
    const firstSportId = sportsWithMatches.value[0].idSport;
    const formattedMatches = store.getters.matchesBySport(firstSportId).map((match) => (
      formatMatch(match)
    ));
    const sortedMatches = sortMatches(formattedMatches);

    return _.first(sortedMatches);
  });

  const sportMatchesCount = computed(() => [
    store.getters.activeView,
    selectedSportWithMatches.value[activeMatchesCount.value]]);

  return {
    allMatchesCount,
    firstMatch,
    formattedSportsWithMatches,
    selectedSport,
    selectedSportWithMatches,
    sportMatchesCount,
    sidebarSports,
    matches,
    activeMatchesCount,
    categoryActive,
    tournamentActive,
    matchNameInSearch,
  };
}
