<template>
  <div class="generated-ticket">
    <GeneratedTicketHeader
      :product-name="generatedTicket.productName"
      :ticket-date-time="generatedTicket.ticketDateTime"
      :ticket-type-id="generatedTicket.ticketType.id"
      @close-modal="closeGeneratedTicket"
    />
    <GeneratedTicketBody
      :bets="generatedTicket.bets"
      :ticket-type-id="generatedTicket.ticketType.id"
      :total-odd="generatedTicket.totalOdd"
      :payin="generatedTicket.payin"
      :bonus="generatedTicket.ticketBonus"
      :payin-tax="generatedTicket.payinTax"
      :max-possible-win="generatedTicket.maxPossibleWin"
      :max-possible-payout-tax="generatedTicket.maxPossiblePayoutTax"
      :ticket-combination-groups="generatedTicket.ticketCombinationGroups"
    />
    <GeneratedTicketFooter :generated-code="generatedTicketCode" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import GeneratedTicketHeader from './GeneratedTicketHeader';
import GeneratedTicketFooter from './GeneratedTicketFooter';
import GeneratedTicketBody from './GeneratedTicketBody';

export default {
  name: 'GeneratedTicket',
  components: {
    GeneratedTicketHeader,
    GeneratedTicketFooter,
    GeneratedTicketBody,
  },
  setup() {
    const store = useStore();

    const generatedTicketData = computed(() => store.getters.generatedTicket);

    const generatedTicket = computed(() => generatedTicketData.value?.ticket);
    const generatedTicketCode = computed(() => generatedTicketData.value?.code);

    const closeGeneratedTicket = () => store.dispatch('toggleGeneratedTicket');

    return {
      generatedTicket,
      generatedTicketCode,
      closeGeneratedTicket,
    };
  },
};
</script>
