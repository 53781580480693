<template>
  <div :class="[
    'match-tracker_container',
    { 'active': breakpoints.mediumAndBellow
        && liveMatchTrackerActive
        && !liveMatchTrackerError },
  ]">
    <div class="match-tracker_tab">{{ $translate.key('visualization') }}</div>
    <div v-bind="$attrs">
    </div>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import useBreakpoints from '@/composables/breakpoints';
import useLiveMatchTracker from '@/composables/liveMatchTracker';

export default {
  inheritAttrs: false,
  setup(props, { attrs }) {
    const store = useStore();

    const companyName = computed(() => store.getters.companyName);
    const liveMatchTracker = computed(() => store.getters.liveMatchTracker);
    const {
      liveMatchTrackerEnabled,
      liveMatchTrackerActive,
      liveMatchTrackerError,
    } = useLiveMatchTracker();
    const selectedMatchId = computed(() => store.getters.selectedMatchId);
    const selectedSport = computed(() => store.getters.matches[selectedMatchId.value].idSport);
    const lmtBanners = computed(() => store.getters.config?.lmtBanners ?? false);
    const bannerImageUrl = computed(() => api.getLmtBannersCmsUrl(companyName.value));
    const isWidgetSilent = computed(() => store.getters.config?.srWidgets?.silent ?? true);

    const { breakpoints } = useBreakpoints();

    const options = computed(() => {
      const layout = !breakpoints.value.mediumAndBellow ? 'topdown' : 'double';
      let opt = {
        matchId: selectedMatchId.value,
        onTrack: (actionType) => {
          nextTick(() => {
            const lmtErrorContainer = !!document.querySelector('.sr-error__container') && actionType !== 'tab_click';
            const lmtWidget = document.querySelector('.srm-isLmt');

            store.dispatch('setliveMatchTrackerError', lmtWidget ? false : lmtErrorContainer);
          });
        },
        layout,
        tabsPosition: layout === 'topdown' ? 'bottom' : 'top',
        ...liveMatchTracker.value?.options,
      };

      if (layout === 'topdown') {
        if (lmtBanners.value) {
          opt = {
            ...opt,
            pitchLogo: `${bannerImageUrl.value}/lmt-pitch-logo.png`,
            goalBannerImage: `${bannerImageUrl.value}/lmt-sideline-goal-banner.png`,
            logo: `${bannerImageUrl.value}/lmt-panel-logo.png`,
          };
        }

        if (selectedSport.value === '21') {
          opt = {
            ...opt,
            momentum: 'bars',
            layout: 'single',
            silent: isWidgetSilent.value,
          };
        }
      }

      return opt;
    });

    watch(options, (newVal) => {
      window.SIR('removeWidget', `#${attrs.id}`);
      nextTick(() => {
        window.SIR('addWidget', `#${attrs.id}`, 'match.lmtPlus', newVal);
      });
    });

    onMounted(() => {
      if (liveMatchTrackerEnabled.value) {
        window.SIR('addWidget', `#${attrs.id}`, 'match.lmtPlus', options.value);
      }
    });

    onBeforeUnmount(() => {
      if (liveMatchTrackerEnabled.value) {
        window.SIR('removeWidget', `#${attrs.id}`);
      }
    });

    return {
      liveMatchTrackerActive,
      liveMatchTrackerError,
      breakpoints,
    };
  },
};
</script>
