<template>
  <div class="overview-header-sport">
    <i
      class="overview-header-sport-icon"
      :class="['icon', `icon-sport-${sportId}`]"
    />

    <div
      v-if="enableSportColor"
      class="overview-header-sport-color sport-color-default"
      :class="[`sport-color-${sportId}`]"
    ></div>

    <span class="overview-header-sport-name">{{label}}</span>
  </div>
</template>

<script>

export default {
  name: 'OverviewHeaderSport',
  props: {
    sportId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    enableSportColor: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
