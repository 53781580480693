<template>
  <div class="outcomes-header-bet">
    <div
      v-if="hasSpecialValue"
      class="outcomes-header-bet-outcome special-value">
        {{$translate.key('sv')}}
    </div>

    <div
      class="outcomes-header-bet-outcome"
      v-for="outcome in displayedOutcomes"
      :key="outcome.idBetOutcome"
    >
      {{ outcome.betOutcomeShortName || outcome.betOutcomeName }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    displayedBet: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    sportMatches: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const matchesBets = computed(() => store.getters.matchesBets);

    const hasSpecialValue = computed(() => _.some(
      props.sportMatches, ({ idMatch }) => _.some(
        matchesBets.value[idMatch],
        ({ idBet, mbSpecialValue }) => idBet === props.displayedBet?.idBet
          && mbSpecialValue && mbSpecialValue !== '*',
      ),
    ));

    const displayedOutcomes = computed(() => {
      const hasCompetitors = _.some(
        props.displayedBet.betOutcomes,
        ({ betOutcomeType }) => betOutcomeType.match(/dynamic/),
      );

      const sorted = _.orderBy(props.displayedBet.betOutcomes, 'betOutcomePosition');

      return _.take(sorted, hasCompetitors ? 1 : 3);
    });

    return {
      displayedOutcomes,
      hasSpecialValue,
    };
  },
};
</script>
