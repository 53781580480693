<template>
  <div class="overview-match-bet">
    <div
      v-if="hasSpecialValue && outcomes.length"
      class="overview-match-bet-outcome special-value"
      :class="{ 'locked': !matchBettingStatus }"
    >
      <span
        v-if="specialValue.period"
        class=special-value-period>
        {{ specialValue.period }}
      </span>

      {{ specialValue.general }}
    </div>

    <MatchBetOutcome
      v-for="outcome in outcomes"
      :key="outcome.idMbo"
      :outcome="outcome"
      :id-match="idMatch"
      :id-mb="matchBet.idMb"
      :match-betting-status="matchBettingStatus"
      :sv="!displayedBet.betGroupingEnabled && hasSpecialValue ? matchBet.mbSpecialValue : ''"
      :bet-name="displayedBet.betName || displayedBet.betShortName"
    />
    <div
      v-if="!outcomes.length"
      class="overview-match-bet-unavailable"
    >
      {{ $translate.key('no_match_bet') }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import MatchBetOutcome from './MatchBetOutcome';

export default {
  name: 'MatchBet',
  components: {
    MatchBetOutcome,
  },
  props: {
    displayedBet: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    matchBets: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    matchBettingStatus: {
      type: Boolean,
      required: true,
    },
    idMatch: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const matchBet = computed(() => {
      const matched = { idBet: props.displayedBet.idBet, mbActive: true, mostBalanced: true };
      return _.find(props.matchBets, matched) || {};
    });

    const outcomes = computed(() => {
      const { mbActive, mbOutcomes } = matchBet.value;
      if (!mbActive || _.isEmpty(mbOutcomes)) return [];

      const hasCompetitors = _.some(mbOutcomes, ({ mboType }) => mboType.match(/dynamic/));

      const orderedOutcomes = hasCompetitors
        ? _.orderBy(mbOutcomes, ['mboOddValue', 'mboDisplayName', 'mboPosition'])
        : _.orderBy(mbOutcomes, 'mboPosition');
      return _.take(orderedOutcomes, hasCompetitors ? 1 : 3);
    });

    const specialValue = computed(() => {
      if (!matchBet.value?.mbSpecialValue || matchBet.value?.mbSpecialValue === '*') return {};

      const sv = matchBet.value?.mbSpecialValue.split('|');
      return sv.length === 2
        ? { period: sv[0], general: sv[1] }
        : { general: sv[0] };
    });

    const hasSpecialValue = computed(() => !_.isEmpty(specialValue.value));

    return {
      matchBet,
      outcomes,
      specialValue,
      hasSpecialValue,
    };
  },
};
</script>
