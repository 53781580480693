const isPreviewLink = window.location.href?.indexOf('deploy-preview') > -1;

export default {
  getCpvUuid() {
    const prvaFirma = '6f70074d-28f5-4941-89ba-2199d794cb9f';
    const expo = 'f79d2fc8-6bee-487f-928d-285854647629';
    return isPreviewLink ? expo : prvaFirma;
  },
  getTenantUuid() {
    const prvaFirma = 'b99752b3-443c-4c80-b559-945a95c4b805';
    const expo = 'f0a2c9ff-cbf7-417e-b9ec-867c27bbe9ca';
    return isPreviewLink ? expo : prvaFirma;
  },
  getName() {
    const prvaFirma = 'prvafirma';
    const expo = 'expo';
    return isPreviewLink ? expo : prvaFirma;
  },
};
