<template>
  <div
    ref="sportRef"
    class="overview-sport"
  >
    <div
      ref="stickyHeaderRef"
      class="sticky"
    >
      <OverviewHeader
        v-if="filteredMatchesLength"
        :label="sport.name"
        :sport-id="sport.id"
        :sport-collapsed="sportCollapsed"
        :matches-count="filteredMatchesLength"
        :enable-sport-color="enableSportColor"
        @click="toggleSport"
      />

      <OutcomesHeader
        v-if="!sportCollapsed && filteredMatchesLength"
        :sport-id="sport.id"
        :sport-matches="filteredMatchesBySport"
      />
    </div>

    <div
      v-show="!sportCollapsed"
      class="overview-sport-body"
    >
      <MatchRow
        v-for="match in filteredMatchesBySport"
        :key="match.idMatch"
        :match="match"
        :id="`match-${match.idMatch}`"
        :class="{'focus-element': animationsEnabled && (focusedMatch?.idMatch === match.idMatch)}"
        :is-visible="isVisible[match.idMatch]"
      />
    </div>
    <div ref="loader" data-id="main"></div>
  </div>
</template>

<script>
import {
  ref, watch, nextTick, onMounted, computed,
} from 'vue';
import { useStore } from 'vuex';
import { useMatchFocus, useMatchSportFocus } from '@/composables/matchFocus';
import useMatches from '@/composables/matches';
import useFormatter from '@/composables/formatter';
import useSearch from '@/composables/search';
import MatchRow from './match/MatchRow';
import OverviewHeader from './header/OverviewHeader';
import OutcomesHeader from './outcomes-header/OutcomesHeader';
import useIntersectionObserver from '@/composables/viewIntersectionObserver';

export default {
  name: 'OverviewSport',
  components: {
    MatchRow,
    OverviewHeader,
    OutcomesHeader,
  },
  props: {
    sport: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    focusedMatch: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    enableSportColor: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { isSportFocused, initFocusedSportWatcher } = useMatchSportFocus(props.sport.id);
    const { animationsEnabled } = useMatchFocus();
    const loader = ref(null);
    const { isVisible, observer } = useIntersectionObserver(loader, true);
    const { tournamentActive, categoryActive, matchNameInSearch } = useMatches();
    const { searchQuery } = useSearch('matchName');

    const { formatMatch, sortMatches } = useFormatter();
    const sportRef = ref(null);
    const stickyHeaderRef = ref(null);

    const sportCollapsed = ref(false);
    const expandSport = () => {
      sportCollapsed.value = false;
    };

    const toggleSport = () => {
      const stickyHeaderPinned = sportRef.value.offsetTop < stickyHeaderRef.value.offsetTop;

      if (isSportFocused.value) {
        store.dispatch('setFocusedMatch', {});
        store.dispatch('setFocusedMatchSportId', {});
      }

      sportCollapsed.value = !sportCollapsed.value;

      if (stickyHeaderPinned && sportCollapsed.value) sportRef.value.scrollIntoView();
    };

    const sportMatches = computed(() => store.getters.matchesBySport(props.sport.id));

    const filteredMatchesBySport = computed(() => {
      const filteredMatches = _.reduce(sportMatches.value, (list, match) => {
        if (!categoryActive(match) || !tournamentActive(match) || !matchNameInSearch(match)) {
          return list;
        }
        const formattedMatch = formatMatch(match);
        list.push(formattedMatch);
        return list;
      }, []);

      return sortMatches(filteredMatches);
    });

    initFocusedSportWatcher(expandSport);

    onMounted(() => {
      const divs = sportRef.value?.querySelectorAll('.overview-match');

      divs.forEach((div) => observer.value.observe(div));
    });

    const filteredMatchesLength = computed(() => filteredMatchesBySport.value.length);

    watch(filteredMatchesLength, (val) => {
      if (searchQuery.value) {
        store.dispatch('updateFilteredMatches', { idSport: props.sport.id, matchesCount: val });
      }
      nextTick(() => {
        if (!val) return;
        const divs = sportRef.value?.querySelectorAll('.overview-match');
        divs.forEach((div) => observer.value.observe(div));
      });
    });

    return {
      animationsEnabled,
      sportCollapsed,
      sportRef,
      stickyHeaderRef,
      toggleSport,
      loader,
      isVisible,
      filteredMatchesBySport,
      filteredMatchesLength,
    };
  },
};
</script>
