<template>
  <div
    v-if="matchBettingStatus && outcome.mboActive"
    class="overview-match-bet-outcome"
    :class="[
      oddChangedClass,
      { 'odd-changed': outcome.oddChanged && oddChangedClass },
      { 'selected': !!addedToTicket },
      { 'small-text' : competitor.length }]"
    @click="selectBet"
  >
    {{ competitor }} {{ $formatOdd(outcome.mboOddValue, oddType, oddFormatterActive) }}
  </div>
  <div
    v-else
    class="overview-match-bet-outcome locked"
    :class="{ 'selected': !!addedToTicket }"
  >
    <i class="icon icon-lock-a" />
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';

export default {
  name: 'MatchBetOutcome',
  props: {
    outcome: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    matchBettingStatus: {
      type: Boolean,
      required: true,
    },
    idMatch: {
      type: String,
      required: true,
    },
    betName: {
      type: String,
      required: true,
    },
    idMb: {
      type: String,
      required: true,
    },
    sv: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { betBuilderBet, ticketBets } = useBetslip();

    const { oddType, oddFormatterActive } = inject('oddTypeData');

    const oddChangedClass = computed(() => {
      if (props.outcome.oddChanged === 'up'
        || props.outcome.oddChanged === 'down') return `odd-${props.outcome.oddChanged}`;
      return false;
    });

    const bet = computed(() => ({
      sv: props.sv,
      idMb: props.idMb,
      idMatch: props.idMatch,
      betName: props.betName,
      idMbo: props.outcome.idMbo,
      originalOdd: props.outcome.mboOddValue,
    }));

    const competitor = props.outcome.mboType.match(/dynamic|^(?=.*\bteam\b)(?=.*\bgoal\b).*$/)
      ? props.outcome.mboShortDisplayName
      || props.outcome.mboDisplayName
      || props.outcome.baseBetOutcomeName
      || props.outcome.mboType
      : '';

    const addedToTicket = computed(() => (
      _.find(ticketBets.value, { idMbo: props.outcome.idMbo }) && !betBuilderBet.value
    ));

    const betBuilderBetPreventAdd = () => {
      let key = '';

      if (betBuilderBet.value) {
        key = store.getters.translate('bet_builder_error_with_bet_builder_bet');
      }
      if (!key) return false;

      return store.dispatch('addNotification', {
        id: key,
        content: store.getters.translate(key),
        type: 'error',
      });
    };

    const selectBet = () => {
      if (betBuilderBetPreventAdd()) return;

      store.dispatch('selectBet', {
        addedToTicket: !!addedToTicket.value,
        bet: bet.value,
      });
    };

    return {
      addedToTicket,
      oddChangedClass,
      oddType,
      oddFormatterActive,
      selectBet,
      competitor,
    };
  },
};

</script>
