export default {
  serviceAlive: 'serviceAlive',
  newConfig: 'updateBetslipConfig',
  state: 'setState',
  betState: 'setBetState',
  matchState: 'setMatchState',
  currentMatches: 'setCurrentMatches',
  setScoreDetails: 'setScoreDetails',
  setOdds: 'setOdds',
  topMatchBets: 'setTopMatchBets',
  betchange: 'updateMatchBet',
  setCards: 'setCards',
  score: 'setScore',
  matchBettingStatus: 'updateMatchBettingStatus',
  addMatches: 'addMatches',
  removeMatches: 'removeMatches',
  updateSport: 'updateSport',
  updateBet: 'updateMetaBet',
  updateCategory: 'updateCategory',
  updateTournament: 'updateTournament',
  updateTeam: 'updateTeam',
  updateMatch: 'updateMatch',
  removeCategory: 'removeCategory',
  removeTournament: 'removeTournament',
  betMeta: 'setBetMeta',
  setSelectedTab: 'setSelectedTab',
  loadTickets: 'loadTickets',
  setActiveTicket: 'setActiveTicket',
  subscribeMatches: 'subscribeMatches',
  ticketUpdate: 'ticketUpdate',
};
