<template>
  <div class="tickets-wrapper">
    <div
      v-if="ticketList.length"
      class="ticket-list"
    >
      <TicketRow
        v-for="(ticket, i) in ticketList"
        :key="i"
        :ticket="ticket"
        :checked-ticket="checkedTicket"
        @toggleSelectedTicket="toggleSelectedTicket($event)"
      />

      <ScrollButtons
        class="bottom-position-adjust"
        :has-scroll-to-top="true"
        :scroll-content="'ticket-list'"
        :key="ticketList.length"
      />
    </div>

    <button
      v-if="ticketList.length"
      @click="handlePrintButton"
      class="print-copy-button"
    >
     {{ getTranslation('print_copy') }}
    </button>

    <div
      v-if="!ticketList.length"
      class="empty-list"
    >
      {{ getTranslation('no_results_found') }}
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import TicketRow from './TicketRow';
import formatter from '@/services/formatter';
import gateway from '@/services/ticketHistoryGravityGateway';
import ScrollButtons from '@/components/common/ScrollButtons';
import useNotifications from '@/composables/notifications';

export default {
  components: {
    TicketRow,
    ScrollButtons,
  },
  setup() {
    const store = useStore();
    const { selectTicketNotification, printCopySucessNotification } = useNotifications();

    const checkedTicket = ref('');

    const taxes = computed(() => store.getters.sevenTaxes);
    const ticketList = computed(() => store.getters.lastTickets);
    const productTranslation = computed(() => store.getters?.product?.translation);

    const toggleSelectedTicket = (ticketId) => {
      checkedTicket.value = checkedTicket.value === ticketId ? '' : ticketId;
    };

    const handlePrintButton = () => {
      if (!checkedTicket.value) {
        gateway.sendMessage('Dialog.Show', selectTicketNotification, true);

        return;
      }

      const selectedTicket = ticketList.value.find((ticket) => ticket.id === checkedTicket.value);

      const formattedTicket = formatter.formatTicketForSeven(
        selectedTicket,
        productTranslation.value,
        taxes.value,
      );

      formattedTicket.isCopy = true;

      const payload = {
        action: 'Add',
        productId: 'LiveBetting',
        responseData: formattedTicket,
      };

      gateway.sendMessage('Tickets.Print', payload);
      gateway.sendMessage('Dialog.Show', printCopySucessNotification, true);

      checkedTicket.value = '';
    };

    const getTranslation = (key) => store.getters.translate(key);

    watch(() => store.getters.resetTrigger, () => {
      checkedTicket.value = '';
      const ticketListEl = document.querySelector('.ticket-list');

      if (!ticketListEl) return;

      ticketListEl.scrollTo(0, 0);
    });

    return {
      ticketList,
      checkedTicket,
      getTranslation,
      handlePrintButton,
      toggleSelectedTicket,
    };
  },
};
</script>
