<template>
  <div class="ticket-check-footer">
    <div class="ticket-info">
      <div>
        <strong>{{ $translate.key('status') }}</strong>
        <div v-if="ticket.payoutTax">{{ $translate.key('payout_tax') }}</div>
        <div>
          <span v-if="!ticket.winnings">{{ $translate.key('max_winning') }}</span>
          <span v-if="ticket.winnings && !ticket.payout">{{ $translate.key('winnings') }}</span>
          <span v-if="ticket.payout">{{ $translate.key('payout') }}</span>
        </div>
      </div>

      <div>
        <strong
          class="ticket-status-value"
          :class="getStatusColor(ticket.status?.value)"
        >
          {{ $translate.key(`status_${ticket.status?.value.toLowerCase()}`) }}
        </strong>
        <div v-if="ticket.payoutTax">{{ ticket.payoutTax }}</div>
        <div>
          <span v-if="!ticket.winnings">{{ ticket.maxPossibleWin.toFixed(2) }}</span>
          <span v-if="ticket.winnings && !ticket.payout">{{ ticket.winnings.toFixed(2) }}</span>
          <span v-if="ticket.payout">
            {{ ticket.winnings > ticket.payout
              ? ticket.payout.toFixed(2)
              : ticket.winnings.toFixed(2)
            }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="ticket?.status?.value === 'WON'"
      class="ticket-actions">
      <div @click="handlePayoutClick">
        <div >
          <em class="icon icon-currency"></em>
          <span>{{ $translate.key('payout') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useStatusFormatter from '@/composables/statusFormatter';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { getStatusColor } = useStatusFormatter();

    const handlePayoutClick = () => store.dispatch('payoutTicket', props.ticket);

    return {
      handlePayoutClick,
      getStatusColor,
    };
  },
};
</script>
