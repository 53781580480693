<template>
  <div class="overview-outcomes-header">
    <div class="overview-outcomes-header-date">
      <!-- CHANGE CLASS LATER - we're not gonna use date here -->
    </div>

    <div class="overview-outcomes-header-bets">
      <OutcomesHeaderBet
        v-for="bet in displayedBets"
        :key="bet.idBet"
        :sport-matches="sportMatches"
        :displayed-bet="bet" />
    </div>

    <div class="overview-outcomes-header-empty-space">
      <!-- USE EMPTY SPACE TO HANDLE FLEXBOX EASIER -->
    </div>
  </div>
</template>

<script>
import useSportBets from '@/composables/sportBets';
import OutcomesHeaderBet from './OutcomesHeaderBet';

export default {
  name: 'OutcomesHeader',
  components: {
    OutcomesHeaderBet,
  },
  props: {
    sportId: {
      type: String,
      required: true,
    },
    sportMatches: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { displayedBets } = useSportBets(props.sportId);

    return { displayedBets };
  },
};
</script>
