import { computed, inject } from 'vue';
import { useStore } from 'vuex';

export default function useSportBets(sportId = '') {
  const store = useStore();
  const displayedBetsNumber = inject('displayedBetsNumber');

  const bets = computed(() => {
    if (!sportId) return [];
    return store.getters.displayedBetsBySport(sportId);
  });

  const displayedBets = computed(() => bets.value.slice(0, displayedBetsNumber.value));

  const metaBets = computed(() => store.getters.betsBySport(sportId));

  const moreBets = computed(() => {
    if (bets.value.length <= displayedBetsNumber.value) return [];
    return bets.value.slice(displayedBetsNumber.value - 1);
  });

  const hasMoreBets = (i) => i === displayedBetsNumber.value - 1 && !_.isEmpty(moreBets.value);

  const selectDisplayedBet = (bet) => {
    if (!bet) return;
    const displayedSportsBets = bets.value;
    const betIndex = _.findIndex(displayedSportsBets, { idBet: bet.idBet });
    displayedSportsBets.splice(displayedBetsNumber.value - 1, 0,
      displayedSportsBets.splice(betIndex, 1)[0]);

    store.dispatch('updateDisplayedSportsBets', { sportId, displayedSportsBets });
  };

  return {
    bets,
    displayedBets,
    metaBets,
    hasMoreBets,
    selectDisplayedBet,
    moreBets,
  };
}
