export default {
  ADD_MATCHES: 'ADD_MATCHES',
  ADD_BETS_TO_BETSLIP: 'ADD_BETS_TO_BETSLIP',
  AWAKE_APP: 'AWAKE_APP',
  CLEAR_BETSLIP: 'CLEAR_BETSLIP',
  CLEAR_BETSLIP_RULES_NOTIFICATIONS: 'CLEAR_BETSLIP_RULES_NOTIFICATIONS',
  CLEAR_GENERAL_STATE_DATA: 'CLEAR_GENERAL_STATE_DATA',
  LOAD_TICKETS: 'LOAD_TICKETS',
  REMOVE_BET: 'REMOVE_BET',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  REMOVE_MATCHES: 'REMOVE_MATCHES',
  REMOVE_TOURNAMENT: 'REMOVE_TOURNAMENT',
  RESET_BET_BUILDER_AVAILABLE_SELECTIONS: 'RESET_BET_BUILDER_AVAILABLE_SELECTIONS',
  SAVE_SYSTEM_COMBINATIONS: 'SAVE_SYSTEM_COMBINATIONS',
  SELECT_BANKER: 'SELECT_BANKER',
  SELECT_BET: 'SELECT_BET',
  SELECT_BETSLIP_BET: 'SELECT_BETSLIP_BET',
  SELECT_TICKET_TYPE: 'SELECT_TICKET_TYPE',
  SELECT_BET_GROUP: 'SELECT_BET_GROUP',
  SET_ACTIVE_MATCH: 'SET_ACTIVE_MATCH',
  SET_ACTIVE_SPORT: 'SET_ACTIVE_SPORT',
  SET_ACTIVE_TICKET: 'SET_ACTIVE_TICKET',
  SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
  SET_APP_CONFIG: 'SET_APP_CONFIG',
  SET_AUTHORIZATION_TOKEN: 'SET_AUTHORIZATION_TOKEN',
  SET_AUTOCHECKER_STATUS: 'SET_AUTOCHECKER_STATUS',
  SET_BANKERS: 'SET_BANKERS',
  SET_BET_BUILDER_AVAILABLE_SELECTIONS: 'SET_BET_BUILDER_AVAILABLE_SELECTIONS',
  SET_BET_BUILDER_BETSLIP_RULES: 'SET_BET_BUILDER_BETSLIP_RULES',
  SET_BET_BUILDER_EVENT_CALCULATION: 'SET_BET_BUILDER_EVENT_CALCULATION',
  SET_BET_BUILDER_EVENT_SELECTION: 'SET_BET_BUILDER_EVENT_SELECTION',
  SET_BET_BUILDER_SELECTED_OUTCOMES: 'SET_BET_BUILDER_SELECTED_OUTCOMES',
  SET_BETSHOP: 'SET_BETSHOP',
  SET_BETSLIP_CONFIG: 'SET_BETSLIP_CONFIG',
  SET_BET_GROUPS: 'SET_BET_GROUPS',
  SET_BET_META: 'SET_BET_META',
  SET_BET_STATE: 'SET_BET_STATE',
  SET_BONUS_CONFIG: 'SET_BONUS_CONFIG',
  SET_CARDS: 'SET_CARDS',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_COMPANY: 'SET_COMPANY',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_CPV_UUID: 'SET_CPV_UUID',
  SET_CURRENT_MATCHES: 'SET_CURRENT_MATCHES',
  SET_DEFAULT_STAKE: 'SET_DEFAULT_STAKE',
  SET_DEVICE: 'SET_DEVICE',
  SET_FOCUSED_MATCH: 'SET_FOCUSED_MATCH',
  SET_FOCUSED_MATCH_SPORT_ID: 'SET_FOCUSED_MATCH_SPORT_ID',
  SET_HEADERS_SETTINGS: 'SET_HEADERS_SETTINGS',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_LAST_TICKETS_LOADING: 'SET_LAST_TICKETS_LOADING',
  SET_LOCAL_APP_CONFIG: 'SET_LOCAL_APP_CONFIG',
  SET_MATCHES: 'SET_MATCHES',
  SET_MATCHES_ORDER: 'SET_MATCHES_ORDER',
  SET_MATCH_BET_SEARCH_QUERY: 'SET_MATCH_BET_SEARCH_QUERY',
  SET_MATCH_NAME_SEARCH_QUERY: 'SET_MATCH_NAME_SEARCH_QUERY',
  SET_MATCH_STATE: 'SET_MATCH_STATE',
  SET_MONEY_DENOMINATIONS: 'SET_MONEY_DENOMINATIONS',
  SET_MATCH_UNIQUE_BETS: 'SET_MATCH_UNIQUE_BETS',
  SET_ODDS: 'SET_ODDS',
  SET_ODD_FORMATTER_STATUS: 'SET_ODD_FORMATTER_STATUS',
  SET_ODD_TYPE: 'SET_ODD_TYPE',
  SET_PENDING_TICKET_REQUEST_UUID: 'SET_PENDING_TICKET_REQUEST_UUID',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_SCORE: 'SET_SCORE',
  SET_SCORE_DETAILS: 'SET_SCORE_DETAILS',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  SET_SEVEN_TAXES: 'SET_SEVEN_TAXES',
  SET_SPORTS: 'SET_SPORTS',
  SET_SPORTS_BETS: 'SET_SPORTS_BETS',
  SET_STATE: 'SET_STATE',
  SET_CHECKED_TICKET: 'SET_CHECKED_TICKET',
  SET_TERMINAL: 'SET_TERMINAL',
  SET_TICKET_PROCESSING: 'SET_TICKET_PROCESSING',
  SET_TOP_MATCH_BETS: 'SET_TOP_MATCH_BETS',
  SET_TOURNAMENTS: 'SET_TOURNAMENTS',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_WORKING_TIME_BLOCKER: 'SET_WORKING_TIME_BLOCKER',
  SET_IS_CONTENT_SCROLLED: 'SET_IS_CONTENT_SCROLLED',
  SET_USER_AUTHORIZATION: 'SET_USER_AUTHORIZATION',
  TOGGLE_BETSLIP: 'TOGGLE_BETSLIP',
  UNSELECT_BET: 'UNSELECT_BET',
  UPDATE_AUTOCHECKER_COUNT: 'UPDATE_AUTOCHECKER_COUNT',
  UPDATE_BETSLIP_GENERAL_NOTIFICATIONS: 'UPDATE_BETSLIP_GENERAL_NOTIFICATIONS',
  UPDATE_BETSLIP_RULES_NOTIFICATIONS: 'UPDATE_BETSLIP_RULES_NOTIFICATIONS',
  UPDATE_BETSLIP_STATUS: 'UPDATE_BETSLIP_STATUS',
  UPDATE_BET_ASSIST_POPUP_ID: 'UPDATE_BET_ASSIST_POPUP_ID',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_GENERAL_BETSLIP_RULES_NOTIFICATIONS: 'UPDATE_GENERAL_BETSLIP_RULES_NOTIFICATIONS',
  UDPATE_GLOBAL_STAKE: 'UDPATE_GLOBAL_STAKE',
  UPDATE_FILTERED_MATCHES: 'UPDATE_FILTERED_MATCHES',
  UPDATE_MATCH: 'UPDATE_MATCH',
  UPDATE_MATCH_BET: 'UPDATE_MATCH_BET',
  UPDATE_MATCH_BETTING_STATUS: 'UPDATE_MATCH_BETTING_STATUS',
  UPDATE_META_BET: 'UPDATE_META_BET',
  UPDATE_SERVER_TIMEOUT_ID: 'UPDATE_SERVER_TIMEOUT_ID',
  UPDATE_SPORT: 'UPDATE_SPORT',
  UPDATE_SPORT_DISPLAYED_BETS: 'UPDATE_SPORT_DISPLAYED_BETS',
  UPDATE_SYSTEM_NOTIFICATIONS: 'UPDATE_SYSTEM_NOTIFICATIONS',
  UPDATE_TEAM: 'UPDATE_TEAM',
  UPDATE_TICKET_SYSTEMS: 'UPDATE_TICKET_SYSTEMS',
  UPDATE_TOURNAMENT: 'UPDATE_TOURNAMENT',
  UPDATE_WINNINGS_NOTIFICATIONS: 'UPDATE_WINNINGS_NOTIFICATIONS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  ADD_BETSLIP_NOTIFICATION: 'ADD_BETSLIP_NOTIFICATION',
  REMOVE_BETSLIP_NOTIFICATION: 'REMOVE_BETSLIP_NOTIFICATION',
  RESET_TICKET_HISTORY_STATE: 'RESET_TICKET_HISTORY_STATE',
  TOGGLE_GENERATED_TICKET: 'TOGGLE_GENERATED_TICKET',
  TOGGLE_CONNECTION_STATUS: 'TOGGLE_CONNECTION_STATUS',
  OVERVIEW_SCROLL_UPDATE: 'OVERVIEW_SCROLL_UPDATE',
  SET_LMT: 'SET_LMT',
  SET_LMT_ACTIVE: 'SET_LMT_ACTIVE',
  SET_LMT_HAS_ERROR: 'SET_LMT_HAS_ERROR',
};
