import gravityTax from '@nsftx/seven-gravity-tax-service';
import _ from '@/services/lodash';

export const getBankers = ({ bets }) => bets?.filter((bet) => bet.banker);
export const getBankerOdds = ({ bets }) => bets?.reduce((arr, bet) => {
  if (bet.banker) arr.push(bet.oddValue);
  return arr;
}, []);
export const getNonBankers = ({ bets }) => bets?.filter((bet) => !bet.banker);
export const getNonBankerOdds = ({ bets }) => bets?.reduce((arr, bet) => {
  if (!bet.banker) arr.push(bet.oddValue);
  return arr;
}, []);

/**
 *
 * @param {Array} bets
 * @returns Number
 */
export const getTotalOdds = (bets, betBuilderOdd) => {
  if (!bets?.length) return 1;

  let total = 1;
  bets.forEach((bet) => {
    if (bet.oddValue && !bet.isBetBuilderBet) total *= bet.oddValue;
  });

  if (betBuilderOdd) return total * betBuilderOdd;

  return total;
};
/**
 *
 * @param {Number} stake
 * @param {String} taxPolicy
 * @returns Number
 */
export const getStakeAfterTax = ({ stake, taxPolicy }) => {
  if (stake === 0 || !taxPolicy) return stake;

  const tax = gravityTax.calculateTax(taxPolicy, stake).taxAmountRounded;
  return stake - tax;
};

/**
 *
 * @param {Array} bets
 * @param {Array} bonusConfig
 * @returns Object
 */
export const getComboBonus = ({ bets, bonusConfig }) => {
  let activeRule = false;

  if (!bonusConfig || !bonusConfig.length || !bets.length) return {};

  bonusConfig.forEach((rule) => {
    let betSatisfaction = 0;

    bets.forEach((bet) => {
      if (bet.oddValue >= rule.minOdd) betSatisfaction += 1;
    });

    if (betSatisfaction >= rule.minBetCount
      && (rule.maxBetCount === 0 || betSatisfaction <= rule.maxBetCount)) {
      activeRule = rule;
    }
  });

  return activeRule;
};

export const getSingleStake = ({ stake, betsLength, taxPolicy }) => {
  if (stake === 0 || betsLength === 0) return stake;

  if (!taxPolicy) return stake / betsLength;

  const taxStake = gravityTax.calculateTax(taxPolicy, stake).taxAmountRounded;
  return (stake - taxStake) / betsLength;
};

export const getSingleBonus = ({ bonusConfig }) => {
  if (!bonusConfig?.length) return false;

  return bonusConfig.find((bonus) => bonus.maxBetCount === 1 && bonus.minBetCount === 1);
};

export const getSelectedSystems = (systems) => systems.filter((item) => item.selected);

export const getSystemCombsOdds = (systemOdds, systemLowerNumber, callback) => {
  const n = systemOdds.length;
  const c = [];
  const inner = (start, choose_) => {
    if (choose_ === 0) {
      callback(c);
    } else {
      for (let i = start; i <= n - choose_; i += 1) {
        c.push(systemOdds[i]);
        inner(i + 1, choose_ - 1);
        c.pop();
      }
    }
  };
  inner(0, systemLowerNumber);
};

export const getActiveBonus = ({ odds, bonusConfig }) => {
  let activeRule = false;

  if (!bonusConfig) return false;

  bonusConfig.forEach((rule) => {
    let betSatisfaction = 0;

    odds.forEach((odd) => {
      if (odd >= rule.minOdd) betSatisfaction += 1;
    });

    if (betSatisfaction >= rule.minBetCount
      && (rule.maxBetCount === 0 || betSatisfaction <= rule.maxBetCount)) {
      activeRule = rule;
    }
  });

  return activeRule;
};

export const getTax = (policy, stake) => gravityTax.calculateTax(policy, stake);

export const getNumberOfCombinations = (num1, num2) => {
  const lower = _.min([num1, num2]);
  const higher = _.max([num1, num2]);

  let x = 1;
  let y = 1;

  for (let i = higher; i > (higher - lower); i -= 1) {
    x *= i;
  }

  for (let i = lower; i > 0; i -= 1) {
    y *= i;
  }

  return x / y;
};
