<template>
  <ThemeProvider>
    <router-view />
  </ThemeProvider>
</template>

<script>
import { useStore } from 'vuex';
import queryParser from './utils/queryParser';
import defaultCompanyValues from './utils/defaultCompanyValues';
import ThemeProvider from '@/components/common/ThemeProvider';
import gravityGateway from '@/services/gateway';
import ticketHistoryGravityGateway from '@/services/ticketHistoryGravityGateway';

export default {
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    const store = useStore();

    const integrationType = queryParser.getUrlParameter('integrationType') || '';

    if (integrationType !== 'gravityGateway') {
      const language = queryParser.getUrlParameter('lang') || 'en';
      const encoding = queryParser.getUrlParameter('encoding') || 'plaintext';
      const cpvUuid = queryParser.getUrlParameter('cpvUuid') || defaultCompanyValues.getCpvUuid();
      const companyName = queryParser.getUrlParameter('company') || defaultCompanyValues.getName();
      const companyUuid = queryParser.getUrlParameter('tenantUuid') || defaultCompanyValues.getTenantUuid();

      store.dispatch('initSocket', { cpvUuid, encoding, language });
      store.dispatch('getTranslations', { language });
      store.dispatch('setBetslipConfig', {});
      store.dispatch('setAppSettings', {
        uuid: companyUuid,
        companyName,
      }).then(() => store.dispatch('handleSIRConnect'));

      return;
    }

    if (window.location.pathname === '/ticket-history') {
      ticketHistoryGravityGateway.instance({ dispatch: store.dispatch });
      ticketHistoryGravityGateway.init();

      return;
    }

    gravityGateway.instance({ dispatch: store.dispatch });
    gravityGateway.init();
  },
};
</script>

<style lang="scss">
</style>
