<template>
  <div class="eventview-sidebar">
    <div
      v-if="formattedSportsWithMatches"
      class="eventview-sidebar-groups"
    >
      <EventviewSidebarSportGroup
        v-for="sport in formattedSportsWithMatches"
        :key="sport.id"
        :label="sport.name"
        :enable-sport-color="enableSportColor"
        :sport-id="sport.id"
      />
    </div>
     <ScrollButtons
        v-if="scrollButtonsEnabled"
        :key="sportMatchesCount"
        class="eventview-aside-navigation"
        scroll-content="eventview-aside" />
  </div>
</template>

<script>
import useMatches from '@/composables/matches';
import useSports from '@/composables/sports';
import EventviewSidebarSportGroup from './EventviewSidebarSportGroup';
import ScrollButtons from '@/components/common/ScrollButtons';

export default {
  name: 'EventviewSidebar',
  components: {
    EventviewSidebarSportGroup,
    ScrollButtons,
  },
  props: {
    scrollButtonsEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { sportMatchesCount, formattedSportsWithMatches } = useMatches();
    const { enableSportColor } = useSports();

    return {
      enableSportColor,
      formattedSportsWithMatches,
      sportMatchesCount,
    };
  },
};
</script>
