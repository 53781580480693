<template>
  <div
    class="ticket"
    :class="{ selected: checked }"
    @click="toggleTicket"
  >
    <div class="ticket-toggle-field ripple">
      <input
        type="checkbox"
        :checked="checked"
      >
      <span class="checkmark" />
    </div>

    <div class="ticket-details-field">
      <p class="ticket-field-title">{{ getTranslation('ticket_id') }}</p>
      <p class="ticket-field-value">{{ ticket.id }}</p>
    </div>

    <div class="ticket-details-field">
      <p class="ticket-field-title">{{ getTranslation('date_and_time') }}</p>
      <p class="ticket-field-value">
        {{ formatDate(new Date(ticket.ticketDateTimeUTC)) }}
      </p>
    </div>

    <div class="ticket-details-field">
      <p class="ticket-field-title">{{ getTranslation('payin') }}</p>
      <p class="ticket-field-value">{{ ticket.payin.toFixed(2) }}</p>
    </div>

    <div class="ticket-details-field">
      <p class="ticket-field-title">{{ getTranslation('status') }}</p>
      <p class="ticket-field-value">{{ ticket.status.value }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import formatter from '@/services/formatter';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
      default: () => {},
    },
    checkedTicket: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const checked = computed(() => props.checkedTicket === props.ticket.id);

    const toggleTicket = () => emit('toggleSelectedTicket', props.ticket.id);

    const getTranslation = (key) => store.getters.translate(key);

    return {
      checked,
      toggleTicket,
      getTranslation,
      formatDate: formatter.formatDateForTicket,
    };
  },
};
</script>
