import resultMapper from '@/utils/matchResultsSportMapper';
import formatter from '@/services/formatter';

export default {
  computed: {
    currentMatchStatus() {
      return this.match.currentStatus;
    },
    results() {
      if (!this.currentMatchStatus) return [];
      const result = this.currentMatchStatus.score;
      const resultSets = this.currentMatchStatus.setScores;
      const resultGame = this.currentMatchStatus.gameScore || this.currentMatchStatus.legScore;
      const resultsArray = resultSets ? resultSets.split('-') : [];
      resultsArray.push(result);

      // Cricket dismissals
      const dismissals = _.has(this.currentMatchStatus, 'htDismissals')
        ? [this.currentMatchStatus.htDismissals, this.currentMatchStatus.atDismissals]
        : [];

      const formattedResults = resultMapper.formatSportMatchResults({
        sportId: this.match.idSport,
        resultsArray,
        resultGame,
        result,
        dismissals,
        matchStatus: this.currentMatchStatus.status,
      });

      return _.map(formattedResults, formatter.formatTeamsResult);
    },
    result() {
      if (!this.currentMatchStatus || (this.currentMatchStatus
        && this.currentMatchStatus.score === '-:-')) return { labels: [], scores: [] };

      const cricketResultAvailable = this.match.idSport === '21';

      const labels = ['R']; // Label for current Result (score)
      const scores = cricketResultAvailable ? [this.cricketResult] : [this.score];

      if (!_.isEmpty(this.currentMatchStatus.setScoreDetails)) {
        _.each(this.currentMatchStatus.setScoreDetails, (scoreDetail) => {
          labels.push(scoreDetail.period);
          const periodScoreSplit = scoreDetail.score.split(':');
          scores.push({ team1: periodScoreSplit[0], team2: periodScoreSplit[1] });
        });
      }

      if ((this.currentMatchStatus.pointsDetails || {}).points) {
        labels.push(this.currentMatchStatus.pointsDetails.name);
        const gamePointsSplit = this.currentMatchStatus.pointsDetails.points.split(':');
        const teamGamePointsSplit = (gamePoint) => (gamePoint === '50' ? 'A' : gamePoint);

        const team1 = teamGamePointsSplit(gamePointsSplit[0]);
        const team2 = teamGamePointsSplit(gamePointsSplit[1]);
        scores.push({ team1, team2 });
      }

      // Soccer has additional stats displayed
      if (this.match.idSport === '1') {
        const specialLabels = ['icon-corner', 'icon-card yellow', 'icon-card red'];
        _.each(specialLabels, (value) => {
          labels.push({
            type: 'icon',
            class: value,
          });
        });
        const specialScores = ['corners', 'yellowCards', 'redCardsTotal'];
        _.each(specialScores, (value) => {
          const teamSpecialScore = (teamValue) => (
            !_.isUndefined(this.currentMatchStatus[`${teamValue}`])
              ? this.currentMatchStatus[`${teamValue}`]
              : '-');
          scores.push({
            team1: teamSpecialScore(`${value}1`),
            team2: teamSpecialScore(`${value}2`),
          });
        });
      }
      return {
        labels,
        scores,
      };
    },
    finalResult() {
      return _.last(this.results);
    },
  },
  methods: {
    resultsBySport(idSport) {
      const numberOfResults = {
        37: 2, // Squash
        34: 2, // B.Volleyball
        31: 2, // Badminton
        23: 2, // Volleyball
        20: 2, // Table tennis
        19: 2, // Snooker
        5: 2, // Tennis
      };
      return _.takeRight(this.results, numberOfResults[idSport] || 1);
    },
  },
};
