import socketio from 'socket.io-client';
import uuidv4 from 'uuid/v4';
import pako from 'pako';
import routes from './env';

let sio = null;
let aliveId = null;
let cpv = null;
let encoding = null;
let lang = null;
let tournamentLang = null;
let deviceId;

export default {
  init({
    cpvUuid, contentEncoding, language, deviceUuid, tournamentLanguage, valuesSaved, reconnect,
  }) {
    if (reconnect) sio = null;

    if (!valuesSaved) {
      lang = language;
      cpv = cpvUuid;
      encoding = contentEncoding;
      tournamentLang = tournamentLanguage || 'en';
      deviceId = deviceUuid || '';
    }
    if (!sio) {
      const url = routes.sportsbookPublicApi;
      const connUuid = uuidv4();
      const socketOptions = {
        forceNew: true,
        transports: ['websocket'],
        query: `cpv=${cpv}&EIO&product=live&protocol=sio1&contentEncoding=${encoding}&connUuid=${connUuid}`,
      };

      sio = socketio(url, socketOptions);
      sio.connect();

      sio.on('connect', () => {
        console.log('Live socket connected!');
        this.liveSubscribe();
      });

      sio.on('disconnect', () => {
        console.log('live disconnected');
        if (sio && aliveId) clearInterval(aliveId);
      });
    }

    return sio;
  },
  liveSubscribe() {
    sio.emit('subscribe', {
      subscribeMode: 'topSportBets',
      language: {
        default: lang,
        tournaments: tournamentLang,
      },
      deliveryPlatform: 'TerminalSubscribe',
      deviceUuid: deviceId,
      subscribeOptions: {
        autoSubscribe: true,
        betCount: 7,
        fullBetMeta: true,
        excludeBetGroups: false,
      },
      useBetAssist: true,
    });

    const clientInterval = 5000;

    aliveId = setInterval(() => {
      this.serviceAlive();
    }, clientInterval);
  },
  serviceAlive() {
    sio.emit('message', {
      type: 'serviceAlive',
    });
  },
  subscribeMatches(data) {
    sio.emit('message', {
      type: 'subscribeMatches',
      data,
    });
  },
  unsubscribeMatches(data) {
    sio.emit('message', {
      type: 'unsubscribeMatches',
      data,
    });
  },
  disconnect() {
    sio.disconnect();
  },
  reconnect() {
    if (sio) {
      sio.disconnect();
      sio = null;
      this.init({ valuesSaved: true });
    }
  },
  decompressMessage(source) {
    try {
      return JSON.parse(pako.inflate(source, {
        to: 'string',
      }));
    } catch (e) {
      console.warn('Invalid data received => ', e);
      return {};
    }
  },
};
