import {
  ref, computed, onMounted, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';

export default function useOnlineChecker() {
  const store = useStore();
  const retryTiemout = ref(null);

  const appOnline = computed(() => store.getters.appOnline);

  const onBackOnline = () => {
    store.dispatch('checkPendingTicket');
  };

  const onlineChecker = () => {
    api.getOnlineStatus()
      .then((resp) => {
        if (resp.status === 200) {
          store.dispatch('toggleOnlineStatus', true);
          store.dispatch('setAutoCheckerStatus', false);

          onBackOnline();
        }
      })
      .catch((err) => {
        console.log(err);

        // retry online check in 5000ms
        retryTiemout.value = setTimeout(() => {
          onlineChecker();
          clearTimeout(retryTiemout.value);
        }, 5000);
      });
  };

  const onDisconnected = () => store.dispatch('toggleOnlineStatus', false);

  onMounted(() => {
    window.addEventListener('offline', onDisconnected);
    window.addEventListener('online', onlineChecker);
  });

  onUnmounted(() => {
    window.removeEventListener('offline', onDisconnected);
    window.removeEventListener('online', onlineChecker);
  });

  return { appOnline };
}
