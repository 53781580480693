<template>
  <div class="bet-assist-wrapper">
    <div
      :class="[
        'bet-assist-label',
        { 'bet-assist-active': activeBetAssist }]"
      @click.stop="toggleBetAssist">
       {{ $translate.key('bet_assist_label') }}
      <em class="icon icon-stats" />
    </div>
    <div
      v-if="activeBetAssist"
      v-click-away="disableBetAssist"
      :class="[
        'bet-assist',
        `${idBet}-${betIndex}` ]">
    </div>
  </div>
</template>

<script>
import { toRefs, onMounted } from 'vue';
import useBetAssist from '@/composables/betAssist';
import clickAway from '@/utils/clickAway';

export default {
  name: 'EventviewBetAssist',
  props: {
    betName: {
      type: String,
      required: true,
      default: '',
    },
    idMatch: {
      type: String,
      required: true,
      default: '',
    },
    idBet: {
      type: String,
      required: true,
      default: '',
    },
    betIndex: {
      type: Number,
      required: true,
    },
  },
  directives: {
    clickAway,
  },
  setup(props) {
    const {
      hasBetAssist,
      activeBetAssist,
      toggleBetAssist,
      disableBetAssist,

    } = useBetAssist(toRefs(props));

    onMounted(() => {
      if (activeBetAssist.value) toggleBetAssist(false);
    });

    return {
      hasBetAssist,
      activeBetAssist,
      toggleBetAssist,
      disableBetAssist,
    };
  },
};
</script>
