export default {
  rules: [],
  taxes: {
    payin: {
      policy: null,
      value: false,
      hideTax: null,
    },
    payout: {
      policy: null,
      value: false,
      hideTax: null,
    },
  },
};
