<template>
  <div class="eventview-header-match-teams">
    <div class="match-time-wrap">
      <div class="match-time">
            <span
              class="period-time"
              v-if="timeStatus">
              {{ timeStatus }}
            </span>
        <span
          class="time-separator"
          v-if="remainingTime">
              |
            </span>
        <span
          class="remaining-time"
          v-if="remainingTime">
              {{ remainingTime }}
            </span>
      </div>
    </div>
    <div class="match-teams">
      <div
        v-for="team in teams"
        class="team"
        :key="team.id">
        <div class="team-details">
          {{ team.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EventviewHeaderMatchTeams',
  props: {
    teams: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    timeStatus: {
      type: String,
      required: true,
      default: '',
    },
    remainingTime: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>
