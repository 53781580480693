<template>
  <div class="generated-ticket-header">
    <div class="generated-ticket-header-type">
      {{ productName }} {{ $translate.key(ticketTypes[ticketTypeId]) }}
    </div>

    <div class="generated-ticket-header-time">
      {{ formatDate(ticketDateTime, true) }}
    </div>

    <div
      class="generated-ticket-header-close"
      @click="closeModal"
    >
      <i class="icon icon-close" />
    </div>
  </div>
</template>

<script>
import formatter from '@/services/formatter';

export default {
  name: 'GeneratedTicketHeader',
  props: {
    productName: {
      type: String,
      required: true,
    },
    ticketDateTime: {
      type: Number,
      required: true,
    },
    ticketTypeId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ticketTypes = {
      1: 'combo',
      2: 'system',
      3: 'single',
    };

    const closeModal = () => emit('closeModal');

    return {
      ticketTypes,
      closeModal,
      formatDate: formatter.formatDate,
    };
  },
};
</script>
