<template>
  <div class="betslip-meta">
    <div class="betslip-meta-cards">
      <div
        v-if="selectedTicketType.totalOdds && oddType === 1"
        class="betslip-meta-total-odds betslip-meta-card"
      >
        <span class="betslip-meta-card-label left">
          {{ $translate.key('total_odds') }}
        </span>
        <span class="betslip-meta-card-value left"
              v-if="!hideOddsAndWinnings">
          {{ totalOdds.toFixed(2) }}
        </span>
      </div>

      <div
        v-if="selectedTicketType.system"
        class="betslip-meta-total-combinations betslip-meta-card"
      >
        <span class="betslip-meta-card-label left">{{ $translate.key('total_combinations') }}</span>
        <span class="betslip-meta-card-value left">{{ totalCombinations }}</span>
      </div>

      <div class="betslip-meta-stake betslip-meta-card">
        <span class="betslip-meta-card-label right">{{ $translate.key('general_stake') }}</span>
        <input
          ref="stakeInput"
          type="text"
          class="betslip-meta-stake-input"
          @focus="e => $keyboard.show(e, {
            layout: 'numeric',
            headerText: 'enter_number',
            predefinedValues: moneyDenominations,
            maxValue: balance || 0,
            preview: true,
          })"
          @click.stop
          @input="updateStake"
          :value="stake"
          :disabled="ticketProcessing"
        >
      </div>
    </div>

    <div class="betslip-meta-data">
      <div class="betslip-meta-row max-winning">
        <div class="label">
          {{ $translate.key(maxWinLabel) }}
        </div>
        <div class="value"
             v-if="!hideOddsAndWinnings">
          {{ formatNumber(winnings.max) || '0.00' }}
        </div>
      </div>

      <div
        class="betslip-meta-row secondary min-winning"
        v-if="winnings.min"
      >
        <div class="label">
          {{ $translate.key('min_winning') }}
        </div>

        <div class="value">{{ formatNumber(winnings.min) }}</div>
      </div>

      <div
        class="betslip-meta-row primary bonus"
        v-if="winnings.bonus"
      >
        <div class="label">
          {{ $translate.key('general_bonus') }} ({{ winnings.bonusPercent }}%)
        </div>

        <div class="value">{{ winnings.bonus.toFixed(2) }}</div>
      </div>

      <div
        class="betslip-meta-row secondary payin-tax"
        v-if="payinTax && payinTax.taxAmountRounded && payinTax.payer != 'company'"
      >
        <div class="label">
          {{ $translate.key('payin_tax') }} ({{ payinTax.policy.taxValue }}%)
        </div>

        <div class="value">{{ payinTax.taxAmountRounded.toFixed(2) }}</div>
      </div>
      <div
        class="betslip-meta-row secondary payout-tax"
        v-if="payoutTax && payoutTax.taxAmountRounded"
      >
        <div class="label">
          {{ $translate.key('payout_tax') }} ({{ payoutTax.policy.taxValue }}%)
        </div>

        <div class="value">{{ payoutTax.taxAmountRounded.toFixed(2) }}</div>
      </div>
    </div>

    <div class="betslip-meta-buttons">
      <div
        class="betslip-clear btn"
        :class="{'disabled': ticketProcessing}"
        @click="clearBetslip"
      >
        <i class="icon icon-trash"/>
      </div>

      <div
        v-if="generateCodeEnabled && payinBtnVisible && !betBuilderBet"
        class="generate-code btn"
        :class="{'disabled': betslipBtnDisabled}"
        @click="generateCode(orderedBets)"
      >
        <i class="icon icon-code" />
      </div>

      <div
        class="payin btn"
        v-if="payinBtnVisible"
        :class="{'disabled': payinDisabled}"
        @click="payin(orderedBets, winnings)"
      >
        {{ $translate.key(payinLabel) }}
      </div>

      <div
        class="payin btn"
        v-if="generateCodeEnabled & !payinBtnVisible && !betBuilderBet"
        :class="{'disabled': betslipBtnDisabled}"
        @click="generateCode(orderedBets)"
      >
        {{ $translate.key('generate_code') }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, inject } from 'vue';
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';
import formatter from '@/services/formatter';

export default {
  name: 'BetslipMeta',
  props: {
    payinTax: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    payoutTax: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    selectedTicketType: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    totalCombinations: {
      type: Number,
      required: true,
      default: 0,
    },
    totalOdds: {
      type: Number,
      required: true,
      default: 0,
    },
    winnings: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    stake: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();

    const { oddType } = inject('oddTypeData');

    const {
      betslipOddsOrStatusChanged,
      orderedBets,
      payinDisabled,
      betslipBtnDisabled,
      ticketProcessing,
      isChangedBet,
      payin,
      generateCode,
      clearBetslip,
      updateStake,
      moneyDenominations,
      balance,
      betBuilderBet,
      betBuilderMinBetCountValid,
    } = useBetslip();

    const hideOddsAndWinnings = computed(() => (betBuilderBet.value
      && !betBuilderMinBetCountValid.value));

    const maxWinLabel = computed(() => (
      props.selectedTicketType.system ? 'max_winning' : 'possible_winning'
    ));

    const payinLabel = computed(() => (
      betslipOddsOrStatusChanged.value && !betBuilderBet.value
        ? 'accept_and_payin' : 'general_pay_in'
    ));

    const generateCodeEnabled = computed(() => store.getters.generateCodeEnabled);

    const payinBtnVisible = computed(() => store.getters.payinBtnVisible);

    watch(orderedBets, (bets) => {
      if (betslipOddsOrStatusChanged.value) return;

      const changedBetExist = _.find(bets, isChangedBet);

      if (changedBetExist) store.dispatch('updateBetslipStatus', true);
    });

    return {
      oddType,
      payinLabel,
      maxWinLabel,
      orderedBets,
      payinDisabled,
      betslipBtnDisabled,
      ticketProcessing,
      generateCodeEnabled,
      clearBetslip,
      payin,
      generateCode,
      updateStake,
      formatNumber: formatter.formatNumber,
      moneyDenominations,
      balance,
      payinBtnVisible,
      betBuilderBet,
      hideOddsAndWinnings,
    };
  },
};
</script>
