<template>
  <div class="header">
    <div class="header-grid-modes">
      <div
        class="header-grid-mode"
        :class="{'active': activeView === view.name}"
        v-for="view in gridViews"
        :key="view.id"
        @click="setActiveView(view.name)"
      >
        {{ $translate.key(view.name) }}
      </div>
    </div>
    <div class="header-filters">
      <div class="header-search">
        <input
          type="text"
          :class="[
            'header-search-input',
            { 'clickable-input': clickableInput }]"
          v-model="searchModel"
          @focus="$keyboard.show"
          :placeholder="$translate.key('search')"
        >
        <div :class="[
          'header-search-button',
          { 'clickable-input': clickableInput }
        ]">
          <i
            :class="[searchNotEmpty ? 'icon icon-close' : 'icon icon-search-a']"
            @click.stop="searchNotEmpty ? clearSearch() : appNewVersionLog()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useSearch from '@/composables/search';
import useKeyboard from '@/composables/virtualKeyboard';
import useViewScrollPosition from '@/composables/viewScrollPosition';

export default {
  name: 'Header',
  setup() {
    const store = useStore();
    const {
      clearSearch,
      searchNotEmpty,
      searchQuery,
      setSearchQuery,
    } = useSearch();
    const { storeCurrentOverviewScrollPos } = useViewScrollPosition();

    const searchModel = computed({
      get: () => searchQuery.value,
      set: setSearchQuery,
    });

    const gridViews = computed(() => store.getters.gridViews);
    const activeView = computed(() => store.getters.activeView);

    const setActiveView = (view) => {
      if (view === activeView.value) return;
      clearSearch();
      store.dispatch('setActiveView', view);

      if (view === 'eventview') {
        storeCurrentOverviewScrollPos(searchNotEmpty.value);
      }
    };

    const clickableInput = computed(() => {
      const { keyboard } = useKeyboard();
      return keyboard.value.visible && keyboard.value.layout === 'compact';
    });

    const appNewVersionLog = () => console.log('search');

    return {
      activeView,
      clearSearch,
      gridViews,
      searchModel,
      searchNotEmpty,
      setActiveView,
      clickableInput,
      appNewVersionLog,
    };
  },
};
</script>
