import integrations from '@/components/integrations';
import companyUuids from '@/services/companyUuids';

const defaultTheme = () => import('../components/common/ThemeImporter');
const defaultLightTheme = () => import('../components/common/LightThemeImporter');

export default function useTheme() {
  const setThemeComponent = (theme, companyName = '', companyUuid = '') => {
    const company = companyUuids.getCompanyByUuid(companyUuid) || companyName;
    const clientOverrides = integrations[company];
    const integrationComponent = clientOverrides?.theme;

    const defaultComponent = theme === 'light' ? defaultLightTheme : defaultTheme;

    return integrationComponent || defaultComponent;
  };

  return {
    setThemeComponent,
  };
}
