<template>
  <div class="eventview-header">
    <div class="eventview-header-match-header">
      <div class="match-header-category">
        {{ categoryName }} | {{ tournamentName }}
      </div>
      <button
        v-if="liveMatchTrackerEnabled && !liveMatchTrackerError"
        @click="toggleLmt"
        :class="[
          'eventview-header_lmt-btn',
          { '-active' : liveMatchTrackerActive },
        ]">
          <i class="icon icon-sport-soccer-field" />
      </button>
    </div>
    <div class="eventview-header-match-body">
      <EventviewHeaderMatchTeams
        :teams="teams"
        :time-status="timeStatus"
        :remaining-time="remainingTime"
      />
      <EventviewHeaderMatchResults
        :sport-name="sportName"
        :result="result"/>
      <ServerIcons
        v-if="teamsHaveServer"
        class="eventview-header-server-icons"
        :teams="teams"
        :result-label="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventviewHeaderMatchTeams from './EventviewHeaderMatchTeams';
import ServerIcons from '@/components/common/ServerIcons';
import EventviewHeaderMatchResults from './EventviewHeaderMatchResults';
import matchDetailsMixin from '@/mixins/matchDetails';
import matchResultsMixin from '@/mixins/matchResults';

export default {
  name: 'EventviewHeader',
  components: {
    EventviewHeaderMatchTeams,
    EventviewHeaderMatchResults,
    ServerIcons,
  },
  mixins: [matchDetailsMixin, matchResultsMixin],
  props: {
    match: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      'liveMatchTrackerActive',
      'liveMatchTrackerEnabled',
      'liveMatchTrackerError',
    ]),
  },
  methods: {
    toggleLmt() {
      this.$store.dispatch('toggleLmt', !this.liveMatchTrackerActive);
    },
  },
};
</script>
