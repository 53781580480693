import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useNotifications() {
  const store = useStore();

  const betslipRulesNotifications = computed(() => store.getters.betslipRulesNotifications);
  const betslipNotifications = computed(() => store.getters.betslipNotifications);

  const balanceWarningTranslation = computed(() => (
    store.getters.translations?.ticket_balance_warning || 'ticket_balance_warning'
  ));

  const balanceValid = computed(() => {
    const stakeValue = parseFloat(store.getters.stake || store.getters.config?.stake);

    return parseFloat(store.getters.terminal?.balance) >= stakeValue;
  });

  const balanceNotification = computed(() => ({
    id: 'balanceWarning',
    content: balanceWarningTranslation.value,
    active: !balanceValid.value,
    type: 'warning',
    dismissable: false,
  }));

  const noConnectionNotification = {
    id: 'no_connection',
    type: 'error',
    content: store.getters.translate('no_connection'),
  };

  const selectTicketNotification = {
    message: store.getters.translate('select_ticket') || 'select_ticket',
    type: 'info',
    delay: 3000,
  };

  const removeBetslipNotification = (id) => store.dispatch('removeBetslipNotification', id);

  const printCopySucessNotification = {
    message: store.getters.translate('ticket.print_copy_success') || 'ticket.print_copy_success',
    type: 'success',
    delay: 3000,
  };

  return {
    balanceNotification,
    betslipNotifications,
    noConnectionNotification,
    selectTicketNotification,
    betslipRulesNotifications,
    removeBetslipNotification,
    printCopySucessNotification,
  };
}
