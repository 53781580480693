<template>
  <div
    v-if="ticket.id"
    class="ticket-check scrollbar">
    <TicketCheckHeader :ticket="ticket" />
    <TicketCheckBody :ticket="ticket" />
    <TicketCheckFooter :ticket="ticket" />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import TicketCheckHeader from './TicketCheckHeader';
import TicketCheckBody from './TicketCheckBody';
import TicketCheckFooter from './TicketCheckFooter';

export default {
  components: {
    TicketCheckHeader,
    TicketCheckBody,
    TicketCheckFooter,
  },
  setup() {
    const store = useStore();

    const ticket = computed(() => store.getters.checkedTicket || {});

    return {
      ticket,
    };
  },
};
</script>
