<template>
  <div class="generated-ticket-body scrollbar">
    <div class="generated-ticket-bets">
      <GeneratedTicketBet
        v-for="(bet, i) in bets"
        :key="i"
        :bet="bet"
        :ticket-type-id="ticketTypeId"
      />
    </div>

    <Systems
      v-if="ticketTypeId === 2"
      :ticket-combination-groups="ticketCombinationGroups"
      :number-of-bankers="numberOfBankers"
    />

    <GeneratedTicketMeta
      :payin="payin"
      :bonus="bonus"
      :payin-tax="payinTax"
      :total-odd="totalOdd"
      :max-possible-win="maxPossibleWin"
      :max-possible-payout-tax="maxPossiblePayoutTax"
      :odd-type="oddType"
      :odd-formatter-active="oddFormatterActive"
      :ticket-type-id="ticketTypeId"
    />

    <ScrollButtons
      v-if="scrollButtonsEnabled"
      scroll-content="generated-ticket-body" />
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import GeneratedTicketBet from './GeneratedTicketBet';
import GeneratedTicketMeta from './GeneratedTicketMeta';
import Systems from './systems/Systems';
import ScrollButtons from '@/components/common/ScrollButtons';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  name: 'GeneratedTicketBody',
  components: {
    GeneratedTicketBet,
    GeneratedTicketMeta,
    Systems,
    ScrollButtons,
  },
  props: {
    bets: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    ticketTypeId: {
      type: Number,
      required: true,
    },
    totalOdd: {
      type: Number,
      required: true,
    },
    payin: {
      type: Number,
      required: true,
    },
    payinTax: {
      type: Number,
      required: true,
    },
    maxPossibleWin: {
      type: Number,
      required: true,
    },
    bonus: {
      type: Object,
      required: true,
    },
    maxPossiblePayoutTax: {
      type: Number,
      required: true,
    },
    ticketCombinationGroups: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  setup(props) {
    const { oddType, oddFormatterActive } = inject('oddTypeData');

    const numberOfBankers = computed(() => (
      props.bets.filter((bet) => bet.ticketBetBank === 1).length
    ));

    const { scrollButtonsEnabled } = useScrollButtons();

    return {
      oddType,
      numberOfBankers,
      oddFormatterActive,
      scrollButtonsEnabled,
    };
  },
};
</script>
