import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useMatch() {
  const store = useStore();

  const selectedMatchId = computed(() => store.getters.selectedMatchId);

  /**
   *
   * @param {String} matchId
   * @param {Boolean} preventReselect - Flag for preventing match re-select in Eventview and Betslip
   */
  const selectMatch = (matchId, preventReselect = false) => {
    if (preventReselect && selectedMatchId.value === matchId) return;

    store.dispatch('setActiveMatch', matchId);

    if (store.getters.selectedBetGroupId !== 'all') {
      store.dispatch('selectBetGroup', 'all');
    }
  };

  return {
    selectedMatchId,
    selectMatch,
  };
}
