<template>
    <div class="empty-list-message">
      <em class="icon icon-lock-a"></em>
      {{ $translate.key(message) }}
    </div>
</template>

<script>
export default {
  name: 'EmptyListMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
