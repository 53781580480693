<template>
  <div class="generated-ticket-systems-header">
    <div class="generated-ticket-systems-header-text">
      {{ $translate.key(translationKey) }}
    </div>

    <div class="generated-ticket-systems-header-icon">
      <i :class="['icon',
        {'icon-arrow-down-a': combinationsCollapsed},
        {'icon-arrow-up-a': !combinationsCollapsed}]"
      />
   </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'SystemsHeader',
  props: {
    combinationsCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const translationKey = computed(() => (
      props.combinationsCollapsed ? 'show_combinations' : 'hide_combinations'
    ));

    return { translationKey };
  },
};
</script>
