export default {

  /**
   *
   * @param {string} sportId
   * @param {Array} resultsArray
   * @param {string} resultGame
   * @param {string} result
   * @param {Array} dismissals
   * @param {string} matchStatus
   * @returns {Array}
   */

  formatSportMatchResults({
    sportId,
    resultsArray,
    resultGame,
    result,
    dismissals,
    matchStatus,
  }) {
    let newResultsArray = resultsArray;
    switch (_.parseInt(sportId)) {
      case 1:
        // Soccer
        if (_.size(newResultsArray) === 3) newResultsArray.splice(1, 1);
        break;
      case 5:
        // Tennis
        if (resultGame) {
          let game = '';
          game = _.replace(resultGame, '50', 'A');
          newResultsArray.splice(-1, 1, game); // Replace set with game result
        }
        // Remove final result
        if (matchStatus === 'ENDED') {
          newResultsArray.pop();
        }
        break;
      case 19:
        // Snooker
        if (_.size(newResultsArray) > 2) {
          newResultsArray = newResultsArray.splice(-2);
        }
        break;
      case 21: {
        // Cricket
        const cricketResult = result.split(':')
          .map((item, i) => (_.isNumber(dismissals[i]) ? `${item}/${dismissals[i]}` : item))
          .join(':');
        newResultsArray = [cricketResult];
        break;
      }
      case 22:
        // Darts
        if (resultGame) newResultsArray.splice(-1, 1, resultGame); break;
      default:
        // Default result
        break;
    }
    return newResultsArray;
  },
};
