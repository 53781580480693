<template>
  <div class="eventview-bet-groups">
    <EventviewBetGroup
      v-for="(group, index) in displayedBetGroups"
      :key="index"
      :bet-group="group"
    />
    <EventviewBetGroupAdditional
      v-if="hasMoreGroups"
      :bet-groups="additionalBetGroups"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/breakpoints';
import EventviewBetGroup from './EventviewBetGroup';
import EventviewBetGroupAdditional from './EventviewBetGroupAdditional';
import useLiveMatchTracker from '@/composables/liveMatchTracker';

export default {
  name: 'EventviewBetGroups',
  components: {
    EventviewBetGroup,
    EventviewBetGroupAdditional,
  },
  props: {
    sportBetGroups: {
      type: Array,
      required: true,
    },
    idSport: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    const { breakpoints } = useBreakpoints();
    const { isLmtRdy } = useLiveMatchTracker();

    const displayedGroupsNumber = computed(() => {
      const {
        small,
        medium,
        large,
        extraLarge,
        ultraLarge,
        portraitSmall,
      } = breakpoints.value;

      if (small || portraitSmall) return 3;
      if (medium || large) return isLmtRdy.value && large ? 2 : 4;
      if (extraLarge) return isLmtRdy.value ? 5 : 7;
      if (ultraLarge) return isLmtRdy.value ? 7 : 9;
      return 9;
    });

    const betBuilderEnabled = computed(() => store.getters.config?.betBuilderEnabled);

    const getFixedGroups = () => {
      const fixedGroups = [
        {
          idBetGroup: 'all',
          betGroupShortName: store.getters.translate('general_all'),
        },
      ];
      if (Number(props.idSport) === 1 && betBuilderEnabled.value) {
        fixedGroups.push({
          idBetGroup: 'betBuilder',
          betGroupShortName: store.getters.translate('bet_builder'),
        });
      }
      return fixedGroups;
    };

    const allGroups = computed(() => (_.concat(getFixedGroups(), props.sportBetGroups)));

    const displayedBetGroups = computed(() => (
      _.take(allGroups.value, displayedGroupsNumber.value)
    ));
    const hasMoreGroups = computed(() => (_.size(allGroups.value) > displayedGroupsNumber.value));

    const additionalBetGroups = computed(() => {
      if (!hasMoreGroups.value) return [];
      return allGroups.value.slice(displayedGroupsNumber.value);
    });

    return {
      additionalBetGroups,
      displayedBetGroups,
      hasMoreGroups,
    };
  },
};
</script>
