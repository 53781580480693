import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/layouts/Main';
import TicketCheckPreview from '@/layouts/TicketCheckPreview';
import TicketHistory from '@/layouts/TicketHistory';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Main },
    { path: '/ticket-preview', component: TicketCheckPreview },
    { path: '/ticket-history', component: TicketHistory },
  ],
});

export default router;
