import uuidv4 from 'uuid/v4';
import { toAmerican, toFractional } from '@/utils/oddConverter';
import { getTax } from '@/services/ticket-calculations/helper';

function formatCombination(betsNumber, stake, bet) {
  return {
    combinationBets: [bet.idMbo],
    combinationAmount: stake / betsNumber,
  };
}

function prepareTicketCombinations(bets, ticketSystems, activeTicketType, stake) {
  if (activeTicketType === 1) return [];

  if (activeTicketType === 2) {
    return _.reduce(ticketSystems, (selectedSystems, system) => {
      if (!system.selected) return selectedSystems;

      selectedSystems.push({
        parlays: system.id,
        events: system.of,
        amount: null,
      });

      return selectedSystems;
    }, []);
  }

  const combinations = _.map(bets, (bet) => formatCombination(bets.length, stake, bet));

  return [{
    amount: null,
    parlays: 1,
    events: bets.length,
    combinations,
  }];
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export default {
  formatBetsForPayin(bets, ticketTypeId, stake) {
    return _.map(bets, (bet) => {
      const banker = bet.banker || ticketTypeId === 1 ? 1 : 0;
      return {
        idMatchBetOutcome: bet.idMbo,
        ticketBetBank: ticketTypeId === 3 ? 0 : banker,
        ticketBetWay: 0,
        mboOddValue: bet.oddValue,
        outcomeOdd: bet.oddValue,
        betSpecialValue: bet.sv,
        stake,
        // Properties needed for MNE tax
        match: bet.matchName,
        outcome: bet.betName, // Not mistake, outcome is bet name on MTS, wrong naming
        pick: bet.outcomeName,
        matchDate: new Date(bet.matchDateTime),
      };
    });
  },
  formatBetBuilderBetsForPayin(bets, stake, betBuilderCalculation) {
    const bet = bets[0] || {};
    if (!bet) return [];

    return [
      {
        type: 'CUSTOM_BET',
        idMatchBetOutcome: betBuilderCalculation.matchBetOutcomeId,
        ticketBetBank: 1,
        ticketBetWay: 0,
        mboOddValue: betBuilderCalculation.odd,
        // Properties needed for MNE tax
        match: bet.matchName,
        outcome: betBuilderCalculation.betOutcomeId,
        stake,
        pick: bet.outcomeName,
        matchDate: new Date(bet.matchDateTime),
        selections: bets.map((b) => ({ idMatchBetOutcome: b.idMbo, mboOddValue: b.oddValue })),
      },
    ];
  },
  formatTicketForPayin(
    bets, ticketSystems, ticketTypeId, stake, taxes, bonusValue, betBuilderCalculation,
  ) {
    const combinationGroups = prepareTicketCombinations(bets, ticketSystems, ticketTypeId, stake);
    const payinTax = getTax(taxes?.payin?.policy, stake);
    const payoutTax = getTax(taxes?.payout?.policy, stake);

    const formattedBets = betBuilderCalculation
      ? this.formatBetBuilderBetsForPayin(bets, stake, betBuilderCalculation)
      : this.formatBetsForPayin(bets, ticketTypeId, stake);

    return {
      bets: formattedBets,
      payin: stake,
      ticketComment: '',
      ticketCombinationGroups: combinationGroups,
      ticketType: ticketTypeId,
      action: 'Add',
      product: 'LiveBetting',
      productId: 'LiveBetting',
      requestUuid: uuidv4(),
      winnings: {
        bonusTotal: bonusValue,
      },
      taxes: {
        payin: {
          taxAmountRounded: payinTax.taxAmountRounded,
        },
        payout: {
          taxAmountRounded: payoutTax.taxAmountRounded,
        },
      },
      config: {
        ticketType: ticketTypeId,
        setFormatPayin: false,
        group: {
          id: 'LiveBetting',
        },
      },
    };
  },
  formatTicketForPrint(ticket, betslipConfig) {
    function betNameFormatter(bet) {
      const patterns = ['[X]', '[Y]', '[Z]', '[Q]'];
      const parts = bet.specialValue.toString().split('|');
      let replaced = false;
      let formattedName = bet.bet.betDisplayName || bet.bet.betShortName;

      _.each(parts, (part, i) => {
        const pattern = patterns[i];

        if (!_.includes(formattedName, pattern)) return;

        formattedName = _.replace(formattedName, pattern, part);
        replaced = true;
      });

      if (!replaced) {
        const sv = !bet.bet.betGroupingEnabled && bet.specialValue !== '*' ? `(${bet.specialValue})` : '';
        formattedName = `${formattedName} ${sv}`;
      }

      return formattedName;
    }

    return {
      ...ticket,
      taxes: betslipConfig?.taxes || {},
      bets: _.map(ticket.bets, (bet) => {
        const formattedName = betNameFormatter(bet);

        return {
          ...bet,
          bet: {
            ...bet.bet,
            formattedName,
          },
          selections: _.map(bet.selections, (selection) => {
            const selectionFormattedName = betNameFormatter(selection);

            return {
              ...selection,
              bet: {
                ...selection.bet,
                selectionFormattedName,
              },
            };
          }),
        };
      }),
    };
  },
  formatTicketForGenerateCode(bets, ticketSystems, ticketTypeId, stake) {
    const combinationGroups = prepareTicketCombinations(bets, ticketSystems, ticketTypeId, stake);

    return {
      bets: this.formatBetsForPayin(bets, ticketTypeId, stake),
      payin: stake,
      ticketCombinationGroups: combinationGroups,
      ticketType: ticketTypeId,
    };
  },

  formatTicketForSeven(ticket, translation, taxes) {
    if (_.isEmpty(ticket)) return {};

    const formatted = this.formatTicketForPrint(ticket, { taxes });
    const id = _.find(formatted.ticketHashes, (hash) => _.lowerCase(hash.type) === 'normal');

    formatted.product = formatted.product || 'LiveBetting';
    formatted.translation = translation;
    formatted.id = id ? id.hash : `${formatted.id}`;
    formatted.taxes = taxes;

    if (!formatted.status) formatted.status = {};
    if (formatted.type === '2') formatted.ticketCombinationGroups = ticket.ticketCombinationGroups;

    return formatted;
  },

  formatMetaDataForGenerateCode(requestUuid, cpvUuid, language) {
    return {
      product: 'LiveBetting',
      deliveryPlatform: 'Terminal',
      cpvUuid,
      requestUuid,
      locale: language,
      sources: [],
    };
  },

  formatTicketCheckBet(bet) {
    return {
      sv: bet.specialValue,
      idMb: bet.bet.idMatchBet,
      idMatch: bet.matchId,
      betName: bet.bet.betShortName || bet.bet.betDisplayName,
      idMbo: bet.betOutcome.idMatchBetOutcome,
      originalOdd: bet.oddValue,
      banker: bet.ticketBetBank === '1',
      removedMatch: bet.removedMatch, //
      matchName: bet.matchDisplayName,
    };
  },

  formatDate(date, utc = false, locale = 'en-GB') {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    if (utc) options.timeZone = 'UTC';

    return new Date(date).toLocaleDateString(locale, options);
  },

  formatDateForTicket(date) {
    const yearMonthDate = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ];

    const hoursMinutesSeconds = [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ];

    return `${yearMonthDate.join('.')} ${hoursMinutesSeconds.join(':')}`;
  },

  formatBetName(bet) {
    let replaced = false;
    const patterns = ['[X]', '[Y]', '[Z]', '[Q]'];
    const parts = bet.mbSpecialValue.toString().split('|');
    let { betName } = bet;

    _.each(parts, (part, i) => {
      const pattern = patterns[i];

      if (!_.includes(betName, pattern)) return;

      betName = _.replace(betName, pattern, part);
      replaced = true;
    });
    // Set special value to the end if no replace occurred
    if (!replaced) {
      const sv = !bet.grouped ? `(${bet.mbSpecialValue})` : '';
      betName = `${betName} ${sv}`;
    }
    return betName;
  },

  formatTeamsResult(result) {
    const team1 = result && result.split(':')[0] !== '-' ? result.split(':')[0] : '';
    const team2 = result && result.split(':')[1] !== '-' ? result.split(':')[1] : '';
    return {
      team1,
      team2,
    };
  },

  formatNumber(number = '') {
    return number.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  },

  formatOddType(odd, type) {
    if (type === 2) return toFractional(odd);
    if (type === 3) return toAmerican(odd);

    return odd.toFixed(2);
  },

  removeBetNamePlaceholders(betName) {
    let name = betName;
    _.each(['[X]', '[Y]', '[Z]', '[Q]'], (placeholder) => {
      name = name.replace(placeholder, '');
    });

    return name;
  },
};
