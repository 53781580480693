import {
  getBankerOdds,
  getNonBankerOdds,
  getSelectedSystems,
  getSystemCombsOdds,
  getActiveBonus,
  getStakeAfterTax,
} from './helper';

import {
  getStakePerCombination,
  systemTotalCombinations,
} from '../betslip-rules/helper';

import _ from '@/services/lodash';

const calculateSystemWinnings = ({
  bets, systems, taxPolicy, stake, bonusConfig,
}) => {
  const bankerOdds = getBankerOdds({ bets });
  const combsList = getNonBankerOdds({ bets });

  let bankersSum = 1;
  let systemsSum = 0;
  let systemsBonusSum = 0;
  let bonusPercent = 0;
  let systemSmallestSum = 0;

  const stakeAfterTax = getStakeAfterTax({ stake, taxPolicy });
  const selectedSystems = getSelectedSystems(systems);
  const combinations = systemTotalCombinations(selectedSystems);
  const stakePerCombination = getStakePerCombination(stakeAfterTax, combinations);

  _.each(bankerOdds, (odd) => {
    if (odd) bankersSum *= odd;
  });

  _.each(selectedSystems, (selectedSystem, index) => {
    const systemOdds = [];

    getSystemCombsOdds(
      combsList,
      selectedSystem.id,
      (odds) => {
        let sum = 1;

        _.each(odds, (odd) => {
          if (odd) sum *= odd;
        });

        systemOdds.push(sum);

        // If there is bonus, do calculation of bonus
        if (bonusConfig && bonusConfig.length) {
          const activeBonusRule = getActiveBonus({ odds: odds.concat(bankerOdds), bonusConfig });

          if (activeBonusRule) {
            if (bonusPercent < activeBonusRule.bonusPercent) {
              ({ bonusPercent } = activeBonusRule);
            }

            systemsBonusSum += (
              sum * bankersSum * (activeBonusRule.bonusPercent / 100) * stakePerCombination
            );
          }
        }
      },
    );
    const minWinning = Math.min(...systemOdds) * stakePerCombination * bankersSum;
    const maxWinning = _.sumBy(systemOdds, (odd) => odd * stakePerCombination * bankersSum);

    if (index === 0 || minWinning < systemSmallestSum) systemSmallestSum = minWinning;
    systemsSum += maxWinning;
  });

  return {
    min: systemSmallestSum,
    max: systemsSum + systemsBonusSum,
    bonus: systemsBonusSum,
    bonusPercent,
  };
};

export default {
  calculateSystemWinnings,
};
