import { useStore } from 'vuex';

const lettersToReplace = ['Č', 'Ć', 'Š', 'Đ', 'Ž'];
const replacementLetters = ['C', 'C', 'S', 'D', 'Z'];

const team1Name = (match) => match.team1ShortName || match.team1Name;

let transformedMatchOrder = [];

export default function useFormatter() {
  const store = useStore();

  const formatSportWithMatches = (sport) => ({
    id: sport.idSport,
    name: sport.sportName || sport.sportShortName,
    sportPosition: sport.sportPosition,
    matchesCount: sport.matchesCount,
    filteredMatchesCount: sport.filteredMatchesCount,
  });

  const formattedCategoryName = ({ categoryName }) => {
    const categoryNameFirst = categoryName.charAt(0);
    const indexToReplace = lettersToReplace.indexOf(categoryNameFirst.toUpperCase());

    if (indexToReplace === -1) return categoryName;

    return categoryName.replace(categoryNameFirst, replacementLetters[indexToReplace]);
  };

  const formatMatch = (match) => {
    const category = store.getters.matchCategory(match.idCategory);
    const tournament = store.getters.matchTournament(match.idTournament);
    const tournamentPosition = tournament.tournamentPosition || 999;
    const categoryName = category.categoryShortName || category.categoryName;
    const tournamentName = tournament.tournamentShortName || tournament.tournamentName;

    return {
      ...match,
      tournamentPosition,
      categoryName,
      tournamentName,
    };
  };

  const formatRemovedMatchTicketBet = (ticketBet) => ({
    removedMatch: true,
    banker: ticketBet.banker,
    locked: true,
    idMatch: ticketBet.idMatch,
    idMb: ticketBet.idMb,
    idMbo: ticketBet.idMbo,
    matchName: ticketBet.matchName,
    betName: ticketBet.betName,
    originalOdd: ticketBet.originalOdd,
    oddValue: 1,
    isBetBuilderBet: ticketBet.isBetBuilderBet,
  });

  const formatTicketBetMatchName = (match) => {
    const team1 = match.team1ShortName || match.team1Name;
    const team2 = match.team2ShortName || match.team2Name;

    return `${match.tournamentPrefix} ${team1} - ${match.tournamentPrefix} ${team2}`;
  };

  const formatTicketBet = (match, ticketBet, metaBet, matchBet) => {
    if (!match) return { locked: true };
    const matchName = formatTicketBetMatchName(match);
    let outcome = _.find(matchBet.mbOutcomes, { idMbo: ticketBet.idMbo });
    const locked = !match.bettingStatus || _.isEmpty(matchBet) || !matchBet.mbActive
      || !outcome || (outcome && !outcome.mboActive) || !metaBet.betActive;
    outcome = outcome || {};
    const metaBetOutcome = (metaBet.betOutcomes || {})[outcome.idBo] || {};
    const outcomeName = outcome.mboShortDisplayName || outcome.mboDisplayName
      || metaBetOutcome.betOutcomeShortName || metaBetOutcome.betOutcomeName || outcome.mboType;

    return {
      locked,
      banker: ticketBet.banker,
      idMbo: ticketBet.idMbo,
      idMatch: match.idMatch,
      idSport: match.idSport,
      matchName,
      betName: ticketBet.betName,
      sv: ticketBet.sv,
      outcomeName,
      oddValue: outcome.mboOddValue,
      oddChanged: outcome.oddChanged,
      oldOdd: outcome.oldOdd,
      newOdd: outcome.newOdd,
      originalOdd: ticketBet.originalOdd,
      matchDateTime: match.matchDateTime,
      isBetBuilderBet: ticketBet.isBetBuilderBet,
    };
  };

  const formatBetNameAndOutcomes = (baseBet, matchBet) => ({
    betName: baseBet.betName,
    name: baseBet.betShortName || baseBet.betName,
    mbOutcomes: _.some(matchBet.mbOutcomes, ({ mboType }) => mboType.match(/dynamic|no goal/))
      ? _.orderBy(matchBet.mbOutcomes,
        ['mboActive', 'mboOddValue', 'mboDisplayName', 'mboPosition'],
        ['desc', 'asc'])
      : _.orderBy(matchBet.mbOutcomes, 'mboPosition'),
  });

  const formatBetOutcomesName = (outcomes, baseBet) => (_.each(outcomes, (outcome) => {
    const baseOutcome = baseBet.betOutcomes[outcome.idBo];
    if (!baseOutcome) return;

    _.assign(outcome, {
      baseBetOutcomeName: baseOutcome.betOutcomeShortName || baseOutcome.betOutcomeName,
    });
  }));

  const formatBetSpecialValue = (bet) => {
    const specialValueTennis = bet.mbSpecialValue.split('|')[1] ? bet.mbSpecialValue.split('|')[1] : '';
    const sv = specialValueTennis || bet.mbSpecialValue;
    return {
      sv: sv && sv !== '*' ? parseFloat(sv) : '',
    };
  };

  const formattedMatchesOrder = () => {
    if (transformedMatchOrder.length) return transformedMatchOrder;

    const { matchesOrder } = store.getters;

    _.each(matchesOrder, (value, key) => {
      if (value === 'categoryName') matchesOrder[key] = formattedCategoryName;
      if (value === 'team1Name') matchesOrder[key] = team1Name;
    });

    transformedMatchOrder = matchesOrder;
    return transformedMatchOrder;
  };

  const sortMatches = (matches) => {
    const matchOrder = formattedMatchesOrder();

    return _.orderBy(matches, matchOrder);
  };

  const sortBetsBySpecialValue = (bets) => (_.sortBy(bets,
    [(bet) => parseFloat(bet.mbSpecialValue)]));

  const formatSidebarSportMatches = (matches) => _.map(matches, (match) => {
    const {
      matchBets,
      matchScores,
      matchCards,
      extraInfo,
      ...matchData
    } = match;

    return matchData;
  });

  return {
    formatBetNameAndOutcomes,
    formatBetOutcomesName,
    formatBetSpecialValue,
    formatMatch,
    formatRemovedMatchTicketBet,
    formatSportWithMatches,
    formatTicketBet,
    sortBetsBySpecialValue,
    sortMatches,
    formatSidebarSportMatches,
  };
}
