<template>
  <div class="generated-ticket-systems">
    <div
      data-test="toggle"
      @click="combinationsCollapsed = !combinationsCollapsed"
    >
      <SystemsHeader :combinations-collapsed="combinationsCollapsed"/>
    </div>

    <SystemsGroup
      v-if="!combinationsCollapsed"
      :ticket-combination-groups="ticketCombinationGroups"
      :number-of-bankers="numberOfBankers"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import SystemsHeader from './SystemsHeader';
import SystemsGroup from './SystemsGroup';

export default {
  name: 'SystemCombinations',
  props: {
    ticketCombinationGroups: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    numberOfBankers: {
      type: Number,
      required: true,
    },
  },
  components: {
    SystemsHeader,
    SystemsGroup,
  },
  setup() {
    const combinationsCollapsed = ref(true);

    return {
      combinationsCollapsed,
    };
  },
};
</script>
