const serverEnv = process.env.SERVER;

const apiRoutes = {
  dev: {
    betBuilder: 'https://sportsbook-public-api-dev.nsoft.com',
  },
  staging: {
    betBuilder: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging',
    sportsbookPublicApi: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
    tc: 'https://tc-service.gb.nsoftcdn.com/v2/b2b/translations',
    liveTickets: 'https://services-staging.7platform.com',
    smGateway: 'https://sports-sm-gateway.staging.de-2.nsoftcdn.com',
    gravityApi: 'https://staging-gravity-service.7platform.com',
  },
  production: {
    betBuilder: 'https://sports-mts-api.gb.nsoftcdn.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production',
    sportsbookPublicApi: 'https://sports-mts-api.gb.nsoftcdn.com',
    tc: 'https://tc-service.gb.nsoftcdn.com/v2/b2b/translations',
    liveTickets: 'https://menhir.{dcUrlFragment}.nsoftcdn.com',
    smGateway: 'https://sports-sm-gateway.de-2.nsoftcdn.com',
    gravityApi: 'https://gravity.de.nsoftcdn.com',
  },
};

export default apiRoutes[serverEnv];
