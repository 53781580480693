<template>
  <div ref="sportGroup" class="eventview-sidebar-sport-group">
    <div
      class="group-header"
      :class="{ collapsed: sportCollapsed }"
      @click="sportCollapsed = !sportCollapsed">
      <i
        class="group-header-icon icon"
        :class="`icon-sport-${sportId}`" />
      <div
        class="group-header-sport-color"
        v-if="enableSportColor"
        :class="`sport-color-${sportId}`">
      </div>
      <div class="group-header-label">
        {{ label }}
      </div>
      <div class="group-header-count">
        ({{ filteredMatchesLength }})
      </div>
    </div>
    <div
      class="group-body"
      v-show="!sportCollapsed">
      <EventviewSidebarMatch
        v-for="item in filteredMatchesBySport"
        :key="item.idMatch"
        :match="item"
        :is-visible="isVisible[item.idMatch]"
      />
    </div>
    <div ref="loader" data-id="main-eventview"></div>
  </div>
</template>

<script>
import {
  computed, ref, onMounted, watch, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { useMatchSportFocus } from '@/composables/matchFocus';
import EventviewSidebarMatch from './EventviewSidebarMatch';
import useIntersectionObserver from '@/composables/viewIntersectionObserver';
import useMatches from '@/composables/matches';
import useFormatter from '@/composables/formatter';

export default {
  name: 'EventviewSidebarSportGroup',
  components: {
    EventviewSidebarMatch,
  },
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    sportId: {
      type: String,
      required: true,
    },
    enableSportColor: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { initFocusedSportWatcher } = useMatchSportFocus(props.sportId);
    const sportGroup = ref(null);
    const loader = ref(null);
    const { isVisible, observer } = useIntersectionObserver(loader, true);
    const { tournamentActive, categoryActive } = useMatches();
    const { formatMatch, sortMatches } = useFormatter();

    const sportCollapsed = ref(false);
    const expandSport = () => {
      sportCollapsed.value = false;
    };

    initFocusedSportWatcher(expandSport);

    const sportMatches = computed(() => store.getters.matchesBySport(props.sportId));

    const filteredMatchesBySport = computed(() => {
      const filteredMatches = _.reduce(sportMatches.value, (list, match) => {
        if (!categoryActive(match) || !tournamentActive(match)) {
          return list;
        }
        const formattedMatch = formatMatch(match);
        list.push(formattedMatch);
        return list;
      }, []);

      return sortMatches(filteredMatches);
    });

    const filteredMatchesLength = computed(() => filteredMatchesBySport.value.length);

    onMounted(() => {
      const divs = sportGroup.value?.querySelectorAll('.eventview-sidebar-match');

      divs.forEach((div) => observer.value.observe(div));
    });

    watch(filteredMatchesLength, (val) => {
      nextTick(() => {
        if (!val) return;
        const divs = sportGroup.value?.querySelectorAll('.eventview-sidebar-match');
        divs.forEach((div) => observer.value.observe(div));
      });
    });

    return {
      sportCollapsed,
      sportGroup,
      loader,
      isVisible,
      filteredMatchesLength,
      filteredMatchesBySport,
    };
  },
};
</script>
