import uuidv4 from 'uuid/v4';
import types from './mutation-types';
import actionTypes from './action-types';
import socket from '../services/socket';
import betBuilderSocket from '../services/betBuilderSocket';
import translations from '../services/translations';
import formatter from '@/services/formatter';
import errorHandler from '@/services/errorHandler';
import gateway from '@/services/gateway';
import api from '@/services/api';
import { validateBetAdd } from '@/services/betslip-rules/helper';
import companyUuids from '@/services/companyUuids';
import { sirConnect } from '@/services/sportradarWidgets';
import router from '@/router';

let autocheckerTimeout;

const printedTickets = [];

export default {
  initSocket(store, {
    cpvUuid, encoding, language, deviceUuid, reconnect, valuesSaved,
  }) {
    const sio = socket.init({
      cpvUuid, contentEncoding: encoding, language, deviceUuid, reconnect, valuesSaved,
    });

    sio.on('message', (message) => store.dispatch('handleSocketMessage', message));
  },
  handleSocketMessage(store, { data, type, meta }) {
    const messageData = meta.contentEncoding === 'deflate' ? socket.decompressMessage(data) : data;
    if (type && actionTypes[type]) {
      store.dispatch(actionTypes[type], messageData);
    } else {
      console.warn(`Message not handled ${type}`);
    }
  },
  setState({ commit }, data) {
    commit(types.SET_STATE, data);
    commit(types.SET_MATCHES, data.matches);
    commit(types.SET_SPORTS, data.sports);
    commit(types.SET_CATEGORIES, data.categories);
    commit(types.SET_TOURNAMENTS, data.tournaments);
    commit(types.SET_SPORTS_BETS, data.bets);
    commit(types.SET_BET_GROUPS, data.betGroups);
  },
  updateDisplayedSportsBets({ commit }, payload) {
    commit(types.UPDATE_SPORT_DISPLAYED_BETS, payload);
  },
  getTranslations({ commit }, { language, companyUuid }) {
    translations.get({ language, companyUuid }).then((response) => {
      commit(types.SET_TRANSLATIONS, response);
    });
  },
  serviceAlive({ getters, dispatch }) {
    // Reconnect if there is no message in 30s
    const timeoutId = getters.serverTimeoutId;
    const timeout = getters.serverServiceTimeout;
    dispatch('reconnect', { timeout, timeoutId });
  },
  reconnect({ dispatch, commit }, { timeout, timeoutId }) {
    clearTimeout(timeoutId);
    const timerId = setTimeout(() => {
      socket.disconnect();
      const data = {
        reconnect: true,
        valuesSaved: true,
      };
      dispatch('initSocket', data);
    }, timeout);

    commit(types.UPDATE_SERVER_TIMEOUT_ID, timerId);
  },
  updateConfig({ commit }, data) {
    commit(types.UPDATE_CONFIG, data);
  },
  updateBetslipConfig({ dispatch, getters }) {
    const {
      cpvUuid, company, language, betslipConfig,
    } = getters;

    dispatch('setBetslipConfig', {
      cpvUuid, companyUuid: company.uuid, language, rules: betslipConfig.rules,
    });
  },
  setActiveMatch({ state, dispatch }, idMatch) {
    if (state.selectedMatchId) dispatch('unsubscribeMatch');
    if (idMatch) dispatch('subscribeMatch', { requireState: true, matches: [idMatch] });
    if (state.activeView !== 'eventview') dispatch('setActiveView', 'eventview');
  },
  selectBetGroup({ commit }, id) {
    commit(types.SELECT_BET_GROUP, id);
  },
  setBetState({ commit }, data) {
    commit(types.SET_BET_STATE, data);
  },
  setMatchState({ commit }, data) {
    commit(types.SET_MATCH_STATE, data);
  },
  setCurrentMatches({ commit }, data) {
    commit(types.SET_CURRENT_MATCHES, data);
  },
  setScoreDetails({ commit }, data) {
    commit(types.SET_SCORE_DETAILS, data);
  },
  setOdds({ commit }, data) {
    commit(types.SET_ODDS, data);
  },
  setTopMatchBets({ commit }, data) {
    commit(types.SET_TOP_MATCH_BETS, data);
  },
  updateMatchBet({ commit }, data) {
    commit(types.UPDATE_MATCH_BET, data);
  },
  setCards({ commit }, data) {
    commit(types.SET_CARDS, data);
  },
  setScore({ commit }, data) {
    commit(types.SET_SCORE, data);
  },
  updateMatchBettingStatus({ commit }, data) {
    commit(types.UPDATE_MATCH_BETTING_STATUS, data);
  },
  addMatches({ commit }, data) {
    commit(types.ADD_MATCHES, data);
  },
  removeMatches({ commit }, data) {
    commit(types.REMOVE_MATCHES, data);
  },
  updateSport({ commit, getters, dispatch }, data) {
    if (data.idSport === getters.selectedSport && !data.sportActive) {
      dispatch('setActiveSport', 'all');
    }
    commit(types.UPDATE_SPORT, data);
  },
  setActiveSport({ commit }, id) {
    commit(types.SET_ACTIVE_SPORT, id);
  },
  updateFilteredMatches({ commit }, data) {
    commit(types.UPDATE_FILTERED_MATCHES, data);
  },
  updateMetaBet({ commit }, data) {
    commit(types.UPDATE_META_BET, data);
  },
  updateCategory({ commit }, data) {
    commit(types.UPDATE_CATEGORY, data);
  },
  updateTeam({ commit }, data) {
    commit(types.UPDATE_TEAM, data);
  },
  updateTournament({ commit }, data) {
    commit(types.UPDATE_TOURNAMENT, data);
  },
  updateMatch({ commit }, data) {
    commit(types.UPDATE_MATCH, data);
  },
  removeCategory({ commit }, data) {
    commit(types.REMOVE_CATEGORY, data);
  },
  removeTournament({ commit }, data) {
    commit(types.REMOVE_TOURNAMENT, data);
  },
  setActiveView({ commit }, data) {
    commit(types.SET_FOCUSED_MATCH, '');
    commit(types.SET_FOCUSED_MATCH_SPORT_ID, '');
    commit(types.SELECT_BETSLIP_BET, '');
    commit(types.SET_ACTIVE_VIEW, data);
  },
  setMatchNameSearchQuery({ commit }, payload) {
    commit(types.SET_MATCH_NAME_SEARCH_QUERY, payload);
  },
  setMatchBetSearchQuery({ commit }, payload) {
    commit(types.SET_MATCH_BET_SEARCH_QUERY, payload);
  },
  setBetMeta({ commit }, data) {
    commit(types.SET_BET_META, data);
  },
  setSelectedTab({ commit }, payload) {
    commit(types.SET_SELECTED_TAB, payload);
  },
  subscribeMatch({ commit }, payload) {
    commit(types.SET_ACTIVE_MATCH, _.first(payload.matches));
    socket.subscribeMatches(payload);
  },
  unsubscribeMatch({ commit, getters }) {
    const { selectedMatchId } = getters;
    socket.unsubscribeMatches([selectedMatchId]);
    commit(types.SET_ACTIVE_MATCH, '');
  },
  loadTickets({ commit, dispatch, getters }, data) {
    dispatch('resetTicketHistoryState');
    const { headersSettings, config, companyName } = getters;

    api.getCompanyAppSettings(companyName).then((response) => {
      const appConfig = response.appSettings?.config;
      commit('SET_APP_CONFIG', appConfig);

      const payload = {
        ...headersSettings,
        dcUrl: config?.dcUrlCloudflare || appConfig?.dcUrlCloudflare || 'gb',
        params: data,
      };

      api.loadLiveTickets(payload).then((tickets) => {
        commit(types.LOAD_TICKETS, tickets.data);
      }).catch(() => {
        dispatch('clearTicketList');
      });
    });
  },
  resetTicketHistoryState({ commit }) {
    commit(types.RESET_TICKET_HISTORY_STATE);
  },
  setLastTicketsLoading({ commit }, payload) {
    commit(types.SET_LAST_TICKETS_LOADING, payload);
  },
  clearTicketList({ commit, dispatch }) {
    commit(types.LOAD_TICKETS, []);
    dispatch('setActiveTicket', null);
  },
  setActiveTicket({ commit }, payload) {
    commit(types.SET_ACTIVE_TICKET, payload);
  },
  setAutoCheckerStatus({ commit }, payload) {
    commit(types.SET_AUTOCHECKER_STATUS, payload);
  },
  startAutoChecker({ dispatch, getters, commit }) {
    const { autoCheckerData, pendingTicketRequestUuid } = getters;
    const {
      count, repeat, interval, active,
    } = autoCheckerData;

    if (!active || (count >= repeat)) {
      commit('SET_TICKET_PROCESSING', false);
      commit('UPDATE_AUTOCHECKER_COUNT', 0);
      return;
    }

    commit('UPDATE_AUTOCHECKER_COUNT');

    autocheckerTimeout = setTimeout(() => {
      clearTimeout(autocheckerTimeout);

      if (!pendingTicketRequestUuid) return;

      dispatch('checkPendingTicket');
    }, interval);
  },
  clearAutoCheckerTimeout() {
    clearTimeout(autocheckerTimeout);
  },
  checkPendingTicket({ getters, dispatch, commit }) {
    const {
      config, pendingTicketRequestUuid, language, authorizationToken, company,
    } = getters;

    if (!pendingTicketRequestUuid) return;

    const settings = {
      dcUrl: config.dcUrlCloudflare || 'gb',
      language,
      company,
      authorizationToken,
    };

    api.ticketCheck({
      requestUuid: pendingTicketRequestUuid,
      settings,
    }).then((response) => {
      const { status } = response.data;

      if (status.value === 'PENDING') {
        dispatch('startAutoChecker');
        return;
      }

      dispatch('ticketUpdate', response.data);
      dispatch('setAutoCheckerStatus', false);
      commit('UPDATE_AUTOCHECKER_COUNT', 0);
    }).catch((e) => {
      const errorMessage = e ? errorHandler.getErrorMessage(e.data) : getters.translate('no_connection');
      dispatch('addNotification', { type: 'error', content: errorMessage });
      dispatch('startAutoChecker');
    });
  },
  addSevenNotification(_, { message, type, delay }) {
    gateway.sendMessage('Dialog.Show', {
      message,
      type,
      delay,
    }, true);
  },
  handleNotification({ dispatch, getters }, { message, type }) {
    if (!getters.appSnoozed) {
      dispatch('addNotification', {
        content: message,
        type,
      });
    } else {
      dispatch('addSevenNotification', {
        message,
        type,
        delay: 4000,
      });
    }
  },
  ticketUpdate({ dispatch, getters, commit }, data) {
    const { pendingTicketRequestUuid } = getters;
    const shouldPrintTicket = !!pendingTicketRequestUuid;

    const companyListToLog = [
      'da74132f-ca2d-4259-a289-574e28917890', // Hemingway 2
      '4f54c6aa-82a9-475d-bf0e-dc02ded89225', // BB
    ];

    const { betslipConfig, company } = getters;

    // temporary here.
    if (companyListToLog.indexOf(company?.uuid) > -1) {
      gateway.sendMessage('Logger.Log', {
        level: 'error',
        payload: [
          '[LIVE] - Ticket is received from backend',
          {
            appName: 'LiveBettigTerminalIframe',
            code: 'LIVE_TICKET_ACCEPTED',
            product_displayid: 'LiveBetting',
            request_id: data?.requestUuid,
            ticket_code: data?.id,
            should_print_ticket: shouldPrintTicket,
            ticket: data,
          },
        ],
      });
    }

    dispatch('ticketProcessing', false);
    if (data) {
      const { operatorLoggedIn } = getters;

      gateway.sendMessage('Tickets.Update', {
        action: data.status.value === 'PAYEDOUT' ? 'Payout' : 'Add',
        ticketData: formatter.formatTicketForPrint(data, betslipConfig),
      }, true);

      if ((data.action === 'payout'
        || (data.action === 'check' && data.status?.value === 'PAYEDOUT'))
        && operatorLoggedIn) {
        Object.assign(data, { translation: getters.translate('live_betting') });
        gateway.sendMessage('Peripherals.Print', {
          layoutName: 'ticketLiveBettingPayout',
          productId: 'LiveBetting',
          type: 'ticket',
          action: 'Payout',
          data,
        }, true);
      }

      commit('SET_PENDING_TICKET_REQUEST_UUID', '');
      switch (_.toUpper(data.status.value)) {
        case 'REJECTED':
          if (autocheckerTimeout) clearTimeout(autocheckerTimeout);

          if (data.alternativeStake) {
            dispatch('updateGlobalStake', data.alternativeStake);
          }
          break;
        case 'CLOSED':
          break;
        case 'PAYEDOUT':
          dispatch('setTicketCheck', { data: { ticket: data } });
          if (autocheckerTimeout) clearTimeout(autocheckerTimeout);

          break;
        default:
          dispatch('clearBetslip');

          /*
          This will be hidden since 7Terminal is displaying
          notification from our `Tickets.Update` message
          Investigate a way to display single notification

          dispatch('handleNotification', {
            message: getters.translate('ticket_accepted'),
            type: 'success',
          }); */

          gateway.sendMessage('Tickets.Resolved', {
            ticket: { payin: data.payin },
            accepted: true,
          });

          _.assign(data, { translation: getters.product.translation });

          if (printedTickets
            && printedTickets.length
            && printedTickets.indexOf(data?.requestUuid) > -1) {
            return;
          }

          if (printedTickets.length > 50) {
            printedTickets.splice(0, 20);
          }

          gateway.sendMessage('Peripherals.Print', {
            type: 'ticket',
            productId: 'LiveBetting',
            layoutName: 'ticketLiveBetting',
            action: '',
            data: formatter.formatTicketForPrint(data, betslipConfig),
          }, true);

          printedTickets.push(data?.requestUuid);

          // temporary here.
          if (companyListToLog.indexOf(company?.uuid) > -1) {
            gateway.sendMessage('Logger.Log', {
              level: 'error',
              payload: [
                '[Live] - Ticket sent to print',
                {
                  appName: 'LiveBettigTerminalIframe',
                  code: 'LIVE_TICKET_SENT_TO_PRINT',
                  product_displayid: 'LiveBetting',
                  request_id: data?.requestUuid,
                  ticket_code: data?.id,
                  ticket: data,
                },
              ],
            });
          }
      }
    }
  },
  updateGlobalStake({ commit, dispatch, getters }, stake) {
    const stakeValue = stake || getters.config?.stake || '1.00';
    const parsedStake = parseFloat(stakeValue);

    commit(types.UDPATE_GLOBAL_STAKE, parsedStake.toFixed(2));

    dispatch('updateBetslipRulesNotifications');
  },
  addNotification({ commit }, data) {
    commit('ADD_NOTIFICATION', data);
  },
  removeNotification({ commit }, id) {
    commit('REMOVE_NOTIFICATION', id);
  },
  addBetslipNotification({ commit }, data) {
    commit('ADD_BETSLIP_NOTIFICATION', data);
  },
  removeBetslipNotification({ commit }, id) {
    commit('REMOVE_BETSLIP_NOTIFICATION', id);
  },
  awakeApp({ commit, getters }) {
    if (!getters.appSnoozed) return;
    commit('AWAKE_APP');
  },
  checkWebCode({ commit, getters, dispatch }, webCode) {
    const { cpvUuid, language } = getters;
    const payload = {
      cpvUuid,
      locale: language,
      webCode,
    };
    api.checkCode(payload).then((response) => {
      commit('ADD_BETS_TO_BETSLIP', response);
      dispatch('selectTicketType', response.ticketType.id);
      dispatch('toggleBetslip', true);
    });
  },

  clearBetslip({ commit, getters, dispatch }) {
    const { stake, selectedTicketTypeId } = getters.config;
    commit(types.CLEAR_BETSLIP, { stake, selectedTicketTypeId });
    dispatch('resetBetBuilderDataOnClearBetslip');
    dispatch('toggleBetslip', false);
  },

  setDefaultState({ commit, dispatch, state }) {
    dispatch('clearBetslip');
    dispatch('updateOverviewScrollPosition', 0);
    dispatch('toggleLmt');
    commit(types.CLEAR_GENERAL_STATE_DATA);
    commit(types.SET_SPORTS_BETS, state.sportsBets);
  },

  getGeneratedTicket({ dispatch }, data) {
    api.getGeneratedTicket(data)
      .then((response) => {
        dispatch('toggleGeneratedTicket', { ticket: response, code: data.webCode });
      })
      .catch((error) => {
        const errorMessage = errorHandler.getErrorMessage(error.data);
        dispatch('addNotification', { id: 'get_generated_ticket_error', type: 'error', content: errorMessage });
      });
  },

  generateCode({ dispatch, getters }, data) {
    const { cpvUuid, language } = getters;

    api.generateCode(data)
      .then((response) => {
        dispatch('clearBetslip');
        dispatch('getGeneratedTicket', {
          webCode: response.code,
          cpvUuid,
          locale: language,
        });
      })
      .catch((error) => {
        const errorMessage = errorHandler.getErrorMessage(error.data);
        dispatch('addNotification', { id: 'generate_code_error', type: 'error', content: errorMessage });
      });
  },

  handleGenerateCode({ getters, dispatch }, bets) {
    const {
      cpvUuid, selectedTicketTypeId, ticketSystems, stake, language,
    } = getters;

    const stakeValue = parseFloat(stake);
    const requestUuid = uuidv4();

    const metadata = formatter.formatMetaDataForGenerateCode(requestUuid, cpvUuid, language);
    const ticket = formatter.formatTicketForGenerateCode(
      bets,
      ticketSystems,
      selectedTicketTypeId,
      stakeValue,
    );

    dispatch('generateCode', { metadata, ticket });
  },

  payin({ commit, dispatch, getters }, ticket) {
    const { requestUuid } = ticket;
    const { company } = getters;

    commit('SET_PENDING_TICKET_REQUEST_UUID', requestUuid);
    dispatch('clearAutoCheckerTimeout');

    const companyListToLog = [
      '4f54c6aa-82a9-475d-bf0e-dc02ded89225', // BB
    ];
    gateway.emitAsync('Tickets.Pay', {
      ticket,
      requestUuid,
    }).then((data) => {
      // temporary here.
      if (companyListToLog.indexOf(company?.uuid) > -1) {
        gateway.sendMessage('Logger.Log', {
          level: 'error',
          payload: [
            '[LIVE] - Ticket is resolved from Seven',
            {
              appName: 'LiveBettigTerminalIframe',
              code: 'LIVE_TICKET_RESOLVED',
              product_displayid: 'LiveBetting',
              request_id: requestUuid,
              ticket_code: ticket?.id,
              payin_data: data,
            },
          ],
        });
      }

      dispatch('setAutoCheckerStatus', true);
      dispatch('startAutoChecker');
    })
      .catch((err) => {
        // temporary here.
        if (companyListToLog.indexOf(company?.uuid) > -1) {
          gateway.sendMessage('Logger.Log', {
            level: 'error',
            payload: [
              '[LIVE] - Ticket payin Error from Seven',
              {
                appName: 'LiveBettigTerminalIframe',
                code: 'LIVE_TICKET_ERROR_CATCH',
                product_displayid: 'LiveBetting',
                request_id: requestUuid,
                ticket_code: ticket?.id,
                error_data: err,
              },
            ],
          });
        }
        dispatch('ticketProcessing', false);

        /*

        Currently, notifications are handled in 7Terminal app

        if (!err) return;

        if (!_.isArray(err.message)) {
          dispatch('addNotification', { id: 'payin_error', type: 'error', content: err.message });
          return;
        }

        _.each(err.message, (message, i) => {
          const timeoutExtraTime = i * 1500;
          const timeout = 5000 + timeoutExtraTime;

          dispatch('addNotification', {
            id: `payin_error_${i + 1}`, type: 'error', content: message, timeout,
          });
        });
        */
      });
  },
  handleTicketPayin({ getters, dispatch }, { bets, winnings }) {
    const {
      betslipConfig,
      selectedTicketTypeId,
      ticketSystems,
      translate,
      stake,
    } = getters;

    const stakeValue = parseFloat(stake);

    const foundBetBuilderBet = _.find(bets, 'isBetBuilderBet');
    const betBuilderCalculation = foundBetBuilderBet ? getters.betBuilderEventCalculation : null;

    if (foundBetBuilderBet && bets.length < getters.betBuilderBetslipRulesValues.minBetCount) {
      dispatch('updateBetslipGeneralNotifications', {
        id: 'betBuilderErrorMinBetCount',
        content: translate('bet_builder_error_min_bet_count'),
        active: true,
        type: 'error',
        dismissable: false,
      });
      return;
    }

    const ticket = formatter.formatTicketForPayin(
      bets,
      ticketSystems,
      selectedTicketTypeId,
      stakeValue,
      betslipConfig?.taxes,
      winnings?.bonus,
      betBuilderCalculation,
    );

    dispatch('ticketProcessing', true);

    dispatch('payin', ticket);
  },
  selectTicketType({ commit, state, dispatch }, id) {
    if (state.selectedTicketTypeId === id) return;

    commit(types.SELECT_TICKET_TYPE, id);
    commit(types.SELECT_BETSLIP_BET, '');

    if (id !== 3) {
      const uniqueBets = _.uniqBy(state.ticketBets, 'idMatch');
      if (uniqueBets.length < state.ticketBets.length) commit('SET_MATCH_UNIQUE_BETS', uniqueBets);
    }

    if (state.selectedTicketTypeId === 2) {
      dispatch('updateTicketSystems');
    } else {
      dispatch('updateBetslipGeneralNotifications', { id: 'selectedSystems' });
    }

    dispatch('updateBetslipRulesNotifications');

    gateway.sendMessage('Tickets.Changed', {
      ticket: {
        betCount: state.ticketBets.length,
      },
    });
  },
  updateBankers({ commit, dispatch }, idMbo) {
    commit(types.SELECT_BANKER, idMbo);
    dispatch('updateTicketSystems');
  },
  clearBetslipRulesNotifications({ commit }) {
    commit(types.CLEAR_BETSLIP_RULES_NOTIFICATIONS);
  },
  updateBetslipRulesNotifications({ commit, getters, dispatch }, maxWinUpdate = false) {
    dispatch('clearBetslipRulesNotifications');

    if (maxWinUpdate) commit(types.UPDATE_WINNINGS_NOTIFICATIONS, maxWinUpdate);

    if (getters.selectedTicketTypeId === 2) commit(types.UPDATE_SYSTEM_NOTIFICATIONS);

    commit(types.UPDATE_GENERAL_BETSLIP_RULES_NOTIFICATIONS);
  },
  updateBetslipGeneralNotifications({ commit }, notificationPayload) {
    commit(types.UPDATE_BETSLIP_GENERAL_NOTIFICATIONS, notificationPayload);
  },
  updateTicketSystems({ commit, getters, dispatch }) {
    const {
      nonBankers, selectedTicketTypeId, translate,
    } = getters;

    commit(types.UPDATE_TICKET_SYSTEMS, nonBankers.length);

    dispatch('updateBetslipRulesNotifications');

    dispatch('updateBetslipGeneralNotifications', {
      id: 'selectedSystems',
      content: translate('select_system_type'),
      active: !getters.selectedTicketSystems.length && selectedTicketTypeId === 2,
      type: 'warning',
      dismissable: false,
    });
  },
  removeBet({ commit, dispatch, getters }, data) {
    commit(types.REMOVE_BET, data);

    dispatch('updateTicketSystems');

    if (_.isEmpty(getters.ticketBets)) dispatch('clearBetslip');
  },
  saveSystemCombinations({ commit, dispatch, getters }, data) {
    commit(types.SAVE_SYSTEM_COMBINATIONS, data);

    dispatch('updateBetslipGeneralNotifications', {
      id: 'selectedSystems',
      content: getters.translate('select_system_type'),
      active: !getters.selectedTicketSystems.length,
      type: 'warning',
      dismissable: false,
    });
  },
  selectBet({ state, dispatch, commit }, data) {
    if (state.ticketProcessing || state.workingTimeBlocker) return;

    const { bet, addedToTicket } = data;

    if (addedToTicket) {
      commit(types.UNSELECT_BET, bet);
    } else {
      if (validateBetAdd(state.betslipRulesNotifications, bet.isBetBuilderBet)) return;

      commit(types.SELECT_BET, bet);
    }

    if (state.selectedTicketTypeId === 2) dispatch('updateTicketSystems');

    dispatch('updateBetslipGeneralNotifications', {
      id: 'betBuilderErrorMinBetCount',
      active: false,
    });
    dispatch('updateBetslipRulesNotifications');
    dispatch('updateBetslipStatus', false);
  },
  setAppSettings({ commit }, { uuid, companyName }) {
    const company = companyName || companyUuids.getCompanyByUuid(uuid);

    commit('SET_LOCAL_APP_CONFIG', company);

    return api.getCompanyAppSettings(company).then((response) => {
      const { config } = response.appSettings;
      commit('SET_APP_CONFIG', config);
      commit('SET_DEFAULT_STAKE', config?.stake);
      if (config?.matchesOrder) commit('SET_MATCHES_ORDER', config.matchesOrder);
    });
  },
  setCompanyName({ commit }, { name, uuid }) {
    const company = companyUuids.getCompanyByUuid(uuid);
    commit('SET_COMPANY_NAME', name || company);
  },
  selectBetslipBet({ commit }, data) {
    commit(types.SELECT_BETSLIP_BET, data);
  },
  setCpvUuid({ commit }, value) {
    commit(types.SET_CPV_UUID, value);
  },
  setAuthorizationToken({ commit }, data) {
    commit(types.SET_AUTHORIZATION_TOKEN, data);
  },
  setBetshop({ commit }, data) {
    commit(types.SET_BETSHOP, data);
  },
  setBetslipConfig({ commit, dispatch }, {
    cpvUuid, companyUuid, language, rules,
  }) {
    api.getLiveConfiguration(cpvUuid, language).then(({ config }) => {
      commit(types.SET_BETSLIP_CONFIG, config);

      dispatch('setBonusConfig', {
        cpvUuid,
        url: config.bonusConfigUrl,
        companyUuid,
      });
    }).catch(() => {
      commit(types.SET_BETSLIP_CONFIG, { taxes: [], rules });
    });
  },
  setBonusConfig({ commit }, { cpvUuid, url, companyUuid }) {
    api.getBonusConfiguration(cpvUuid, url, companyUuid)
      .then((response) => {
        commit(types.SET_BONUS_CONFIG, response);
      }).catch(() => {
        console.log('error fetching bonus config');
      });
  },
  setCompany({ commit }, data) {
    commit(types.SET_COMPANY, data);
  },
  setDefaultStake({ commit, getters }) {
    const { stake } = getters.config;
    commit(types.SET_DEFAULT_STAKE, stake);
  },
  setDevice({ commit }, data) {
    commit(types.SET_DEVICE, data);
  },
  setFocusedMatch({ commit, getters }, { idMatch = '', animationPrevented = true }) {
    if (getters.focusedMatch.idMatch === idMatch) return;
    commit(types.SET_FOCUSED_MATCH, {
      idMatch,
      animationPrevented,
    });
  },
  setFocusedMatchSportId({ commit }, { idSport = '', animationPrevented = true }) {
    commit(types.SET_FOCUSED_MATCH_SPORT_ID, {
      idSport,
      animationPrevented,
    });
  },
  setOddFormatterStatus({ commit }, data) {
    commit(types.SET_ODD_FORMATTER_STATUS, data);
  },
  setOddType({ commit }, data) {
    const formatterActive = data !== 1;

    commit(types.SET_ODD_FORMATTER_STATUS, formatterActive);
    commit(types.SET_ODD_TYPE, data);
  },
  setMoneyDenominations({ commit }, data) {
    commit(types.SET_MONEY_DENOMINATIONS, data);
  },
  setProduct({ commit }, data) {
    commit(types.SET_PRODUCT, data);
  },
  setSevenTaxes({ commit }, data) {
    commit(types.SET_SEVEN_TAXES, data);
  },
  setHeadersSettings({ commit }, data) {
    commit(types.SET_HEADERS_SETTINGS, data);
  },
  setTerminal({ commit }, data) {
    commit(types.SET_TERMINAL, data);
  },
  setLanguage({ commit }, data) {
    commit(types.SET_LANGUAGE, data);
  },
  setWorkingTimeBlocker({ commit, dispatch }, data) {
    const workingTime = _.find(data, { id: 'WorkingTime' })?.message || '';
    commit(types.SET_WORKING_TIME_BLOCKER, workingTime);
    dispatch('clearBetslip');
  },
  removeWorkingTimeBlocker({ commit }, data) {
    const workingTime = _.some(data, { id: 'WorkingTime' });
    if (workingTime) {
      commit(types.SET_WORKING_TIME_BLOCKER, '');
    }
  },
  ticketProcessing({ commit }, load) {
    commit(types.SET_TICKET_PROCESSING, load);
  },
  toggleBetslip({ commit }, data = undefined) {
    commit(types.TOGGLE_BETSLIP, data);
  },
  toggleGeneratedTicket({ commit }, payload) {
    commit(types.TOGGLE_GENERATED_TICKET, payload);
  },
  toggleOnlineStatus({ commit }, payload) {
    commit(types.TOGGLE_CONNECTION_STATUS, payload);
  },
  updateBetslipStatus({ commit }, status) {
    commit(types.UPDATE_BETSLIP_STATUS, status);
  },
  updateOverviewScrollPosition({ commit }, payload) {
    commit(types.OVERVIEW_SCROLL_UPDATE, payload);
  },
  handleSIRConnect({ commit, getters }) {
    const { language } = getters;
    const {
      clientId,
      liveMatchTracker,
      betAssist,
    } = getters.config?.srWidgets || {};

    if (!clientId) return;
    if (liveMatchTracker?.enabled || betAssist?.enabled) {
      commit(types.SET_LMT, liveMatchTracker);
      sirConnect(clientId, language);
    }
  },
  updateBetAssistPopupId({ commit }, data = '') {
    commit(types.UPDATE_BET_ASSIST_POPUP_ID, data);
  },
  printGeneratedCode(_, code) {
    gateway.sendMessage('Peripherals.Print', {
      productId: 'LiveBetting',
      layoutName: 'codeLiveBetting',
      action: '',
      data: {
        code,
      },
    }, true);
  },
  handleUIShowEvent(_, data) {
    const showTicketCheck = data?.data?.name?.find((value) => value === 'TicketCheck');
    const showBettingArea = data?.data?.name?.find((value) => value === 'BettingArea');

    if (showBettingArea) {
      router.push('/');
      return;
    }

    if (showTicketCheck) {
      router.push('/ticket-preview');
    }
  },
  setTicketCheck({ commit }, data) {
    const { ticket } = data?.data || {};

    commit('SET_CHECKED_TICKET', ticket);
  },
  payoutTicket({ commit, dispatch }, ticket) {
    commit('SET_PENDING_TICKET_REQUEST_UUID', ticket.requestUuid);

    gateway.emitAsync('Tickets.Payout', {
      ticket: { ...ticket },
    }).then((response) => {
      if (response.ticket.localStatus !== 'PAYEDOUT') {
        dispatch('setAutoCheckerStatus', true);
        dispatch('startAutoChecker');
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  setBalance({ commit, getters }, data) {
    const terminal = { ...getters.terminal, balance: data?.data?.balance || 0 };

    commit('SET_TERMINAL', terminal);
  },
  setIsContentScrolled({ commit }, data) {
    commit(types.SET_IS_CONTENT_SCROLLED, data);
  },
  initBetBuilderSocket({ dispatch, getters }, { companyUuid, feedId, language }) {
    if (!getters.config?.betBuilderEnabled) return;

    betBuilderSocket.init({
      companyUuid,
      feedId,
      language,
      dispatch,
    });
  },
  setBetBuilderBetslipRules({ commit, getters }) {
    if (!getters.config?.betBuilderEnabled) return;
    commit(types.SET_BET_BUILDER_BETSLIP_RULES);
  },
  setBetBuilderEventSelection({ commit }, data) {
    commit(types.SET_BET_BUILDER_EVENT_SELECTION, data);
  },
  resetBetBuilderSelection({ commit }) {
    commit(types.RESET_BET_BUILDER_AVAILABLE_SELECTIONS);
    commit(types.SET_BET_BUILDER_EVENT_CALCULATION, {});
  },
  updateBetBuilderEventSelection({ commit, getters }, data) {
    const { betBuilderEventSelection } = getters;

    if (data.markets.length) betBuilderEventSelection[data.id] = data;

    if (betBuilderEventSelection[data.id]
      && !data.markets.length) delete betBuilderEventSelection[data.id];

    commit(types.SET_BET_BUILDER_EVENT_SELECTION, betBuilderEventSelection);
  },
  unsubscribeBetBuilderEventCalculation({ dispatch, getters }) {
    if (!getters.config.betBuilderEnabled) return;

    const { id } = getters.betBuilderEventCalculation;
    if (id) betBuilderSocket.unsubscribeCalculation(id);

    dispatch('updateBetslipGeneralNotifications', {
      id: 'betBuilderCalculationError',
      active: false,
    });
  },
  subscribeBetBuilderEventCalculation({
    commit,
    dispatch,
    getters,
    state,
  }, { eventId, outcomeId }) {
    if (!getters.config.betBuilderEnabled) return;

    const allBetBuilderOutcomes = state.betBuilderSelectedOutcomes || [];

    const outcomeIndex = allBetBuilderOutcomes.indexOf(outcomeId);
    if (outcomeIndex > -1) {
      allBetBuilderOutcomes.splice(outcomeIndex, 1);
    } else {
      if (allBetBuilderOutcomes.length >= getters.betBuilderBetslipRulesValues.maxBetCount) return;
      allBetBuilderOutcomes.push(outcomeId);
    }

    if (!allBetBuilderOutcomes.length) {
      betBuilderSocket.unsubscribeCalculation(`${eventId}-betbuilder`);
      commit(types.RESET_BET_BUILDER_AVAILABLE_SELECTIONS);
      dispatch('updateBetslipGeneralNotifications', {
        id: 'betBuilderCalculationError',
        active: false,
      });
      return;
    }

    betBuilderSocket.subscribeCalculation({
      id: `${eventId}-betbuilder`,
      eventId: Number(eventId),
      outcomes: allBetBuilderOutcomes,
    });
    commit(types.SET_BET_BUILDER_SELECTED_OUTCOMES, allBetBuilderOutcomes);
  },
  setBetBuilderEventCalculation({ commit, dispatch }, data) {
    const calculationError = {
      id: 'betBuilderCalculationError',
      content: data.errorMessage,
      active: data.errorCode || !data.odd || !data.available,
      type: 'error',
      dismissable: false,
    };

    dispatch('updateBetslipGeneralNotifications', calculationError);

    commit(types.SET_BET_BUILDER_EVENT_CALCULATION, data);
    commit(types.SET_BET_BUILDER_AVAILABLE_SELECTIONS, data.selections || []);
  },
  setBetBuilderSelectedOutcomes({ commit }, data) {
    commit(types.SET_BET_BUILDER_SELECTED_OUTCOMES, data);
  },
  resetBetBuilderDataOnClearBetslip({ dispatch }) {
    dispatch('unsubscribeBetBuilderEventCalculation');
    dispatch('resetBetBuilderSelection');
    dispatch('setBetBuilderSelectedOutcomes', []);
  },
  setUserAuthorization({ commit }, data) {
    commit(types.SET_USER_AUTHORIZATION, data.userType === 'OPERATOR');
  },
  setliveMatchTrackerError({ commit }, data) {
    commit(types.SET_LMT_HAS_ERROR, data);
  },
  toggleLmt({ commit }, data = true) {
    commit(types.SET_LMT_ACTIVE, data);
  },
};
