<template>
  <div class="betslip scrollbar">
    <BetslipHeader :bets-count="orderedBets.length" />

    <div
      v-if="orderedBets.length && !workingTimeBlocker"
      class="betslip-body"
    >
      <div
        v-if="!betBuilderBet"
        class="betslip-bets"
      >
        <Loader v-if="ticketProcessing"/>

        <BetslipBet
          v-for="(bet, i) in orderedBets"
          :key="i"
          :bet="bet"
          :single-stake="singleStake"
        />
      </div>

      <div
        v-else
        class="betslip-bet-builder-bets"
      >
        <Loader v-if="ticketProcessing"/>

        <BetslipBetBuilderBet
          v-for="(bet, index) in orderedBets"
          :key="bet.idMbo"
          :bet="bet"
          :index="index"
        />
      </div>

      <Notification
        v-for="item in betslipNotifications"
        :key="item"
        size="small"
        :id="item.id"
        :type="item.type"
        :content="item.content"
        :timeout="item.timeout"
        :dismissable="item.dismissable"
        @remove-notification="removeBetslipNotification"
      />

      <Notification
        v-if="balanceNotification.active"
        size="small"
        :id="balanceNotification.id"
        :type="balanceNotification.type"
        :content="balanceNotification.content"
        :timeout="balanceNotification.timeout"
        :dismissable="balanceNotification.dismissable"
        @remove-notification="removeBetslipNotification"
      />

      <div
        v-if="selectedTicketType.system"
        class="betslip-combinations"
      >
        <div
          class="betslip-combinations-button"
          :class="{'disabled': !nonBankersNumber || ticketProcessing}"
          @click="toggleCombinations"
        >
          <i class="icon icon-edit" />
          {{ $translate.key('set_combinations') }}
        </div>

        <teleport to="#modals">
          <Modal
            v-if="combinationsModalActive"
            @close-modal="toggleCombinations"
          >
            <BetslipCombinations
              :winnings="winnings"
              :systems="ticketSystems"
              :bankers-number="bankersNumber"
              :total-combinations="totalCombinations"
              @close-betslip-modal="toggleCombinations"
            />
          </Modal>
        </teleport>
      </div>

      <BetslipMeta
        :stake="stake"
        :winnings="winnings"
        :payin-tax="payinTax"
        :payout-tax="payoutTax"
        :total-odds="totalOdds"
        :total-combinations="totalCombinations"
        :selected-ticket-type="selectedTicketType"
      />
    </div>

    <div
      class="betslip-empty"
      v-if="!orderedBets.length || workingTimeBlocker"
    >
      <div class="betslip-empty-image"></div>

      <div
        class="betslip-empty-placeholder-text"
        :class="{ 'betslip-working-time-blocker': workingTimeBlocker }">
        {{ workingTimeBlocker || $translate.key('empty_betslip_message') }}
      </div>
    </div>
    <ScrollButtons
      v-if="scrollButtonsEnabled"
      :key="orderedBets"
      class="betslip-scroll-buttons"
      scroll-content="betslip" />
  </div>
</template>

<script>
import { ref } from 'vue';
import useBetslip from '@/composables/betslip';
import useNotifications from '@/composables/notifications';
import useTicketCalculations from '@/composables/ticketCalculations';

import BetslipBet from './BetslipBet';
import BetslipMeta from './BetslipMeta';
import BetslipHeader from './BetslipHeader';
import BetslipBetBuilderBet from './BetslipBetBuilderBet';
import BetslipCombinations from './BetslipCombinations';
import Modal from '../common/Modal';
import Loader from '@/components/common/Loader';
import Notification from '../common/Notification';
import ScrollButtons from '@/components/common/ScrollButtons';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  name: 'AppBetslip',
  components: {
    BetslipBet,
    BetslipMeta,
    BetslipHeader,
    BetslipBetBuilderBet,
    BetslipCombinations,
    Notification,
    Loader,
    Modal,
    ScrollButtons,
  },
  setup() {
    const combinationsModalActive = ref(false);

    const {
      stake,
      betBuilderBet,
      orderedBets,
      bankersNumber,
      ticketSystems,
      nonBankersNumber,
      ticketProcessing,
      totalCombinations,
      selectedTicketType,
      workingTimeBlocker,
    } = useBetslip();

    const {
      winnings,
      payinTax,
      payoutTax,
      totalOdds,
      singleStake,
    } = useTicketCalculations();

    const {
      betslipNotifications, balanceNotification, removeBetslipNotification,
    } = useNotifications();

    const { scrollButtonsEnabled } = useScrollButtons();

    const toggleCombinations = () => {
      if (!nonBankersNumber.value || ticketProcessing.value) return;
      combinationsModalActive.value = !combinationsModalActive.value;
    };

    return {
      stake,
      winnings,
      payinTax,
      betBuilderBet,
      payoutTax,
      totalOdds,
      orderedBets,
      singleStake,
      bankersNumber,
      ticketSystems,
      nonBankersNumber,
      ticketProcessing,
      totalCombinations,
      selectedTicketType,
      balanceNotification,
      betslipNotifications,
      combinationsModalActive,
      removeBetslipNotification,
      toggleCombinations,
      scrollButtonsEnabled,
      workingTimeBlocker,
    };
  },
};
</script>
