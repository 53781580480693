import { getComboBonus, getTotalOdds, getStakeAfterTax } from './helper';

const calculateComboWinnings = ({
  betslipConfig, stake, bets, bonusConfig, betBuilderOdd,
}) => {
  const taxPolicy = betslipConfig?.taxes?.payin?.policy;
  const formattedStake = getStakeAfterTax({ stake, taxPolicy });
  const activeBonus = getComboBonus({ bonusConfig, bets });

  const max = formattedStake * getTotalOdds(bets, betBuilderOdd);

  let bonus = 0;

  if (activeBonus.bonusPercent) {
    bonus = (max * (1 + activeBonus.bonusPercent / 100)) - max;
  }

  return {
    max: max + bonus,
    bonus,
    bonusPercent: activeBonus.bonusPercent ?? 0,
  };
};

export default {
  calculateComboWinnings,
};
