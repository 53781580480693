import Gateway from '@nsftx/seven-gravity-gateway';

let gateway;
let storeDispatch;

const setListeners = () => {
  gateway.on('AuthorizationChanged', () => {});
  gateway.on('Tickets.FetchHistory', ({ data }) => storeDispatch('loadTickets', data));
  gateway.on('UI.Show', (data) => console.log(data));
};

export default {
  instance({ dispatch }) {
    storeDispatch = dispatch;
  },
  init() {
    const gatewayConstructor = Gateway.slave;

    gateway = gatewayConstructor({
      debug: true,
      allowedOrigins: '*',
      slaveId: 'LiveBettingTicketHistory',
      data: {
        title: 'Ticket history live terminal',
      },
      eventPropagation: {
        click: true,
        keydown: '*',
      },
      load: ({ data }) => {
        gateway.sendMessage({
          action: 'Slave.Loaded',
          data: {},
        });

        const {
          company, device, betshop, product, user, config,
        } = data;

        const headersSettings = {
          companyId: company.id,
          companyUuid: company.uuid,
          betshopId: betshop.id,
          deviceUuid: device.deviceUuid,
          cpvUuid: product.cpvUuid,
          token: user.auth.token,
        };

        storeDispatch('getTranslations', { language: company.locale, companyUuid: company.uuid });
        storeDispatch('setHeadersSettings', headersSettings);
        storeDispatch('setSevenTaxes', config.taxes);
        storeDispatch('setProduct', product);
        storeDispatch('setCompanyName', { uuid: company.uuid });

        setListeners();
      },
    });
  },
  sendMessage(action, data, enforceEvent) {
    const gatewayDataConstruct = {
      action,
      data,
      enforceEvent,
    };

    if (gateway) gateway.sendMessage(gatewayDataConstruct);
  },
};
