<template>
  <div class="sidebar">
    <div class="sidebar-items">
      <OverviewSidebarRow
        :label="$translate.key('general_all')"
        :value="allMatchesCount"
        :selected="selectedSport === 'all'"
        id="all"
        icon-class="icon-specials"
      />

      <OverviewSidebarRow
        v-for="sport in formattedSportsWithMatches"
        :key="sport.id"
        :label="sport.name"
        :value="sport[activeMatchesCount]"
        :id="sport.id"
        :selected="selectedSport === sport.id"
        :icon-class="`icon-sport-${sport.id}`"
      />
    </div>
  </div>
</template>

<script>
import OverviewSidebarRow from './OverviewSidebarRow';
import useMatches from '@/composables/matches';

export default {
  name: 'OverviewSidebar',
  components: {
    OverviewSidebarRow,
  },
  setup() {
    const {
      allMatchesCount, formattedSportsWithMatches, selectedSport, activeMatchesCount,
    } = useMatches();

    return {
      allMatchesCount,
      formattedSportsWithMatches,
      selectedSport,
      activeMatchesCount,
    };
  },
};
</script>
