<template>
  <div class="eventview-header-match-results">
    <div class="match-results-header">
      <div
        class="result-label"
        v-for="(label, index) in result.labels"
        :key="index">
        <i
          v-if="typeof label === 'object'"
          :class="['icon', label.class]"/>
        <div v-else>
          {{ label }}
        </div>
      </div>
    </div>
    <div class="match-results-body">
      <div
        :class="['result-value', sportName]"
        v-for="(result, index) in result.scores"
        :key="index">
        <div>{{ result.team1 }}</div>
        <div>{{ result.team2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EventviewHeaderMatchResults',
  props: {
    result: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    sportName: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>
