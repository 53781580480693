import { ref, computed } from 'vue';
import Layouts from '@/utils/keyboardLayout';
import {
  keyboard,
  formatKeyboardRow,
  showKeyboard,
  hideKeyboard,
  getCaret,
  backspace,
  insertChar,
  validateInput,
  setSelection,
} from '@/services/keyboardHandler';

export default function useVirtualKeyboard() {
  const previewInputValue = ref();

  const keySet = computed(() => {
    const layout = Layouts[keyboard.value.layout || 'compact'];
    const result = [];

    layout[keyboard.value.keySet || 'default'].forEach((el) => {
      result.push(formatKeyboardRow(el.split(' '), layout.meta));
    });
    return result;
  });

  /**
   *
   * @param {String} value
   */

  const setValueToInput = (value) => {
    if (!value) return;
    keyboard.value.input.value = value;
  };

  /**
 *
 * @param {String} control
 * @param {String} char
 */
  const clickKey = ({ control, char }) => {
    const { input, layout } = keyboard.value;
    let text = input.value;
    const caret = getCaret(input);

    if (control === 'capsed' || control === 'default') {
      keyboard.value.keySet = keyboard.value.keySet === control ? 'default' : control;
    }

    if (control === 'backspace') {
      text = backspace(caret, text);
      caret.end = caret.start;
    }

    if (control === 'accept') {
      hideKeyboard();
    }

    if (control === 'clear') {
      text = '';
    }

    if (char && !control) {
      text = insertChar(caret, text, char);
      caret.start += char.length;
      caret.end = caret.start;
    }

    keyboard.value.input.value = validateInput(layout, text);
    keyboard.value.input.dispatchEvent(new Event('input', { bubbles: true }));
    setSelection(caret);
  };

  return {
    showKeyboard,
    hideKeyboard,
    previewInputValue,
    keyboard,
    keySet,
    clickKey,
    setValueToInput,
  };
}
