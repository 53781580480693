/* eslint-disable */
export const rulesConstants = {
  MAX_BET_BUILDER_BET_COUNT: 999,
  MAX_BET_COUNT: 12,
  MIN_TICKET_PAYIN: 8,
  MAX_TICKET_PAYIN: 10,
  MIN_TICKET_PAYIN_SINGLE: 9,
  MAX_TICKET_PAYIN_PER_BET: 11,
  MIN_TICKET_PAYIN_SYSTEM_PER_COMBINATION: 9,
  MAX_WINNING: 14,
  MAX_WINNING_SINGLE: 22,
  MAX_NUMBER_OF_BANKERS: 20,
  MAX_NUMBER_OF_NON_BANKERS: 21,
};
