import axios from 'axios';
import routes from './env';

export default {
  getBonusConfiguration(cpvUuid, url, companyUuid) {
    return axios({
      url,
      method: 'get',
      params: {
        cpvUuid,
        playerUuid: companyUuid,
      },
    })
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.toJSON()));
  },
  getLiveConfiguration(cpvUuid, locale) {
    return axios({
      url: `${routes.sportsbookPublicApi}/clientConfiguration.php/configuration`,
      method: 'get',
      params: {
        cpvUuid,
        locale,
        deliveryChannel: 'Terminal',
      },
    })
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.toJSON()));
  },
  getCompanyAppSettings(companyName = '') {
    const options = {
      method: 'get',
      params: {
        templateMerge: true,
      },
      url: `${routes.gravityApi}/v1/apps/${companyName}_live_terminal_iframe_app/settings`,
    };

    return axios(options)
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.toJSON()));
  },
  loadLiveTickets({
    companyUuid,
    deviceUuid,
    betshopId,
    companyId,
    cpvUuid,
    token,
    dcUrl,
    params,
  }) {
    const ticketsApi = _.replace(routes.liveTickets, '{dcUrlFragment}', dcUrl);

    return axios({
      url: `${ticketsApi}/app.php/terminal-admin/device-tickets/${cpvUuid}.json`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'HTTP-X-NAB-BETSHOP-ID': betshopId,
        'HTTP-X-NAB-COMPANY-ID': companyId,
        'HTTP-X-NAB-DEVICE-UUID': deviceUuid,
        'HTTP-X-NAB-DP': 'Terminal',
        'X-NSFT-SEVEN-COMPANY-UUID': companyUuid,
      },
      params: {
        count: params.count,
        deviceUuid,
        timeFrom: params.timeFrom,
        timeTo: params.timeTo,
      },
    })
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.toJSON()));
  },
  checkCode({ cpvUuid, locale, webCode }) {
    return axios({
      url: `${routes.sportsbookPublicApi}/ticketCode.php/live/getTicketCode`,
      method: 'get',
      params: {
        cpvUuid,
        locale,
        webCode,
      },
    })
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.toJSON()));
  },
  generateCode({ ticket, metadata }) {
    const options = {
      url: `${routes.sportsbookPublicApi}/ticketCode.php/live/ticketCode`,
      method: 'POST',
      data: {
        metadata,
        ticket,
      },
    };
    return axios(options)
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.response));
  },
  getGeneratedTicket({ webCode, cpvUuid, locale }) {
    const options = {
      url: `${routes.sportsbookPublicApi}/ticketCode.php/live/getTicketCode`,
      method: 'get',
      params: {
        webCode,
        cpvUuid,
        locale,
      },
    };

    return axios(options)
      .then((response) => response.data)
      .catch((err) => Promise.reject(err.response));
  },

  ticketCheck({ requestUuid, settings }) {
    const liveTicketsApi = _.replace(routes.liveTickets, '{dcUrlFragment}', settings.dcUrl);

    const options = {
      method: 'GET',
      url: `${liveTicketsApi}/api/terminal/tickets/request/${requestUuid}/product/LiveBetting/check.json`,
      timeout: 15000,
      headers: {
        Authorization: `Basic ${settings.authorizationToken}`,
        'HTTP-X-SEVEN-CLUB-UUID': settings.company.uuid,
        'HTTP-X-NAB-DP': 'Terminal',
        'SEVEN-LOCALE': settings.language,
      },
    };

    return axios(options)
      .then((response) => response)
      .catch((err) => Promise.reject(err.response));
  },
  getOnlineStatus() {
    const options = {
      method: 'get',
      url: `${routes.smGateway}/online-status`,
    };

    return axios(options)
      .then((response) => response)
      .catch((err) => Promise.reject(err.response));
  },
  getLmtBannersCmsUrl(companyName) {
    return `${routes.cms}/${companyName}/live_lmt_banner_images`;
  },
};
