<template>
  <teleport to="#modals">
    <Modal @close-modal="$emit('closeSelectionModal')">
      <div class="selection-modal">
        <div class="selection-modal-header">
          <span class="selection-modal-header-title">
            {{ $translate.key(headerTitle) }}
          </span>
          <i
            id="close-selection-modal"
            class="icon icon-close"
            @click="$emit('closeSelectionModal')" />
        </div>
        <div class="selection-modal-body scrollbar">
          <div
            id="select-option"
            class="selection-modal-body-meta"
            v-for="(option, i) in options"
            :key="i"
            @click.stop.prevent="$emit('selectOption', option)">
            <slot
              name="option"
              :option="option">
            </slot>
          </div>
        </div>
      </div>
    </Modal>
  </teleport>
</template>

<script>
import Modal from './Modal';

export default ({
  name: 'SelectionModal',
  components: {
    Modal,
  },
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  emits: ['closeSelectionModal', 'selectOption'],
});
</script>
