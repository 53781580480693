const clickAway = {
  beforeMount(element, binding) {
    const el = element;
    el.clickOutsideEvent = ({ target }) => {
      if (!(el === target || el.contains(target))) binding.value();
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export default clickAway;
