import { createApp } from 'vue';
import store from './store';
import router from './router';
import App from './App';
import formatter from '@/services/formatter';
import sentry from '@/services/sentry';
import { showKeyboard, hideKeyboard } from '@/services/keyboardHandler';

const app = createApp(App);

sentry.init({ app, router });

app.use(store);
app.use(router);
app.config.globalProperties.$translate = {
  key(value) {
    return store.getters.translate(value);
  },
};

app.config.globalProperties.$keyboard = {
  show(e, options = {}) { showKeyboard(e, options); },
  hide() { hideKeyboard(); },
};

app.config.globalProperties.$formatOdd = (odd, type, formatterActive) => {
  if (!odd) return '';
  if (!formatterActive) return odd.toFixed(2);

  return formatter.formatOddType(odd, type);
};

app.mount('#app');
