<template>
  <div class="generated-ticket-system-combinations-group-header group-row">
    <div
      class="col"
      :class="largeCols.includes(colName) ? `${colName} large` : colName"
      v-for="(colName, i) in combinationsHeaderColumns"
      :key="i"
    >
      {{$translate.key(colName)}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemsGroupHeader',
  props: {
    combinationsHeaderColumns: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  setup() {
    const largeCols = ['system'];

    return { largeCols };
  },
};
</script>
