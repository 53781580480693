<template>
  <div
    class="betslip-bet"
    :class="{ 'selected': isBetSelected }"
    v-if="bet.idMatch"
    @click="toggleBetslipBet"
  >
    <div
      v-if="bet.locked"
      class="betslip-bet-locked"
    >
       <i class="icon icon-lock-a lock" />
    </div>

    <div
      v-if="selectedTicketType.system"
      class="betslip-bet-banker"
      :class="{ 'active': bet.banker }"
    >
      {{ $translate.key('banker_symbol') }}
    </div>

    <div class="betslip-bet-details">
      <div class="betslip-bet-match">
        {{ bet.matchName }}
      </div>

      <div class="betslip-bet-name">
        {{ betName }}
      </div>

      <div class="betslip-bet-outcome-wrapper">
        <div
          class="betslip-bet-outcome"
          :class="{ 'single': selectedTicketType.hasStake }"
        >
          <div class="betslip-bet-outcome-name">
            {{ bet.outcomeName || '-' }}
          </div>

          <div
            :class="[
              'betslip-bet-outcome-value',
              {'changed-up': bet.oddChanged === 'up'},
              {'changed-down': bet.oddChanged === 'down'}
            ]"
          >
            {{ $formatOdd(bet.oddValue, oddType, oddFormatterActive) || '1.00' }}
          </div>
        </div>

        <div
          class="betslip-bet-stake"
          v-if="selectedTicketType.hasStake"
        >
          <input
            @click.stop
            ref="stakeInput"
            type="text"
            class="betslip-bet-stake-input"
            :value="singleStake.toFixed(2)"
            disabled
          >
        </div>
      </div>

      <div
        class="betslip-bet-odd-changed"
        v-if="(bet.oddChanged === 'up' || bet.oddChanged === 'down')"
      >
        <i
          :class="[
            'icon',
            {'icon-arrow-down-wide down': bet.oddChanged === 'down'},
            {'icon-arrow-up-wide up': bet.oddChanged === 'up'}
          ]"
        />

        <div>
          {{ $translate.key('odds_changed_from') }}
          {{ $formatOdd(bet.oldOdd, oddType, oddFormatterActive) }}
          {{ $translate.key('odds_changed_to') }}
          {{ $formatOdd(bet.newOdd, oddType, oddFormatterActive) }}
        </div>
      </div>
    </div>

  </div>

  <div
    v-if="isBetSelected"
    class="betslip-bet-options"
  >
    <div
      v-if="selectedTicketType.system && !bet.removedMatch"
      class="betslip-bet-select-banker"
      @click.stop="selectBanker"
    >
      <i
        :class="[
          {'icon icon-checkbox': !bet.banker},
          {'icon icon-checkbox-a': bet.banker}]"/>
      <span>{{ $translate.key('banker') }}</span>
    </div>

    <div
      class="betslip-bet-delete"
      @click.stop="removeBet"
    >
      <i class="icon icon-trash"/>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';
import useMatch from '@/composables/match';
import formatter from '@/services/formatter';
import useViewScrollPosition from '@/composables/viewScrollPosition';

export default {
  name: 'BetslipBet',
  props: {
    bet: {
      type: Object,
      required: true,
    },
    singleStake: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const stakeInput = ref(null);
    const { selectedTicketType } = useBetslip();
    const { selectMatch } = useMatch();
    const { scrollToTopOfEventviewBets } = useViewScrollPosition();

    const { oddType, oddFormatterActive } = inject('oddTypeData');

    const activeView = computed(() => store.getters.activeView);

    const selectedBet = computed(() => store.getters.selectedBet);
    const isBetSelected = computed(() => selectedBet.value === props.bet.idMbo);

    const selectBetslipBet = () => {
      store.dispatch('setFocusedMatchSportId', { idSport: props.bet.idSport });
      store.dispatch('setFocusedMatch', { idMatch: props.bet.idMatch });

      if (activeView.value === 'eventview') {
        selectMatch(props.bet.idMatch, true);
      }
      store.dispatch('selectBetslipBet', props.bet.idMbo);
      scrollToTopOfEventviewBets();
    };

    const deselectBetslipBet = () => {
      store.dispatch('setFocusedMatchSportId', {});
      store.dispatch('setFocusedMatch', {});
      store.dispatch('selectBetslipBet', '');
    };

    const toggleBetslipBet = () => {
      if (!isBetSelected.value) selectBetslipBet();
      else deselectBetslipBet();
    };

    const removeBet = () => store.dispatch('removeBet', props.bet.idMbo);

    const betName = computed(() => {
      if (props.bet.sv && props.bet.sv !== '*') {
        const { bet } = props;
        _.assign(bet, { mbSpecialValue: bet.sv, name: bet.betName });
        return formatter.formatBetName(bet);
      }
      return props.bet.betName;
    });

    const selectBanker = () => {
      if (props.bet.removedMatch) return;
      toggleBetslipBet();
      store.dispatch('updateBankers', props.bet.idMbo);
    };

    return {
      betName,
      isBetSelected,
      oddType,
      oddFormatterActive,
      selectedTicketType,
      stakeInput,
      toggleBetslipBet,
      removeBet,
      selectBanker,
    };
  },
};
</script>
