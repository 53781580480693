<template>
  <div
    class="betslip-bet-builder-bet"
    :class="{ 'selected': isBetSelected }"
    v-if="bet.idMatch"
    @click="toggleBetslipBet"
  >
    <div
      v-if="bet.locked"
      class="betslip-bet-builder-bet-locked"
    >
       <i class="icon icon-lock-a lock" />
    </div>

    <div class="betslip-bet-builder-bet-details">
      <div
        v-if="index === 0"
        class="betslip-bet-builder-bet-match"
      >
        {{ bet.matchName }}
      </div>

      <div class="betslip-bet-builder-bet-name">
        {{ betName }}
      </div>

      <div class="betslip-bet-builder-bet-outcome-wrapper">
        <div class="betslip-bet-builder-bet-outcome">
          <div class="betslip-bet-builder-bet-outcome-name">
            {{ bet.outcomeName || '-' }}
          </div>
        </div>
      </div>
    </div>

  </div>

  <div
    v-if="isBetSelected"
    class="betslip-bet-builder-bet-options"
  >
    <div
      class="betslip-bet-builder-bet-delete"
      @click.stop="removeBet"
    >
      <i class="icon icon-trash"/>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';
import useMatch from '@/composables/match';
import useViewScrollPosition from '@/composables/viewScrollPosition';
import formatter from '@/services/formatter';

export default {
  name: 'BetslipBetBuilderBet',
  props: {
    bet: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { betBuilderCalculation, selectedTicketType } = useBetslip();
    const { selectMatch } = useMatch();
    const { scrollToTopOfEventviewBets } = useViewScrollPosition();

    const activeView = computed(() => store.getters.activeView);

    const selectedBet = computed(() => store.getters.selectedBet);
    const isBetSelected = computed(() => selectedBet.value === props.bet.idMbo);

    const betName = computed(() => {
      if (props.bet.sv && props.bet.sv !== '*') {
        const { bet } = props;
        _.assign(bet, { mbSpecialValue: bet.sv, name: bet.betName });
        return formatter.formatBetName(bet);
      }
      return props.bet.betName;
    });

    const selectBetslipBet = () => {
      store.dispatch('setFocusedMatchSportId', { idSport: props.bet.idSport });
      store.dispatch('setFocusedMatch', { idMatch: props.bet.idMatch });

      if (activeView.value === 'eventview') {
        selectMatch(props.bet.idMatch, true);
      }
      store.dispatch('selectBetslipBet', props.bet.idMbo);
      scrollToTopOfEventviewBets();
    };

    const deselectBetslipBet = () => {
      store.dispatch('setFocusedMatchSportId', {});
      store.dispatch('setFocusedMatch', {});
      store.dispatch('selectBetslipBet', '');
    };

    const toggleBetslipBet = () => {
      if (!isBetSelected.value) selectBetslipBet();
      else deselectBetslipBet();
    };

    const removeBet = () => {
      if (betBuilderCalculation.value.id) {
        store.dispatch('subscribeBetBuilderEventCalculation', {
          eventId: props.bet.idMatch,
          outcomeId: props.bet.idMbo,
        });
      }

      store.dispatch('removeBet', props.bet.idMbo);
    };

    return {
      betName,
      isBetSelected,
      removeBet,
      selectedTicketType,
      toggleBetslipBet,
    };
  },
};
</script>
