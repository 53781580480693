<template>
  <div class="generated-ticket-system-combinations-group-item group-row">
    <div class="system col large bold">
      <span v-if="numberOfBankers">
        {{ numberOfBankers }}{{ $translate.key('banker_symbol') }} +
      </span>
      <span>{{ ticketCombinationGroup.parlays }}/{{ ticketCombinationGroup.events }}</span>
    </div>

    <div class="combination col">{{ ticketCombinationGroup.numberOfCombinations }}</div>
    <div class="pay-in col">{{ ticketCombinationGroup.amount.toFixed(2) }}</div>
    <div class="min-win col">{{ (ticketCombinationGroup.minGain.toFixed(2)) }}</div>
    <div class="max-win col">{{ (ticketCombinationGroup.maxGain.toFixed(2)) }}</div>
  </div>
</template>

<script>
export default {
  name: 'SystemsGroupItem',
  props: {
    ticketCombinationGroup: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    combinationsHeaderColumns: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    numberOfBankers: {
      type: Number,
      required: true,
    },
  },
};
</script>
