<template>
  <div
    class="eventview-bet"
    v-if="displayBet"
    ref="eventviewBet">
    <div class="bet-header">
      <div class="bet-header-name">
        {{ betName }}
      </div>
      <div class="bet-header-icons">
        <EventviewBetAssist
          v-if="betAssistEnabled && betAssistBetName"
          :key="betName"
          :id-bet="bet.idBet"
          :id-match="idMatch"
          :bet-name="betAssistBetName"
          :bet-index="betIndex" />
      </div>
    </div>
    <div
      class="bet-outcomes-wrapper"
      v-if="bet.grouped && selectedBetGroupId !== 'betBuilder'">
      <div
        class="bet-outcomes"
        v-for="(groupedBet, idx) in bet.groupedBets"
        :key="`non-bet-builder-bet-${idx}`">
        <EventviewBetOutcome
          v-for="outcome in groupedBet.mbOutcomes"
          :key="outcome.idMbo"
          :outcome="outcome"
          :outcomes-length="groupedBet.mbOutcomes.length"
          :match-betting-status="matchBettingStatus"
          :id-match="idMatch"
          :bet-name="groupedBet.betName"
          :id-mb="groupedBet.idMb"
        />
      </div>
    </div>
    <div
      class="bet-outcomes-wrapper"
      v-if="bet.grouped && betBuilderBet && selectedBetGroupId === 'betBuilder'">
      <div
        class="bet-outcomes"
        v-for="(groupedBet, idx) in betBuilderBet.groupedBets"
        :key="`bet-builder-bet-${idx}`">
        <EventviewBetOutcome
          v-for="outcome in groupedBet.mbOutcomes"
          :key="outcome.idMbo"
          :outcome="outcome"
          :outcomes-length="groupedBet.mbOutcomes.length"
          :match-betting-status="matchBettingStatus"
          :id-match="idMatch"
          :bet-name="groupedBet.betName"
          :id-mb="groupedBet.idMb"
          :is-bet-builder-bet="true"
        />
      </div>
    </div>
    <div
      v-if="!bet.grouped"
      class="bet-outcomes-wrapper">
      <div
        v-if="selectedBetGroupId !== 'betBuilder'"
        class="bet-outcomes">
        <EventviewBetOutcome
          v-for="outcome in bet.mbOutcomes"
          :key="outcome.idMbo"
          :outcome="outcome"
          :outcomes-length="bet.mbOutcomes.length"
          :match-betting-status="matchBettingStatus"
          :id-match="idMatch"
          :bet-name="bet.betName"
          :id-mb="bet.idMb"
          :sv="bet.mbSpecialValue"
        />
      </div>
      <div
        v-else
        class="bet-outcomes">
        <EventviewBetOutcome
          v-for="outcome in betBuilderBet.mbOutcomes"
          :key="outcome.idMbo"
          :outcome="outcome"
          :outcomes-length="bet.mbOutcomes.length"
          :match-betting-status="matchBettingStatus"
          :id-match="idMatch"
          :bet-name="bet.betName"
          :id-mb="bet.idMb"
          :sv="bet.mbSpecialValue"
          :is-bet-builder-bet="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import formatter from '@/services/formatter';
import useMatchBets from '@/composables/matchBets';
import EventviewBetOutcome from './EventviewBetOutcome';
import EventviewBetAssist from './EventviewBetAssist';

export default {
  name: 'EventviewBet',
  components: {
    EventviewBetOutcome,
    EventviewBetAssist,
  },
  props: {
    bet: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    selectedBetGroup: {
      type: Object,
      required: true,
    },
    matchBettingStatus: {
      type: Boolean,
      required: true,
    },
    idMatch: {
      type: String,
      required: true,
    },
    idSport: {
      type: String,
      required: true,
    },
    betIndex: {
      type: Number,
      required: false,
    },
    betBuilderBets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const {
      betAssistEnabled,
      getBetAssistName,
      selectedBetGroupId,
    } = useMatchBets();

    const eventviewBet = ref(null);

    const betName = computed(() => {
      if (props.bet.mbSpecialValue && props.bet.mbSpecialValue !== '*') {
        return formatter.formatBetName(props.bet);
      }
      return props.bet.betName;
    });

    const betBuilderBet = computed(() => {
      const isBetBuilderBet = _.find(props.betBuilderBets, { id: props.bet.idMb });

      if (!isBetBuilderBet) return false;

      const bet = _.assign({}, props.bet);
      bet.isBetBuilderBet = true;

      return bet;
    });

    const displayBet = computed(() => (selectedBetGroupId.value === 'all'
        || (selectedBetGroupId.value === 'betBuilder' && betBuilderBet.value))
        || (selectedBetGroupId.value !== 'all' && selectedBetGroupId.value !== 'betBuilder'));

    const betAssistBetName = computed(() => getBetAssistName(props.idSport, props.bet.idBet));

    return {
      betBuilderBet,
      betName,
      betAssistEnabled,
      betAssistBetName,
      displayBet,
      eventviewBet,
      selectedBetGroupId,
    };
  },
};
</script>
