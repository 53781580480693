export default {
  CLOSED: 'close-o',
  LOST: 'close-o',
  CANCELLED: 'close-o',
  REJECTED: 'close-o',
  EXPIRED: 'close-o',
  WON: 'check',
  PAYEDOUT: 'check',
  PAIDOUT: 'check',
  OPEN: 'clock',
  IN_PLAY: 'play',
  VOID: 'whistle-a',
  HALFWON: 'won-void',
  HALF_WON: 'won-void',
  HALFLOST: 'lost-void',
  HALF_LOST: 'lost-void',
  10: 'check',
  15: 'close-o',
  20: 'whistle-a',
  5: 'clock',
  25: 'won-void',
  30: 'lost-void',
};
