/* eslint-disable */
export const sirConnect = (clientId, language) => {
  const languageMappings = {
    'sr-Latn': 'srl',
  };

  (function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
    g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
    )})(window,document,"script", `https://widgets.sir.sportradar.com/${clientId}/widgetloader`, "SIR", {
        language: languageMappings[language] ?? language,
        theme: false
    });
};
