<template>
  <div class="generated-ticket-meta">
    <div
      v-if="totalOdd && ticketTypeId === 1"
      class="generated-ticket-total-odds"
    >
        <div>{{ $translate.key('total_odds') }}</div>
        <div>{{ $formatOdd(totalOdd, oddType, oddFormatterActive) }}</div>
    </div>

    <div class="generated-ticket-meta-data">
      <div class="generated-ticket-meta-row generated-ticket-payin">
        <div>{{ $translate.key('payin') }}</div>
        <div class="primary">{{ payin.toFixed(2) }}</div>
      </div>

      <div
        v-if="payinTax"
        class="generated-ticket-meta-row generated-ticket-payin-tax"
      >
        <div class="transparent">{{ $translate.key('payin_tax') }}</div>
        <div class="transparent">{{ payinTax.toFixed(2) }}</div>
      </div>

      <div class="generated-ticket-meta-row generated-ticket-max-winning">
        <div class="primary">{{ $translate.key('max_winning') }}</div>
        <div class="primary">{{ maxPossibleWin.toFixed(2) }}</div>
      </div>

      <div
        v-if="bonus.amount"
        class="generated-ticket-meta-row generated-ticket-bonus"
      >
        <div class="primary transparent">{{ $translate.key('bonus') }} ({{bonus.percentage}}%)</div>
        <div class="primary transparent">{{ bonus.amount.toFixed(2) }}</div>
      </div>

      <div
        v-if="maxPossiblePayoutTax"
        class="generated-ticket-meta-row generated-ticket-max-payout-tax"
      >
        <div class="transparent">{{ $translate.key('payout_tax') }}</div>
        <div class="transparent">{{ maxPossiblePayoutTax.toFixed(2) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratedTicketMeta',
  props: {
    totalOdd: {
      type: Number,
      required: true,
    },
    payin: {
      type: Number,
      required: true,
    },
    payinTax: {
      type: Number,
      required: true,
    },
    maxPossibleWin: {
      type: Number,
      required: true,
    },
    bonus: {
      type: Object,
      required: true,
    },
    maxPossiblePayoutTax: {
      type: Number,
      required: true,
    },
    oddType: {
      type: Number,
      required: true,
    },
    oddFormatterActive: {
      type: Boolean,
      required: true,
    },
    ticketTypeId: {
      type: Number,
      required: true,
    },
  },
};
</script>
