<template>
  <div
    :class="[
      'modal',
      { overlay }
    ]"
    @click.self="closeModal"
  >
    <div
      @click.self="closeModal"
      class="modal-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    overlay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const closeModal = () => emit('closeModal');

    return { closeModal };
  },
};
</script>
