<template>
  <div
    v-if="hasScrollableContent"
    class="scroll-buttons-wrap">
    <div class="scroll-buttons">
      <div
        v-if="hasScrollToTop"
        class="scroll-button to-top icon icon-arrow-up-double"
        @click="scroll('toTop')">
      </div>
      <div
        class="scroll-button icon icon-arrow-down-a"
        @click="scroll('down')"></div>
      <div
        class="scroll-button icon icon-arrow-up-a"
        @click="scroll('up')"></div>
    </div>
  </div>
</template>

<script>
import { watchEffect, nextTick, onUnmounted } from 'vue';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  name: 'ScrollButtons',
  props: {
    scrollContent: {
      type: String,
      required: true,
      default: '',
    },
    hasScrollToTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { hasScrollableContent, scroll, content } = useScrollButtons(props.scrollContent);

    watchEffect(async () => {
      await nextTick();
      content.value = document.querySelector(`.${props.scrollContent}`);

      if (!content.value) return;
      hasScrollableContent.value = content
        .value?.scrollHeight > content.value?.clientHeight || false;
    });

    const stopWatcher = watchEffect(() => {});
    onUnmounted(() => {
      stopWatcher();
    });

    return {
      hasScrollableContent,
      scroll,
    };
  },
};
</script>
