import uuidv4 from 'uuid/v4';
import _ from '@/services/lodash';
import types from './mutation-types';
import matchUpdate from '@/utils/matchUpdate';
import appConfig from '@/services/localAppConfig';
import formatter from '@/services/formatter';
import betslipRules from '@/services/betslip-rules';
import betslipSettings from '@/services/betslipConfig';
import { getNumberOfCombinations } from '@/services/ticket-calculations/helper';

export default {
  [types.ADD_MATCHES](state, data) {
    const sports = _.cloneDeep(state.sports);
    const {
      categories, tournaments, sportsWithMatches, matchesBySport,
    } = state;
    const matches = _.cloneDeep(state.matches);
    const ticketBets = _.cloneDeep(state.ticketBets);
    const matchBets = state.matchesBets;

    _.each(data.categories, (value, key) => {
      categories[key] = value;
    });
    _.each(data.tournaments, (value, key) => {
      tournaments[key] = value;
    });
    _.each(data.matches, (value, key) => {
      matches[key] = value;

      const foundMatchOnBetslip = _.findIndex(ticketBets, { idMatch: value.idMatch });
      if (foundMatchOnBetslip >= 0) {
        const bet = ticketBets[foundMatchOnBetslip];
        bet.removedMatch = false;
      }

      if (!matchBets[value.idMatch]) {
        matchBets[value.idMatch] = value.matchBets;
      }

      const matchSportExist = _.find(sportsWithMatches, (sport) => sport.idSport === value.idSport);
      const matchIndex = _.findIndex(matchesBySport[value.idSport], { idMatch: value.idMatch });

      if (matchIndex > -1) {
        matchesBySport[value.idSport].splice(matchIndex, 1, value);
        return;
      }

      if (matchSportExist) {
        matchesBySport[value.idSport].push(value);
        sportsWithMatches[value.idSport].matchesCount += 1;
      } else {
        matchesBySport[value.idSport] = [value];

        sportsWithMatches[value.idSport] = sports[value.idSport] || {};
        sportsWithMatches[value.idSport].matchesCount = 1;
      }
    });

    state.matches = matches;
    state.ticketBets = ticketBets;
  },
  [types.LOAD_TICKETS](state, data) {
    state.lastTickets = data;
  },
  [types.RESET_TICKET_HISTORY_STATE](state) {
    state.resetTrigger = !state.resetTrigger;
  },
  [types.REMOVE_CATEGORY](state, data) {
    const categories = _.cloneDeep(state.categories);
    if (!categories[data.idCategory]) return;
    delete categories[data.idCategory];
    state.categories = categories;
  },
  [types.REMOVE_MATCHES](state, data) {
    const matches = _.cloneDeep(state.matches);
    const { matchesBets, sportsWithMatches, matchesBySport } = state;
    const match = matches[data.idMatch];

    if (matchesBets[data.idMatch]) delete matchesBets[data.idMatch];
    if (!match) return;

    // If match is on betslip, we need to display min info about bet
    const foundMatchOnBetslip = _.findIndex(state.ticketBets, { idMatch: data.idMatch });
    if (foundMatchOnBetslip >= 0) {
      const bet = state.ticketBets[foundMatchOnBetslip];
      const team1 = matches[data.idMatch].team1ShortName || matches[data.idMatch].team1Name;
      const team2 = matches[data.idMatch].team2ShortName || matches[data.idMatch].team2Name;
      _.assign(bet, {
        matchName: `${team1} - ${team2}`,
        removedMatch: true,
      });
    }

    delete matches[data.idMatch];

    sportsWithMatches[match.idSport].matchesCount -= 1;

    if (!sportsWithMatches[match.idSport].matchesCount) {
      delete matchesBySport[match.idSport];
      delete sportsWithMatches[match.idSport];
    } else {
      const matchIndex = _.findIndex(matchesBySport[match.idSport], ((sportMatch) => (
        sportMatch.idMatch === match.idMatch
      )));
      matchesBySport[match.idSport].splice(matchIndex, 1);
    }

    state.matches = matches;
  },
  [types.REMOVE_TOURNAMENT](state, data) {
    const tournaments = _.cloneDeep(state.tournaments);
    if (!tournaments[data.idTournament]) return;
    delete tournaments[data.idTournament];
    state.tournaments = tournaments;
  },
  [types.SET_ACTIVE_MATCH](state, id) {
    state.selectedMatchId = id;
  },
  [types.SET_ACTIVE_SPORT](state, id = 'all') {
    state.selectedSport = id;
  },
  [types.SET_ACTIVE_TICKET](state, data) {
    state.activeTicket = data;
  },
  [types.SET_ACTIVE_VIEW](state, data) {
    state.activeView = data;
  },
  [types.SET_BET_GROUPS](state, data = {}) {
    state.betGroups = data;
  },
  [types.SELECT_BET_GROUP](state, id) {
    state.selectedBetGroupId = id;
  },
  [types.SET_BET_META](state, data) {
    console.log('BET_META: ', data, state);
  },
  [types.SET_BET_STATE](state, data) {
    console.log('SET_BET_STATE: ', data, state);
  },
  [types.SET_CARDS]() {
    // Red cards are updated through currentMatches message
  },
  [types.SET_CATEGORIES](state, data = {}) {
    const categories = _.pickBy(data, { categoryActive: 1 });
    state.categories = categories;
  },
  [types.SET_CURRENT_MATCHES](state, data) {
    const { matchesBySport } = state;
    const oldMatches = _.cloneDeep(state.matches);
    const matchBets = state.matchesBets;
    delete oldMatches.matchBets;
    if (!_.isEmpty(data) && oldMatches) {
      _.each(data, (newMatch) => {
        if (!matchBets[newMatch.idMatch]) matchBets[newMatch.idMatch] = newMatch.matchBets;

        const oldMatch = oldMatches[newMatch.idMatch];
        if (!oldMatch) return;
        oldMatch.active = newMatch.active;
        oldMatch.activeBets = newMatch.activeBets;
        if (!oldMatch.active) {
          delete oldMatches[oldMatch.idMatch];
          return;
        }
        const matchIndex = _.findIndex(matchesBySport[oldMatch.idSport], ((match) => (
          match.idMatch === oldMatch.idMatch
        )));

        _.merge(oldMatch, matchUpdate.updateMatchDetails(oldMatch, newMatch));

        if (matchIndex > -1) matchesBySport[oldMatch.idSport][matchIndex] = oldMatch;
      });
    }
    state.matches = oldMatches;
  },
  [types.SET_LAST_TICKETS_LOADING](state, data) {
    state.lastTicketsLoading = data;
  },
  [types.SET_MATCHES](state, data = {}) {
    const matches = _.pickBy(data, 'active');
    state.matches = matches;

    _.each(state.matches, (match) => {
      state.matchesBets[match.idMatch] = match.matchBets;
    });
  },
  [types.SET_MATCHES_ORDER](state, data = []) {
    if (!_.isEmpty(data)) state.matchesOrder = data;
  },
  [types.SET_MATCH_BET_SEARCH_QUERY](state, data) {
    state.matchBetSearchQuery = data;
  },
  [types.SET_MATCH_NAME_SEARCH_QUERY](state, data) {
    state.matchNameSearchQuery = data;
  },
  [types.SET_MATCH_STATE](state, { bets, matches }) {
    const { sportsBets } = state;
    _.each(bets, (allSportBets, sportId) => {
      _.each(allSportBets, (bet, idBet) => {
        // If not found in base bets, add it
        if (!(sportsBets[sportId] || {})[idBet]) sportsBets[sportId][idBet] = bet;
      });
    });
    state.sportsBets = sportsBets;

    _.each(matches, (match, idMatch) => {
      const matchBets = state.matchesBets[idMatch];

      if (idMatch === state.selectedMatchId && matchBets) {
        const newMatchBets = match.matchBets;
        _.each(newMatchBets, (newBet) => {
          const foundBet = _.find(matchBets, { idMb: newBet.idMb });
          const baseBet = (sportsBets[match.idSport] || {})[newBet.idBet];

          if (!foundBet) {
            if (!matchBets) state.matchesBets[idMatch].matchBets = [];
            matchBets.push(matchUpdate.addBetToMatch(newBet, baseBet));
          } else {
            const updatedMatchBet = matchUpdate.updateBetInMatch(foundBet, newBet, baseBet);
            _.merge(foundBet, updatedMatchBet);
          }
        });
      }
    });
  },
  [types.SET_MONEY_DENOMINATIONS](state, data) {
    state.moneyDenominations = data;
  },
  [types.SET_ODDS](state, data) {
    console.log('SET_ODDS: ', data, state);
  },
  [types.SET_SCORE](state, data) {
    console.log('SCORE: ', data, state);
  },
  [types.SET_SCORE_DETAILS](state, data) {
    console.log('SET_SCORE_DETAILS: ', data, state);
  },
  [types.SET_SELECTED_TAB](state, data) {
    state.selectedTab = data;
  },
  [types.SET_SPORTS](state, data = {}) {
    const sports = _.pickBy(data, { sportActive: 1 });
    _.each(sports, (sport) => {
      if (!sport.sportPosition) _.assign(sport, { sportPosition: 999 });
    });
    state.sports = sports;

    const sportsWithMatchesList = {};
    const groupedMatches = _.groupBy(state.matches, 'idSport');

    state.matchesBySport = groupedMatches;

    _.each(groupedMatches, (sportMatches, sportId) => {
      const sportMatchesCount = sportMatches.length;
      sportsWithMatchesList[sportId] = sports[sportId] || {};
      sportsWithMatchesList[sportId].matchesCount = sportMatchesCount;
      sportsWithMatchesList[sportId].filteredMatchesCount = sportMatchesCount;
    });

    state.sportsWithMatches = sportsWithMatchesList;
  },
  [types.SET_SPORTS_BETS](state, data = {}) {
    state.sportsBets = data;

    const { sportsBets } = state;
    const sportDisplayedBets = {};
    _.each(sportsBets, (value, key) => {
      sportDisplayedBets[key] = _.take(
        _.orderBy(
          _.filter(sportsBets[key],
            { betActive: 1 }),
          'betPosition',
        ),
        7,
      );
    });
    state.sportDisplayedBets = sportDisplayedBets;
  },
  [types.UPDATE_FILTERED_MATCHES](state, { idSport, matchesCount }) {
    const { sportsWithMatches } = state;
    sportsWithMatches[idSport].filteredMatchesCount = matchesCount;
  },
  [types.UPDATE_SPORT_DISPLAYED_BETS](state, { sportId, displayedSportsBets }) {
    state.sportDisplayedBets[sportId] = displayedSportsBets;
  },
  [types.SET_STATE](state, data) {
    state.cachedBets = data.useCachedBets;
    state.cachedBetGroups = data.useCachedBetGroups;
  },
  [types.SET_TOP_MATCH_BETS](state, data) {
    console.log('TOP_MATCH_BETS: ', data, state);
  },
  [types.SET_TOURNAMENTS](state, data = {}) {
    const tournaments = _.pickBy(data, { tournamentActive: 1 });
    state.tournaments = tournaments;
  },
  [types.SET_TRANSLATIONS](state, translations) {
    state.translations = translations;
  },
  [types.UPDATE_CATEGORY]() {
    /* [i]: Might be used later, or deleted completely
    console.log('UPDATE_CATEGORY: ', data, state); */
  },
  [types.UPDATE_CONFIG](state, config) {
    state.config = config;
  },
  [types.UDPATE_GLOBAL_STAKE](state, stake) {
    if (stake === state.stake) {
      // We need to change value of stake to force computed to trigger and input to rerender
      state.stake = 0.99;
      state.stake = stake;
    } else {
      state.stake = stake;
    }
  },
  [types.UPDATE_MATCH]() {
    // This message comes when teams have changed name, that is handled on updateTeam socket message
  },
  [types.UPDATE_MATCH_BET](state, data) {
    const match = state.matches[data.idMatch];
    if (!match) return;

    match.activeBets = data.activeBets;

    const matchBets = state.matchesBets[data.idMatch];
    if (!matchBets) {
      state.matchesBets[data.idMatch] = data.bets;
      return;
    }

    _.each(data.bets, (newBet) => {
      const matchBet = _.find(matchBets, { idMb: newBet.idMb });
      const baseBet = (state.sportsBets[match.idSport] || {})[newBet.idBet];

      if (matchBet) {
        matchBet.mbActive = newBet.mbActive;
        if (!newBet.mbChanged) return;
        const updatedMatchBet = matchUpdate.updateBetInMatch(matchBet, newBet, baseBet);
        _.merge(matchBet, updatedMatchBet);
      } else {
        if (!matchBets) state.matchesBets[data.idMatch] = [];

        matchBets.push(matchUpdate.addBetToMatch(newBet, baseBet));
      }
    });
  },
  [types.UPDATE_MATCH_BETTING_STATUS](state, data) {
    const match = state.matches[data.idMatch];
    if (match) match.bettingStatus = data.bettingStatus;
  },
  [types.UPDATE_META_BET](state, data) {
    let displayedBets = _.cloneDeep(state.sportDisplayedBets[data.idSport]);
    let changedDisplayedBets = false;
    const foundBetIndex = _.findIndex(displayedBets, (bet) => bet.idBet === data.idBet);

    if (foundBetIndex === -1 && data.betActive) {
      displayedBets.push(data);
      displayedBets = _.orderBy(displayedBets, ['betPosition'], ['asc']);
      changedDisplayedBets = true;
    } else if (foundBetIndex !== -1 && !data.betActive) {
      displayedBets.splice(foundBetIndex, 1);
      changedDisplayedBets = true;
    }

    if (state.sportsBets[data.idSport]) {
      state.sportsBets[data.idSport][data.idBet] = data;
    }

    if (changedDisplayedBets) state.sportDisplayedBets[data.idSport] = displayedBets;
  },
  [types.UPDATE_SERVER_TIMEOUT_ID](state, data) {
    state.serverTimeoutId = data;
  },
  [types.UPDATE_SPORT](state, data) {
    const sports = _.cloneDeep(state.sports);
    const { sportsWithMatches, matchesBySport } = state;

    if (!data.sportActive) {
      delete sports[data.idSport];
      delete sportsWithMatches[data.idSport];
      delete matchesBySport[data.idSport];
    } else {
      sports[data.idSport] = data;

      const groupedMatches = _.groupBy(state.matches, 'idSport');

      state.matchesBySport = groupedMatches;

      _.each(groupedMatches, (sportMatches, sportId) => {
        if (sportId !== data.idSport) return;
        const sportMatchesCount = sportMatches.length;
        sportsWithMatches[sportId] = sports[sportId] || {};
        sportsWithMatches[sportId].matchesCount = sportMatchesCount;
        sportsWithMatches[sportId].filteredMatchesCount = sportMatchesCount;
      });
    }
    state.sports = sports;
  },
  [types.UPDATE_TEAM](state, data) {
    const matches = _.cloneDeep(state.matches);
    const { matchesBySport } = state;
    const match = _.find(matches, (m) => m.idTeam1 === data.idTeam || m.idTeam2 === data.idTeam);
    if (!match) return;
    if (match.idTeam1 === data.idTeam) {
      match.team1Name = data.teamName;
      match.team1ShortName = data.teamShortName;
    } else {
      match.team2Name = data.teamName;
      match.team2ShortName = data.teamShortName;
    }
    matches[match.idMatch] = match;

    const matchIndex = _.findIndex(matchesBySport[match.idSport], { idMatch: match.idMatch });

    if (matchIndex > -1) {
      matchesBySport[match.idSport].splice(matchIndex, 1, match);
      return;
    }

    state.matches = matches;
  },
  [types.UPDATE_TOURNAMENT](state, data) {
    const tournaments = _.cloneDeep(state.tournaments);

    if (!data.tournamentActive) {
      delete tournaments[data.idTournament];
    } else {
      tournaments[data.idTournament] = data;
    }
    state.tournaments = tournaments;
  },
  [types.ADD_NOTIFICATION](state, data) {
    const foundNotification = _.findIndex(state.notifications, { id: data.id });

    if (foundNotification !== -1) return;

    state.notifications.push({
      ...data,
      id: data.id || uuidv4(),
    });
  },
  [types.REMOVE_NOTIFICATION](state, id) {
    const notificationId = _.findIndex(state.notifications, { id });
    if (notificationId !== -1) state.notifications.splice(notificationId, 1);
  },
  [types.ADD_BETSLIP_NOTIFICATION](state, data) {
    state.betslipNotifications.push({
      ...data,
      id: data.id || uuidv4(),
    });
  },
  [types.REMOVE_BETSLIP_NOTIFICATION](state, id) {
    const notificationId = _.findIndex(state.betslipNotifications, { id });
    if (notificationId !== -1) state.betslipNotifications.splice(notificationId, 1);
  },
  [types.ADD_BETS_TO_BETSLIP](state, data) {
    const formattedBets = data.bets.map(formatter.formatTicketCheckBet);

    state.ticketBets = formattedBets;
  },
  [types.AWAKE_APP](state) {
    state.appSnoozed = false;
  },
  [types.CLEAR_BETSLIP](state, { stake = '1.00', selectedTicketTypeId = 1 }) {
    state.stake = stake;
    state.selectedTicketTypeId = selectedTicketTypeId;
    state.ticketBets = [];
    state.betslipRulesNotifications = [];
    state.betslipGeneralNotifications = [];
    state.selectedBet = '';
    state.ticketSystems = [];
    state.betslipOddsOrStatusChanged = false;
  },
  [types.CLEAR_GENERAL_STATE_DATA](state) {
    state.activeView = 'overview';
    state.appSnoozed = true;
    state.generatedTicket = {
      code: '',
      active: false,
      ticket: {},
    };
    state.matchBetSearchQuery = '';
    state.matchNameSearchQuery = '';
    state.selectedMatchId = '';
    state.selectedSport = 'all';
    state.selectedBetGroupId = 'all';
    state.ticketProcessing = false;
    state.focusedMatch = {
      idMatch: '',
      animationPrevented: true,
    };
    state.focusedMatchSportId = {
      idSport: '',
      animationPrevented: true,
    };
  },
  [types.REMOVE_BET](state, idMbo) {
    const ticketBets = _.clone(state.ticketBets);
    const foundBetIndex = _.findIndex(ticketBets, { idMbo });

    ticketBets.splice(foundBetIndex, 1);
    state.ticketBets = ticketBets;
    state.selectedBet = '';
  },
  [types.SELECT_BANKER](state, idMbo) {
    const ticketBets = _.clone(state.ticketBets);
    const foundBetIndex = _.findIndex(ticketBets, { idMbo });

    if (foundBetIndex < 0) return;

    ticketBets[foundBetIndex].banker = !ticketBets[foundBetIndex].banker;
    state.ticketBets = ticketBets;
  },
  [types.UNSELECT_BET](state, bet) {
    const ticketBets = _.clone(state.ticketBets);
    const foundBetIndex = _.findIndex(ticketBets, { idMbo: bet.idMbo });

    if (bet.idMbo === state.selectedBet) state.selectedBet = '';

    ticketBets.splice(foundBetIndex, 1);

    state.ticketBets = ticketBets;
  },
  [types.SELECT_BET](state, bet) {
    const ticketBets = _.clone(state.ticketBets);
    const { selectedTicketTypeId } = state;

    const foundSameMatchIndex = _.findIndex(ticketBets, { idMatch: bet.idMatch });

    if (foundSameMatchIndex >= 0 && selectedTicketTypeId !== 3 && !bet.isBetBuilderBet) {
      ticketBets.splice(foundSameMatchIndex, 1, bet);
    } else {
      ticketBets.push(bet);
    }

    state.ticketBets = ticketBets;
  },
  [types.SELECT_BETSLIP_BET](state, data) {
    state.selectedBet = state.selectedBet === data ? '' : data;
  },
  [types.SELECT_TICKET_TYPE](state, id) {
    state.selectedTicketTypeId = id;
  },
  [types.SET_APP_CONFIG](state, data) {
    const localConfig = _.cloneDeep(state.config);
    const applicationConfig = _.assign(localConfig, data);

    state.config = applicationConfig;
  },
  [types.SET_LOCAL_APP_CONFIG](state, company) {
    let localConfig = _.cloneDeep(appConfig.default);

    if (appConfig[company]) {
      localConfig = _.assign(localConfig, appConfig[company]);
    }

    state.config = localConfig;
  },
  [types.SET_AUTHORIZATION_TOKEN](state, data) {
    state.authorizationToken = data;
  },
  [types.SET_BETSHOP](state, data) {
    state.betshop = data;
  },
  [types.SET_BETSLIP_CONFIG](state, { taxes, rules }) {
    state.betslipConfig.taxes = !_.isEmpty(taxes) ? taxes : betslipSettings.taxes;
    state.betslipConfig.rules = !_.isEmpty(rules) ? rules : betslipSettings.rules;
  },
  [types.SET_BONUS_CONFIG](state, data) {
    state.bonusConfig = data?.bonuses?.[0];
  },
  [types.SET_COMPANY](state, data) {
    state.company = data;
  },
  [types.SET_COMPANY_NAME](state, value) {
    state.companyName = value;
  },
  [types.SET_CPV_UUID](state, data) {
    state.cpvUuid = data;
  },
  [types.SET_CHECKED_TICKET](state, data) {
    state.checkedTicket = data;
  },
  [types.SET_DEFAULT_STAKE](state, data) {
    if (!data) return;
    state.stake = data;
  },
  [types.SET_DEVICE](state, data) {
    state.device = data;
  },
  [types.SET_WORKING_TIME_BLOCKER](state, data) {
    state.workingTimeBlocker = data;
  },
  [types.SET_FOCUSED_MATCH](state, data) {
    state.focusedMatch = data;
  },
  [types.SET_FOCUSED_MATCH_SPORT_ID](state, data) {
    state.focusedMatchSportId = data;
  },
  [types.SET_PRODUCT](state, data) {
    state.product = data;
  },
  [types.SET_ODD_FORMATTER_STATUS](state, data) {
    state.oddFormatterActive = data;
  },
  [types.SET_ODD_TYPE](state, type) {
    state.oddType = type;
  },
  [types.SET_PENDING_TICKET_REQUEST_UUID](state, requestUuid) {
    state.pendingTicketRequestUuid = requestUuid;
  },
  [types.SET_TERMINAL](state, data) {
    state.terminal = data;
  },
  [types.SET_LANGUAGE](state, data) {
    state.language = data;
  },
  [types.SET_TICKET_PROCESSING](state, load) {
    state.ticketProcessing = load;
  },
  [types.SET_MATCH_UNIQUE_BETS](state, data) {
    state.ticketBets = data;
  },
  [types.SAVE_SYSTEM_COMBINATIONS](state, systems) {
    state.ticketSystems = systems;
  },
  [types.TOGGLE_BETSLIP](state, toggle = undefined) {
    state.betslipOpen = _.isUndefined(toggle) ? !state.betslipOpen : toggle;
  },
  [types.SET_AUTOCHECKER_STATUS](state, data) {
    state.autoCheckerData.active = data;
  },
  [types.UPDATE_AUTOCHECKER_COUNT](state, data = undefined) {
    const { count } = state.autoCheckerData;

    state.autoCheckerData.count = _.isUndefined(data) ? count + 1 : 0;
  },
  [types.UPDATE_TICKET_SYSTEMS](state, nonBankersCount) {
    const ticketSystems = [];

    for (let i = 1; i <= nonBankersCount; i += 1) {
      ticketSystems.push({
        id: i,
        of: nonBankersCount,
        selected: false,
        stake: '',
        combinations: getNumberOfCombinations(i, nonBankersCount),
      });
    }

    state.ticketSystems = ticketSystems;
  },
  [types.CLEAR_BETSLIP_RULES_NOTIFICATIONS](state) {
    state.betslipRulesNotifications = [];
  },
  [types.UPDATE_BETSLIP_GENERAL_NOTIFICATIONS](state, notification) {
    const betslipGeneralNotifications = _.cloneDeep(state.betslipGeneralNotifications);
    const foundNotificationIndex = _.findIndex(
      betslipGeneralNotifications, { id: notification.id },
    );

    if (foundNotificationIndex >= 0) betslipGeneralNotifications.splice(foundNotificationIndex, 1);

    if (notification.active) betslipGeneralNotifications.push(notification);

    state.betslipGeneralNotifications = betslipGeneralNotifications;
  },
  [types.UPDATE_GENERAL_BETSLIP_RULES_NOTIFICATIONS](state) {
    const {
      selectedTicketTypeId,
      betslipConfig,
      ticketBets,
      stake,
    } = state;

    const foundBetBuilderBet = _.find(ticketBets, 'isBetBuilderBet');

    // Apply rule only if any BetBuilder Bet exists in Betslip
    if (foundBetBuilderBet) {
      const maxBetBuilderBetCountRule = betslipRules.isMaxBetBuilderBetRuleValid(
        selectedTicketTypeId,
        betslipConfig.rules,
        ticketBets,
      );

      if (!maxBetBuilderBetCountRule.valid) {
        state.betslipRulesNotifications.push(maxBetBuilderBetCountRule.notification);
      }
    }

    const maxBetCountRule = betslipRules.isMaxBetRuleValid(
      selectedTicketTypeId,
      betslipConfig.rules,
      ticketBets,
    );

    if (!maxBetCountRule.valid) state.betslipRulesNotifications.push(maxBetCountRule.notification);

    const payinRuleName = selectedTicketTypeId !== 3
      ? { min: 'MIN_TICKET_PAYIN', max: 'MAX_TICKET_PAYIN' }
      : { min: 'MIN_TICKET_PAYIN_SINGLE', max: 'MAX_TICKET_PAYIN_PER_BET' };

    const payinRule = betslipRules.isPayinValid(
      selectedTicketTypeId,
      betslipConfig.rules,
      stake,
      payinRuleName,
      ticketBets.length,
    );

    if (!payinRule.valid) state.betslipRulesNotifications.push(payinRule.notification);
  },
  [types.UPDATE_SYSTEM_NOTIFICATIONS](state) {
    const {
      stake,
      ticketBets,
      ticketSystems,
      betslipConfig,
      selectedTicketTypeId,
    } = state;

    const maxNonBankersRule = betslipRules.isMaxNonBankersRuleValid(
      betslipConfig.rules,
      ticketBets,
    );

    if (!maxNonBankersRule.valid) {
      state.betslipRulesNotifications.push(maxNonBankersRule.notification);
    }

    const maxBankersRule = betslipRules.isMaxBankersRuleValid(
      betslipConfig.rules,
      ticketBets,
    );

    if (!maxBankersRule.valid) {
      state.betslipRulesNotifications.push(maxBankersRule.notification);
    }

    const stakePerCombRule = betslipRules.stakePerCombPayinValid(
      selectedTicketTypeId,
      betslipConfig.rules,
      stake,
      ticketSystems,
    );

    if (!stakePerCombRule.valid) {
      state.betslipRulesNotifications.push(stakePerCombRule.notification);
    }
  },
  [types.UPDATE_WINNINGS_NOTIFICATIONS](state, maxWin) {
    const { selectedTicketTypeId, betslipConfig } = state;

    const maxWinRule = betslipRules.maxWinValid(
      selectedTicketTypeId,
      betslipConfig.rules,
      maxWin,
    );

    if (!maxWinRule.valid) state.betslipRulesNotifications.push(maxWinRule.notification);
  },
  [types.TOGGLE_GENERATED_TICKET](state, data) {
    state.generatedTicket.active = !state.generatedTicket.active;
    state.generatedTicket.ticket = data ? data.ticket : {};
    state.generatedTicket.code = data ? data.code : '';
  },
  [types.TOGGLE_CONNECTION_STATUS](state, data) {
    state.appOnline = data;
  },
  [types.UPDATE_BETSLIP_STATUS](state, status) {
    state.betslipOddsOrStatusChanged = status;
  },
  [types.OVERVIEW_SCROLL_UPDATE](state, data) {
    state.overviewScrollPosition = data;
  },
  [types.UPDATE_BET_ASSIST_POPUP_ID](state, data) {
    state.betAssistPopupId = data;
  },
  [types.SET_IS_CONTENT_SCROLLED](state, data) {
    state.isScrolled = data;
  },
  [types.SET_BET_BUILDER_BETSLIP_RULES](state) {
    if (!state.betslipConfig.rules) return;

    // Hardcode MaxBetCount rule for BetBuilder bets (since we don't receive it from Backend)
    const translationKey = 'restriction_max_bet_builder_bets';
    state.betslipConfig.rules.push({
      idRule: '999',
      name: '999',
      group: null,
      message: state.translations[translationKey] || translationKey,
      ticketType: '1',
      translation_key: translationKey,
      conditions: [
        {
          ruleType: '',
          dataType: '',
          value: state.betBuilderBetslipRulesValues.maxBetCount,
        },
      ],
      placeholders: [state.betBuilderBetslipRulesValues.maxBetCount],
      translationKey,
    });
  },
  [types.SET_BET_BUILDER_EVENT_SELECTION](state, data = []) {
    state.betBuilderEventSelection = data;
  },
  [types.SET_BET_BUILDER_EVENT_CALCULATION](state, data) {
    const newCalculation = {
      available: data.available,
      odd: data.odd,
      id: data.id,
      betOutcomeId: data.betOutcomeId,
      matchBetOutcomeId: data.matchBetOutcomeId,
    };
    state.betBuilderEventCalculation = newCalculation;
  },
  [types.SET_BET_BUILDER_SELECTED_OUTCOMES](state, data) {
    state.betBuilderSelectedOutcomes = data;
  },
  [types.SET_BET_BUILDER_AVAILABLE_SELECTIONS](state, data) {
    const outcomes = [];

    // We are using ugly but fast loop here.
    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].outcomes.length; j += 1) {
        outcomes.push(data[i].outcomes[j].id);
      }
    }

    state.betBuilderAvailableSelections = outcomes;
  },
  [types.RESET_BET_BUILDER_AVAILABLE_SELECTIONS](state) {
    state.betBuilderAvailableSelections = null;
  },
  [types.SET_SEVEN_TAXES](state, data) {
    state.sevenTaxes = data;
  },
  [types.SET_HEADERS_SETTINGS](state, data) {
    state.headersSettings = data;
  },
  [types.SET_USER_AUTHORIZATION](state, value) {
    state.operatorLoggedIn = value;
  },
  [types.SET_LMT](state, value) {
    state.liveMatchTracker = value;
  },
  [types.SET_LMT_HAS_ERROR](state, value) {
    state.liveMatchTrackerError = value;
  },
  [types.SET_LMT_ACTIVE](state, value) {
    state.liveMatchTrackerActive = value;
  },
};
