import { rulesConstants } from './constants';
import {
  getRule,
  getRuleValue,
  invalidPayload,
  validPayload,
  ticketBetsCountInvalid,
  nonBankersBets,
  bankersBets,
  getGlobalStake,
  systemTotalCombinations,
  getStakePerCombination,
} from './helper';

const isMaxBetBuilderBetRuleValid = (ticketType, rules, ticketBets) => {
  const ruleName = rulesConstants.MAX_BET_BUILDER_BET_COUNT;
  const maxBetCountRule = getRule(ruleName, ticketType, rules);
  const maxBetCountNumber = getRuleValue(maxBetCountRule);

  const bets = ticketBets.length;
  const ruleInvalid = maxBetCountRule && ticketBetsCountInvalid(bets, maxBetCountNumber);

  return ruleInvalid ? invalidPayload(ruleName, maxBetCountRule.message) : validPayload;
};

const isMaxBetRuleValid = (ticketType, rules, ticketBets) => {
  const ruleName = rulesConstants.MAX_BET_COUNT;
  const maxBetCountRule = getRule(ruleName, ticketType, rules);
  const maxBetCountNumber = getRuleValue(maxBetCountRule);

  const bets = ticketBets.length;
  const ruleInvalid = maxBetCountRule && ticketBetsCountInvalid(bets, maxBetCountNumber);

  return ruleInvalid ? invalidPayload(ruleName, maxBetCountRule.message) : validPayload;
};

const isMaxNonBankersRuleValid = (rules, ticketBets) => {
  const ruleName = rulesConstants.MAX_NUMBER_OF_NON_BANKERS;
  const maxNonBankersRule = getRule(ruleName, 2, rules);
  const maxNonBanker = getRuleValue(maxNonBankersRule);
  const nonBankers = nonBankersBets(ticketBets);

  const ruleInvalid = nonBankers.length >= maxNonBanker;

  return ruleInvalid ? invalidPayload(ruleName, maxNonBankersRule.message) : validPayload;
};

const isMaxBankersRuleValid = (rules, ticketBets) => {
  const ruleName = rulesConstants.MAX_NUMBER_OF_BANKERS;
  const maxBankersRule = getRule(ruleName, 2, rules);
  const maxBanker = getRuleValue(maxBankersRule);
  const bankers = bankersBets(ticketBets);

  const ruleInvalid = bankers.length >= maxBanker;

  return ruleInvalid ? invalidPayload(ruleName, maxBankersRule.message) : validPayload;
};

const isPayinValid = (ticketType, rules, stake, payinRuleName, betsNumber) => {
  const minPayinRuleName = rulesConstants[payinRuleName.min];
  const maxPayinRuleName = rulesConstants[payinRuleName.max];
  const minPayinRule = getRule(minPayinRuleName, ticketType, rules);
  const maxPayinRule = getRule(maxPayinRuleName, ticketType, rules);
  const minPayin = getRuleValue(minPayinRule, 'float');
  const maxPayin = getRuleValue(maxPayinRule, 'float');

  const globalStake = getGlobalStake(ticketType, stake, betsNumber);

  if (globalStake < minPayin) return invalidPayload(minPayinRuleName, minPayinRule.message);
  if (globalStake > maxPayin) return invalidPayload(maxPayinRuleName, maxPayinRule.message);

  return validPayload;
};

const stakePerCombPayinValid = (ticketType, rules, stake, systems) => {
  const ruleName = rulesConstants.MIN_TICKET_PAYIN_SYSTEM_PER_COMBINATION;
  const minPayinRule = getRule(ruleName, ticketType, rules);
  const minPayinPerComb = getRuleValue(minPayinRule, 'float');

  const selectedSystems = systems.filter((system) => system.selected);
  const totalCombinations = systemTotalCombinations(selectedSystems);
  const stakePerCombination = getStakePerCombination(stake, totalCombinations);

  const ruleInvalid = stakePerCombination < minPayinPerComb;

  return ruleInvalid ? invalidPayload(ruleName, minPayinRule.message) : validPayload;
};

const maxWinValid = (ticketType, rules, winnings) => {
  const rule = ticketType === 3 ? 'MAX_WINNING_SINGLE' : 'MAX_WINNING';
  const ruleName = rulesConstants[rule];
  const maxWinRule = getRule(ruleName, ticketType, rules);
  const maxWinValue = getRuleValue(maxWinRule, 'float');

  const ruleInvalid = winnings > maxWinValue;

  return ruleInvalid ? invalidPayload(ruleName, maxWinRule.message) : validPayload;
};

export default {
  getRule,
  isMaxBetBuilderBetRuleValid,
  isMaxBetRuleValid,
  isMaxNonBankersRuleValid,
  isMaxBankersRuleValid,
  isPayinValid,
  stakePerCombPayinValid,
  maxWinValid,
};
