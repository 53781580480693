<template>
  <TicketHistory />
</template>

<script>
import TicketHistory from '@/components/ticket-history/TicketHistory';

export default {
  components: { TicketHistory },
};

</script>
