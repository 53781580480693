import { ref } from 'vue';

const keyboardDefaults = {
  visible: false,
  input: null,
  keySet: 'default',
  layout: 'compact',
  preview: false,
  previewInput: null,
  headerText: null,
  predefinedValues: null,
  maxValue: null,
};

export const keyboard = ref({ ...keyboardDefaults });

export const showKeyboard = (e, options) => {
  if (keyboard.value.visible) return;
  keyboard.value = {
    ...keyboardDefaults,
    ...options,
    input: e.target,
    visible: true,
  };
};

export const hideKeyboard = () => {
  keyboard.value = {
    ...keyboardDefaults,
    input: keyboard.value?.previewInput || keyboard.value?.input || null,
  };
};

/**
 *
 * @param {Object} caret
 * @param {String} text
 * @returns String
 */
export const backspace = (caret, text) => [text.slice(0, caret.start - 1), text.slice(caret.start)].join('');

/**
 *
 * @param {Object} caret
 * @param {String} textValue
 * @param {String} ch
 * @returns String
 */
export const insertChar = (caret, textValue, ch) => {
  const text = window.getSelection().type === 'Range' ? '' : textValue;
  return [text.slice(0, caret.start), ch, text.slice(caret.start)].join('');
};

/**
 *
 * @param {Object} caret
 */
export const setSelection = (caret) => {
  if (!keyboard.value.visible || !caret) return;
  keyboard.value.input.focus();
  keyboard.value.input.selectionStart = caret.start;
  keyboard.value.input.selectionEnd = caret.end;
};

/**
 *
 * @param {String} layout
 * @param {String} text
 * @returns String
 */
export const validateInput = (layout, text) => {
  if (layout !== 'numeric' || !text) return text;
  const regexFormat = new RegExp('^-?\\d+(?:\\.\\d{0,2})?');
  return text.toString().match(regexFormat);
};

/**
 *
 * @param {Array} row
 * @param {Object} meta
 * @returns Array
 */
export const formatKeyboardRow = (row, meta) => {
  const result = [];
  row.forEach((el) => {
    if (el.length > 2 && el[0] === '{' && el[el.length - 1]) {
      const name = el.substring(1, el.length - 1);
      if (meta[name]) result.push(meta[name]);
    } else result.push({ key: el, text: el });
  });
  return result;
};

/**
 *
 * @param {Object} input
 * @returns Object
 */
export const getCaret = (input) => ({
  start: input?.selectionStart || input?.value?.length || 0,
  end: input?.selectionEnd || input?.value?.length || 0,
});
