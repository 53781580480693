<template>
  <div class="overview-match" ref="matchOverview" :data-id="match.idMatch">
    <template v-if="isVisible">
      <div
        class="overview-match-details"
        @click="openMatch"
      >
        <div class="overview-match-details-header">
          <div class="match-time">
            <span
              v-if="timeStatus"
              class="period-time"
            >
              {{ timeStatus }}
            </span>

            <span
              v-if="remainingTime && timeStatus"
              class="time-separator"
            >
              |
            </span>

            <span
              v-if="remainingTime"
              class="extended-time"
            >
              {{ remainingTime }}
            </span>
          </div>

          <FlagIcon
            class="overview-match-details-header-flag"
            ratio-class="flags-0-5"
            :flag-code="flagCode"
          />

          <div class="match-info">{{ categoryName }} / {{ tournamentName }}</div>
          <i
            v-if="match.hasScout && liveMatchTrackerEnabled"
            class="icon icon-sport-soccer-field" />
        </div>

        <div class="overview-match-details-body">
          <div class="match-teams">
            <div
              v-for="team in teams"
              :key="team.id"
              class="team-details"
            >
              {{ team.name }}
              <i
                v-for="(redCard, index) in team.redCards"
                :key="index"
                class="icon icon-card red-card"
              />
            </div>
          </div>

          <div
            class="match-results"
            :class="{'teams-have-server': teamsHaveServer}"
          >
            <div
              class="result"
              v-for="(result, index) in results"
              :key="index"
            >
              <div>{{ result.team1 }}</div>
              <div>{{ result.team2 }}</div>
            </div>
          </div>

          <ServerIcons
            v-if="teamsHaveServer"
            :teams="teams"
          />
        </div>
      </div>

      <div
        class="overview-match-bets"
        v-if="match.activeBets"
      >
        <MatchBet
          v-for="bet in displayedBets"
          :key="bet.idBet"
          :displayed-bet="bet"
          :id-match="match.idMatch"
          :match-bets="matchBets"
          :match-betting-status="match.bettingStatus"
        />
      </div>

      <div
        class="overview-match-bets no-active-bets"
        v-if="!match.activeBets"
      >
        <span>
          <i class="icon icon-lock-a"/>
          {{ $translate.key('no_match_active_bets') }}
        </span>
      </div>

      <div
        class="overview-match-bets-number"
        @click="openMatch"
      >
        <span>+{{ match.activeBets }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MatchBet from './MatchBet';
import useMatch from '@/composables/match';
import useSportBets from '@/composables/sportBets';
import useSearch from '@/composables/search';
import useViewScrollPosition from '@/composables/viewScrollPosition';
import matchDetailsMixin from '@/mixins/matchDetails';
import matchResultsMixin from '@/mixins/matchResults';
import ServerIcons from '@/components/common/ServerIcons';
import FlagIcon from '@/components/common/FlagIcon';

export default {
  name: 'MatchRow',
  components: {
    MatchBet,
    ServerIcons,
    FlagIcon,
  },
  mixins: [matchDetailsMixin, matchResultsMixin],
  props: {
    match: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { selectMatch } = useMatch();
    const { searchNotEmpty } = useSearch();
    const { storeCurrentOverviewScrollPos } = useViewScrollPosition();

    const openMatch = () => {
      storeCurrentOverviewScrollPos(searchNotEmpty.value);

      store.dispatch('setMatchNameSearchQuery', '');

      selectMatch(props.match.idMatch);
    };

    const { displayedBets } = useSportBets(props.match.idSport);
    const matchesBets = computed(() => store.getters.matchesBets);

    const matchBets = matchesBets.value[props.match.idMatch];

    const liveMatchTrackerEnabled = computed(() => store.getters.liveMatchTrackerEnabled);

    return {
      displayedBets,
      openMatch,
      matchBets,
      liveMatchTrackerEnabled,
    };
  },
  computed: {
    flagCode() {
      return this.categoryIsoName ? _.lowerCase(this.categoryIsoName) : 'int';
    },
  },
};
</script>
