import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue';

const BREAK_EXTRA_MEDIUM = 1024;
const BREAK_PORTRAIT = 1080;
const BREAK_REGULAR = 1280;
const BREAK_EXTRA_LARGE = 1600;
const BREAK_ULTRA_LARGE = 1920;

export default function useBreakpoints() {
  const clientWidth = ref(0);
  const clientHeight = ref(0);

  const breakpoints = computed(() => {
    const p = clientWidth.value < clientHeight.value;

    const sm = !p && clientWidth.value <= BREAK_EXTRA_MEDIUM;
    const md = !p && clientWidth.value <= BREAK_REGULAR && !sm;
    const lg = !p && clientWidth.value <= BREAK_EXTRA_LARGE && !(md || sm);
    const xl = !p && clientWidth.value <= BREAK_ULTRA_LARGE && !(md || sm || lg);
    const xxl = !p && clientWidth.value >= BREAK_ULTRA_LARGE;

    // portrait check
    const psm = p && (clientWidth.value <= BREAK_PORTRAIT);

    return {
      small: sm, // screens to 1024
      medium: md, // from 1025 to 1280
      large: lg, // from 1281 to 1600
      extraLarge: xl, // above 1600
      ultraLarge: xxl, // above 1920
      portraitSmall: psm, // portraits to 1080
      isPortrait: p,

      // Ranges
      mediumAndBellow: (sm || md) && !(lg || xl), // screens up to 1280
      largeAndBellow: (sm || md || lg) && !xl, // screens up to 1600

      // Dimensions
      clientHeight: clientHeight.value,
      clientWidth: clientWidth.value,
    };
  });

  const updateDimensions = () => {
    const docWidth = document.documentElement.clientWidth;
    const docHeight = document.documentElement.clientHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    clientWidth.value = Math.max(docWidth, windowWidth || 0);
    clientHeight.value = Math.max(docHeight, windowHeight || 0);
  };

  onMounted(async () => {
    await nextTick();
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateDimensions);
  });

  return {
    breakpoints,
  };
}
