import pickBy from 'lodash/pickBy';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import assign from 'lodash/assign';
import each from 'lodash/each';
import find from 'lodash/find';
import merge from 'lodash/merge';
import take from 'lodash/take';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import clone from 'lodash/clone';
import min from 'lodash/min';
import max from 'lodash/max';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';

const _ = {
  pickBy,
  orderBy,
  groupBy,
  sumBy,
  assign,
  each,
  find,
  merge,
  take,
  cloneDeep,
  findIndex,
  isEmpty,
  filter,
  isString,
  isObject,
  isUndefined,
  clone,
  min,
  max,
  uniqBy,
  isArray,
};

export default _;
