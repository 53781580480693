import { computed, nextTick } from 'vue';
import { useStore } from 'vuex';

/**
 *
 * @param {String} idBet
 * @param {String} idMatch
 * @param {String} betName
 * @param {Number} betIndex
 * @returns Object
 */
export default function useBetAssist({
  idBet, idMatch, betName, betIndex,
}) {
  const store = useStore();

  const betAssistId = `${idBet.value}-${betIndex.value}`;
  const betAssistPopupId = computed(() => store.getters.betAssistPopupId);
  const activeBetAssist = computed(() => betAssistPopupId.value === betAssistId);

  const disableBetAssist = () => {
    store.dispatch('updateBetAssistPopupId');
    window.SIR('removeWidget', '.bet-assist');
  };

  const widgetProps = computed(() => ({
    matchId: Number(idMatch.value),
    market: betName.value,
    silent: store.getters.config?.srWidgets?.silent ?? false,
  }));

  const toggleBetAssist = async (initial = true) => {
    if (betAssistPopupId.value && activeBetAssist.value) {
      if (!initial) disableBetAssist();
      else {
        disableBetAssist();
        return;
      }
    }

    store.dispatch('updateBetAssistPopupId', betAssistId);
    await nextTick();
    window.SIR('addWidget', `.${CSS.escape(betAssistId)}`, 'betAssist.standalone', widgetProps.value);
  };

  return {
    activeBetAssist,
    toggleBetAssist,
    disableBetAssist,
    betAssistPopupId,
  };
}
