<template>
  <div
    class="overview-header"
    :class="{'collapsed': sportCollapsed}"
  >
    <OverviewHeaderSport
      :label="label"
      :sport-id="sportId"
      :enable-sport-color="enableSportColor"
    />

    <div
      v-if="!sportCollapsed"
      class="overview-header-bets"
    >
      <OverviewHeaderBet
        v-for="(bet, i) in displayedBets"
        :key="i"
        :displayed-bet="bet"
        :sport-id="sportId"
        :has-more-bets="hasMoreBets(i)"
      />
    </div>

    <div
      class="overview-header-count"
      :class="{'visible': sportCollapsed}"
    >
      ({{matchesCount}})
    </div>
  </div>
</template>

<script>
import OverviewHeaderBet from './OverviewHeaderBet';
import OverviewHeaderSport from './OverviewHeaderSport';
import useSportBets from '@/composables/sportBets';

export default {
  name: 'OverviewHeader',
  components: {
    OverviewHeaderBet,
    OverviewHeaderSport,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    sportId: {
      type: String,
      required: false,
    },
    sportCollapsed: {
      type: Boolean,
      required: true,
      default: false,
    },
    matchesCount: {
      type: Number,
      required: true,
      default: 0,
    },
    enableSportColor: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const {
      displayedBets,
      hasMoreBets,
    } = useSportBets(props.sportId);

    return {
      displayedBets,
      hasMoreBets,
    };
  },
};
</script>
