import { onMounted, onUnmounted, ref } from 'vue';

export default function useIntersectionObserver(refElement, multipleRefs = false) {
  const isVisible = ref(false);
  const observer = ref(null);

  const options = {
    root: null,
    threshold: 0.1,
  };

  onMounted(() => {
    observer.value = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!multipleRefs) {
          isVisible.value = entry.isIntersecting;
        } else {
          isVisible.value = {
            ...isVisible.value,
            [entry.target.dataset.id]: entry.isIntersecting,
          };
        }
      });
    }, options);

    observer.value.observe(refElement.value);
  });

  onUnmounted(() => {
    observer.value.disconnect();
    observer.value = null;
  });

  return {
    observer,
    isVisible,
  };
}
