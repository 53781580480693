export default {
  compact: {
    meta: {
      caps: {
        keySet: 'capsed',
        text: '⇪ Caps Lock',
        width: 20,
      },
      space: {
        key: ' ',
        text: 'Space',
        width: 60,
        classes: 'control',
      },
      backspace: {
        func: 'backspace',
        text: '⌫',
        classes: 'control backspace',
        width: 65,
      },
      accept: {
        func: 'accept',
        text: 'OK',
        classes: 'control featured',
        width: 20,
      },
    },
    default: [
      '` 1 2 3 4 5 6 7 8 9 0 =',
      'q w e r t y u i o p ( )',
      'a s d f g h j k l ; \' {backspace}',
      '. @ z x c v b n m , -',
      '{caps} {space} {accept}',
    ],
    capsed: [
      '~ ! / # $ % ^ & * { } +',
      'Q W E R T Y U I O P [ ]',
      'A S D F G H J K L : " {backspace}',
      '< > Z X C V B N M ? _',
      '{caps} {space} {accept}',
    ],
  },
  numeric: {
    meta: {
      backspace: {
        func: 'backspace', text: '⌫', classes: 'control',
      },
      accept: {
        func: 'accept', text: 'OK', classes: 'control featured', width: '66',
      },
      clear: {
        func: 'clear', text: 'DEL', classes: 'control', width: '32',
      },
      zero: { key: '0' },
    },
    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '. {zero} {backspace}',
      '{clear} {accept}',
    ],
  },
};
