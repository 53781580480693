import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useViewScrollPosition() {
  const store = useStore();
  const overviewScrollPosition = computed(() => store.getters.overviewScrollPosition);

  const scrollToTopOfEventviewBets = () => {
    const eventViewBets = document.querySelector('.eventview-bets-list');

    if (eventViewBets) {
      eventViewBets.scrollTop = 0;
    }
  };

  const storeCurrentOverviewScrollPos = (setToTop = false) => {
    const overviewContainer = document.querySelector('.overview-content');

    if (overviewContainer) {
      const scrollPosition = setToTop ? 0 : overviewContainer.scrollTop;

      store.dispatch('updateOverviewScrollPosition', scrollPosition);
    }
  };

  const applyOverviewScrollPosition = (setToTop = false) => {
    const overviewContainer = document.querySelector('.overview-content');

    if (overviewContainer) {
      if (setToTop) store.dispatch('updateOverviewScrollPosition', 0);

      setTimeout(() => { overviewContainer.scrollTop = overviewScrollPosition.value; });
    }
  };

  return {
    scrollToTopOfEventviewBets,
    storeCurrentOverviewScrollPos,
    applyOverviewScrollPosition,
  };
}
