/* eslint-disable */
export const getRule = (name, ticketType, rules) => rules?.find(rule => {
  return parseInt(rule.name) === name && ticketType === parseInt(rule.ticketType);
});

export const getRuleValue = (rule, parseType = 'int') => {
  const value = rule?.conditions[0]?.value;

  return parseType === 'float' ? parseFloat(value) : parseInt(value);
};

export const setNotification = (id, type, content) => {
  return {
    id,
    type,
    content,
    timeout: -1,
    dismissable: false,
  };
};

export const invalidPayload = (ruleName, message, type = 'warning') => {
  return {
    valid: false,
    notification: setNotification(ruleName, type, message),
  };
};

export const validPayload = {
  valid: true,
};

export const ticketBetsCountInvalid = (bets, max) => bets >= max;

export const nonBankersBets = bets => bets.filter(bet => !bet.banker);
export const bankersBets = bets => bets.filter(bet => bet.banker);

export const getGlobalStake = (ticketType, stake, betsNumber) => {
  return ticketType === 3 ? stake / (betsNumber || 1) : stake;
};

export const systemTotalCombinations = systems => systems.reduce((acc, item) => acc + item.combinations, 0);
export const getStakePerCombination = (stake, combinations) => stake / (combinations || 1);

/**
 * @param {Array} betslipRulesNotifications
 * @param {Boolean} isBetBuilderBet
 * @returns Boolean
 */
export const validateBetAdd = (betslipRulesNotifications, isBetBuilderBet) => {
  if (_.isEmpty(betslipRulesNotifications)) return false;

  const rules = {
    12: 'MAX_BET_COUNT',
    21: 'MAX_WINNING_SINGLE',
  };

  // Adding BetBuilder-related rules to `betslipRulesNotifications`
  // (when no BetBuilder bets exist in Betslip)
  // was already prevented in UPDATE_GENERAL_BETSLIP_RULES_NOTIFICATIONS mutation.
  // We could just add rule 999 to `rules` object without checking `isBetBuilderBet`,
  // and it wouldn't be a problem, but we can leave this condition for clarity.
  if (isBetBuilderBet) rules[999] = 'MAX_BET_BUILDER_BET_COUNT';

  return _.find(betslipRulesNotifications, (el) => rules[el.id]) || false;
};
