import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default {
  init({ app, router }) {
    if (window.location.hostname === 'localhost') return;

    Sentry.init({
      app,
      dsn: 'https://45b5f1adcc014f898026c0517edff1ba@o73276.ingest.sentry.io/6020482',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [/^\//],
        }),
      ],
      tracesSampleRate: 0.0,
      release: `sports-live-terminal@${process.env.VUE_APP_VERSION}`,
    });
  },
  setCustomTags({ companyName, deviceUuid }) {
    if (window.location.hostname === 'localhost') return;

    Sentry.setTag('company.name', companyName);
    Sentry.setTag('device.uuid', deviceUuid);
  },
};
