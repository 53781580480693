import { getSingleBonus, getSingleStake } from './helper';

const calculateSingleWinnings = ({
  stake, bets, betslipConfig, bonusConfig,
}) => {
  if (!bets?.length) return {};
  const taxPolicy = betslipConfig?.taxes?.payin?.policy;
  const betsLength = bets.length ?? 0;

  let totalBonus = 0;
  let sum = 0;

  const singleStake = getSingleStake({ stake, betsLength, taxPolicy });
  const singleBonus = getSingleBonus({ bonusConfig });

  bets.forEach((bet) => {
    sum += bet.oddValue;
    if (singleBonus && bet.oddValue >= singleBonus.minOdd) {
      totalBonus += ((singleBonus.bonusPercent / 100) * bet.oddValue * singleStake);
    }
  });

  sum *= singleStake;

  return {
    max: sum + totalBonus || 0,
    bonus: totalBonus,
    bonusPercent: bets?.length ? singleBonus?.bonusPercent ?? 0 : 0,
  };
};

export default {
  calculateSingleWinnings,
};
