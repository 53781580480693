<template>
  <div
    class="eventview-bet-group"
    :class="{'selected': selectedBetGroupId === betGroup.idBetGroup}"
    @click="selectBetGroup(betGroup.idBetGroup)">
    <span>
      {{ betGroup.betGroupName || betGroup.betGroupShortName }}
    </span>
    <span
      class="bet-builder-promo-label"
      v-if="betGroup.idBetGroup === 'betBuilder'">
      {{ $translate.key('label_new') }}
    </span>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useMatchBets from '@/composables/matchBets';

export default {
  name: 'EventviewBetGroup',
  props: {
    betGroup: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup() {
    const store = useStore();
    const { selectedBetGroupId } = useMatchBets();

    function selectBetGroup(id) {
      if (selectedBetGroupId.value === id) return;
      store.dispatch('selectBetGroup', id);
    }

    return {
      selectBetGroup,
      selectedBetGroupId,
    };
  },
};
</script>
