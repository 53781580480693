<template>
  <div
    v-if="isScrolled"
    @click="scroll('toTop')"
    class="back-to-top-button icon icon-arrow-up-a">
  </div>
</template>

<script>

import { onMounted, watchEffect } from 'vue';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  props: {
    activeView: {
      type: String,
      required: true,
    },
  },
  emits: ['handleBackToTopAnim'],
  setup(props, { emit }) {
    const {
      scroll, content, isScrolled, handleNativeScroll, betslipOpen,
    } = useScrollButtons();

    onMounted(async () => {
      const scrollContent = props.activeView === 'overview' ? 'overview-content' : 'eventview-bets-list';
      content.value = document.querySelector(`.${scrollContent}`);

      if (!content.value) return;
      content.value.addEventListener('scroll', handleNativeScroll);
    });

    watchEffect(() => {
      handleNativeScroll();

      if (!isScrolled.value) emit('handleBackToTopAnim');
      else {
        if (!betslipOpen.value) return;
        emit('handleBackToTopAnim', 'move-in');
      }
    });

    return {
      isScrolled,
      scroll,
    };
  },
};
</script>
