<template>
  <div
    class="eventview-bet-outcome"
    :class="[
      oddChangedClass,
      {'odd-changed': displayOddChangeIndicator && oddChangedClass},
      {'three-in-row': outcomesLength > 2 && outcomesLength !== 4},
      {'locked': lockOutcome},
      {'selected': !!addedToTicket}]"
    @click="selectBet">
    {{ outcomeName }}
    <div class="outcome-value">
      <span v-if="displayOutcomeValue">
        {{
          outcome.mboOddValue ? $formatOdd(outcome.mboOddValue, oddType, oddFormatterActive)
                              : '-'
        }}
      </span>
      <span v-if="lockOutcome">
        <i class="icon icon-lock-a" />
      </span>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import useBetslip from '@/composables/betslip';
import useMatchBets from '@/composables/matchBets';

export default {
  name: 'EventviewBetOutcome',
  props: {
    outcome: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    outcomesLength: {
      type: Number,
      required: true,
    },
    matchBettingStatus: {
      type: Boolean,
      required: true,
    },
    idMatch: {
      type: String,
      required: true,
    },
    betName: {
      type: String,
      required: true,
    },
    idMb: {
      type: String,
      required: true,
    },
    sv: {
      type: String,
      required: false,
    },
    isBetBuilderBet: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      betBuilderBet, selectedTicketTypeId, selectTicketType, ticketBets, ticketProcessing,
    } = useBetslip();
    const { selectedBetGroupId } = useMatchBets();
    const { oddType, oddFormatterActive } = inject('oddTypeData');

    const bet = computed(() => ({
      idMatch: props.idMatch,
      idMb: props.idMb,
      idMbo: props.outcome.idMbo,
      originalOdd: props.outcome.mboOddValue,
      betName: props.betName,
      sv: props.sv && props.sv !== '*' ? props.sv : '',
      isBetBuilderBet: props.isBetBuilderBet,
    }));

    const availableBetBuilderOutcomes = computed(() => store.getters.betBuilderAvailableSelections);

    const outcomeName = computed(() => props.outcome.mboShortDisplayName
      || props.outcome.mboDisplayName
      || props.outcome.baseBetOutcomeName
      || props.outcome.mboType);

    const addedToTicket = computed(() => {
      const foundBet = _.find(ticketBets.value, { idMbo: props.outcome.idMbo });

      if (selectedBetGroupId.value === 'betBuilder' && !foundBet?.isBetBuilderBet) return false;
      if (selectedBetGroupId.value !== 'betBuilder' && foundBet?.isBetBuilderBet) return false;

      return foundBet;
    });

    const outcomeLocked = computed(() => (!props.matchBettingStatus || !props.outcome.mboActive));
    const betBuilderBetLocked = computed(() => {
      if (!props.isBetBuilderBet) return false;
      if (!availableBetBuilderOutcomes.value) return false;
      if (!ticketBets.value.length) return false;

      let found = false;

      // Using for because we need high performance here.
      for (let i = 0; i < availableBetBuilderOutcomes.value.length; i += 1) {
        if (availableBetBuilderOutcomes.value[i] === props.outcome.idMbo) {
          found = true;
          break;
        }
      }
      return !found;
    });

    const lockOutcome = computed(() => (outcomeLocked.value
      || (betBuilderBetLocked.value && !addedToTicket.value)));

    const displayOutcomeValue = computed(() => (!outcomeLocked.value
      && !props.isBetBuilderBet));

    const displayOddChangeIndicator = computed(() => (props.outcome.oddChanged
      && !outcomeLocked.value && !props.isBetBuilderBet));

    const oddChangedClass = computed(() => {
      if (props.outcome.oddChanged === 'up'
        || props.outcome.oddChanged === 'down') return `odd-${props.outcome.oddChanged}`;
      return false;
    });

    const betBuilderBetPreventAdd = () => {
      let key = '';
      const foundSameMatch = _.findIndex(ticketBets.value, { idMatch: props.idMatch }) > -1;

      if (!betBuilderBet.value && ticketBets.value.length && props.isBetBuilderBet) {
        key = 'bet_builder_error_with_regular_bet';
      }
      if (betBuilderBet.value && !props.isBetBuilderBet) {
        key = 'bet_builder_error_with_bet_builder_bet';
      }
      if (!foundSameMatch && betBuilderBet.value && props.isBetBuilderBet) {
        key = 'bet_builder_error_two_bets';
      }
      if (!key) return false;

      return store.dispatch('addNotification', {
        id: key,
        content: store.getters.translate(key),
        type: 'error',
      });
    };

    const selectBet = () => {
      if (lockOutcome.value
        || (betBuilderBetLocked.value && !selectBet)
        || ticketProcessing.value) return;

      if (betBuilderBetPreventAdd()) return;

      store.dispatch('selectBet', {
        addedToTicket: !!addedToTicket.value,
        bet: bet.value,
      });

      if (props.isBetBuilderBet) {
        if (selectedTicketTypeId.value !== 1) {
          selectTicketType(1);
        }

        store.dispatch('subscribeBetBuilderEventCalculation', {
          eventId: props.idMatch,
          outcomeId: props.outcome.idMbo,
        });
      }
    };

    return {
      addedToTicket,
      betBuilderBetLocked,
      displayOddChangeIndicator,
      displayOutcomeValue,
      lockOutcome,
      oddChangedClass,
      oddFormatterActive,
      oddType,
      outcomeName,
      selectBet,
      ticketBets,
    };
  },
};
</script>
