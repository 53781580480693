<template>
  <div
    v-if="!appSnoozed"
    class="main"
  >
    <div :class="[
      'main-content',
      { 'lmt' : isLmtRdy },
    ]">
      <Header />
      <div class="main-body">
        <keep-alive>
          <component :is="activeViewComponent" :betslip-button-visible="betslipToggleBtnVisible" />
        </keep-alive>
      </div>
    </div>
    <MatchTracker
      v-if="isLmtVisible"
      v-show="isLmtRdy"
      id="match-tracker-main"/>
    <Transition name="slide">
      <div
        v-if="!betslipToggleBtnVisible || betslipOpen"
        class="main-aside"
        :class="{ 'fixed': betslipOpen }"
      >
        <AppBetslip />
      </div>
    </Transition>

    <div class="main-notifications" v-if="notifications">
      <Notification
        class="main-notification"
        v-for="item in notifications"
        :key="item.id"
        :id="item.id"
        :content="item.content"
        :type="item.type"
        :timeout="item.timeout"
        :dismissable="item.dismissable"
        @remove-notification="removeNotification" />
    </div>

    <teleport to="#modals">
      <Modal
        v-if="generatedTickeActive"
        @close-modal="closeGeneratedTicket"
      >
        <GeneratedTicket />
      </Modal>

      <Modal
        v-if="keyboard.visible"
        :overlay="keyboard.layout === 'numeric'"
        @close-modal="hideKeyboard">
        <VirtualKeyboard />
      </Modal>
    </teleport>
    <div class="main-buttons">
      <BackToTopButton
        v-if="!scrollButtonsEnabled"
        :key="backToTopKeys"
        :class="animationsEnabled && betslipToggleable
          ? displayBackToTopAnimation
          : {'betslip-active-no-anim': betslipOpen}"
        :active-view="activeView"
        @handle-back-to-top-anim="handleBackToTopAnim" />

      <BetslipToggleButton
        v-if="betslipToggleBtnVisible"
        :class="{'active': betslipOpen }"
        :betslip-open="betslipOpen"
        @handle-back-to-top-anim="handleBackToTopAnim" />
    </div>
  </div>
</template>

<script>
import {
  computed, defineAsyncComponent, provide,
} from 'vue';
import { useStore } from 'vuex';
import Notification from '@/components/common/Notification';
import AppOverview from '@/components/overview/AppOverview';
import AppEventview from '@/components/eventview/AppEventview';
import AppBetslip from '@/components/betslip/AppBetslip';
import BetslipToggleButton from '@/components/betslip-toggle/BetslipToggleButton';
import GeneratedTicket from '@/components/generated-ticket/GeneratedTicket';
import Modal from '@/components/common/Modal';
import BackToTopButton from '@/components/common/BackToTopButton';
import MatchTracker from '@/components/match-tracker/MatchTracker';

import Header from '@/components/header/Header';
import useBreakpoints from '@/composables/breakpoints';
import useOnlineChecker from '@/composables/onlineChecker';
import { useScrollToTopAnim } from '@/composables/scrollButtons';
import useKeyboard from '@/composables/virtualKeyboard';
import useLiveMatchTracker from '@/composables/liveMatchTracker';

const VirtualKeyboard = () => import('@/components/common/virtual-keyboard/VirtualKeyboard');

export default {
  name: 'Main',
  components: {
    AppBetslip,
    BetslipToggleButton,
    Header,
    AppEventview,
    AppOverview,
    GeneratedTicket,
    Modal,
    Notification,
    VirtualKeyboard: defineAsyncComponent(VirtualKeyboard),
    BackToTopButton,
    MatchTracker,
  },
  setup() {
    const store = useStore();
    const { breakpoints } = useBreakpoints();
    const { keyboard, hideKeyboard } = useKeyboard();

    useOnlineChecker();

    const activeView = computed(() => store.getters.activeView);

    const appSnoozed = computed(() => store.getters.appSnoozed);

    const notifications = computed(() => store.getters.notifications);

    const ticketBets = computed(() => store.getters.ticketBets);

    const betslipOpen = computed(() => store.getters.betslipOpen);

    const betslipToggleable = computed(() => (
      breakpoints.value.mediumAndBellow || breakpoints.value.portraitSmall
    ));

    const betslipToggleBtnVisible = computed(() => {
      if (!betslipToggleable.value) return false;

      return betslipOpen.value || ticketBets.value.length;
    });

    const removeNotification = (id) => {
      store.dispatch('removeNotification', id);
    };

    const generatedTickeActive = computed(() => store.getters.generatedTicket.active);
    const closeGeneratedTicket = () => store.dispatch('toggleGeneratedTicket');

    const oddType = computed(() => store.getters.oddType);
    const oddFormatterActive = computed(() => store.getters.oddFormatterActive);

    const scrollButtonsEnabled = computed(() => store.getters?.scrollButtons || false);
    const backToTopKeys = computed(() => [
      activeView.value,
      store.getters.selectedMatchId,
      store.getters.isScrolled]);

    provide('oddTypeData', { oddType, oddFormatterActive });

    const {
      handleBackToTopAnim,
      displayBackToTopAnimation,
      animationsEnabled,
    } = useScrollToTopAnim();

    const selectedMatchId = computed(() => store.getters.selectedMatchId);
    const { isLmtRdy, liveMatchTrackerEnabled } = useLiveMatchTracker();
    const isLmtVisible = computed(() => (
      liveMatchTrackerEnabled.value
      && selectedMatchId.value
      && !breakpoints.value.mediumAndBellow
    ));

    const activeViewComponent = computed(() => (activeView.value === 'eventview' ? AppEventview : AppOverview));

    return {
      appSnoozed,
      activeView,
      betslipOpen,
      betslipToggleBtnVisible,
      generatedTickeActive,
      notifications,
      ticketBets,
      closeGeneratedTicket,
      removeNotification,
      keyboard,
      hideKeyboard,
      handleBackToTopAnim,
      displayBackToTopAnimation,
      animationsEnabled,
      scrollButtonsEnabled,
      backToTopKeys,
      betslipToggleable,
      isLmtVisible,
      isLmtRdy,
      liveMatchTrackerEnabled,
      selectedMatchId,
      activeViewComponent,
    };
  },
};
</script>
