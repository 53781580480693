import {
  ref, computed, watch,
} from 'vue';
import { useStore } from 'vuex';

export function useScrollButtons() {
  const store = useStore();

  const content = ref();
  const hasScrollableContent = ref(false);
  const isScrolled = computed(() => store.getters?.isScrolled || false);
  const scrollButtonsEnabled = computed(() => store.getters?.scrollButtons || false);
  const betslipOpen = computed(() => store.getters.betslipOpen);

  let disableScroll = false;
  const setScrollPos = (pos) => new Promise((resolve) => {
    let scrollListener;
    const scrollStatic = () => {
      content.value.removeEventListener('scroll', scrollListener);
      disableScroll = false;
      resolve();
    };

    let timeoutId;
    scrollListener = () => {
      disableScroll = true;
      clearTimeout(timeoutId);

      if (content.value.scrollTop === pos) scrollStatic();
      else {
        timeoutId = setTimeout(scrollStatic, 100);
      }
    };

    content.value.addEventListener('scroll', scrollListener);
    content.value.scrollTo({
      top: pos ?? 0,
      behavior: 'smooth',
    });
  });

  const scroll = (type) => {
    if (!content.value || disableScroll) return;

    const { clientHeight, scrollTop } = content.value;
    const positionCalc = Math.floor(0.75 * clientHeight);

    if (type === 'toTop') setScrollPos(0);
    if (type === 'up') setScrollPos(scrollTop - positionCalc);
    if (type === 'down') setScrollPos(scrollTop + positionCalc);
  };

  const isContentScrolled = ref(false);
  const handleNativeScroll = () => {
    isContentScrolled.value = !!content.value?.scrollTop || false;
  };

  const backToTopKeys = computed(() => [
    store.getters.selectedMatchId,
    store.getters.activeView,
    store.getters.matchBetSearchQuery,
  ]);

  watch([isContentScrolled, backToTopKeys, content], () => {
    store.dispatch('setIsContentScrolled', isContentScrolled.value);
  });

  return {
    content,
    scroll,
    hasScrollableContent,
    scrollButtonsEnabled,
    handleNativeScroll,
    isScrolled,
    betslipOpen,
  };
}

export function useScrollToTopAnim() {
  const store = useStore();
  const animationsEnabled = computed(() => store.getters?.config?.enableAnimations || false);

  const displayBackToTopAnimation = ref('');
  const handleBackToTopAnim = (anim = '') => {
    if (!anim || !animationsEnabled.value) {
      displayBackToTopAnimation.value = '';
      return;
    }

    displayBackToTopAnimation.value = anim;
  };

  const ticketBetsLength = computed(() => !!store.getters.ticketBets?.length);
  watch(ticketBetsLength, () => {
    if (!animationsEnabled.value) return;

    if (!ticketBetsLength.value) {
      displayBackToTopAnimation.value = 'move-out';
    }
  });

  return {
    animationsEnabled,
    displayBackToTopAnimation,
    handleBackToTopAnim,
  };
}
