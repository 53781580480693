export default {
  default: {
    srWidgets: {
      clientId: '326ae239e3a0fb8b9034cb6ee2ed9535',
      silent: true,
      liveMatchTracker: {
        enabled: false,
        options: {
          goalBannerImage: false,
        },
      },
      betAssist: {
        enabled: false,
      },
    },
    payinBtnVisible: true,
    betBuilderEnabled: false,
    enableSportColor: false,
    enableAnimations: true,
    generateCodeEnabled: true,
    scrollButtons: false,
    stake: '1.00',
    ticketTypes: [
      {
        id: 3,
        name: 'Single',
        label: 'single',
        priority: 1,
        hasStake: true,
        active: true,
      },
      {
        id: 1,
        name: 'Combo',
        label: 'combo',
        priority: 2,
        totalOdds: true,
        active: true,
      },
      {
        id: 2,
        name: 'System',
        label: 'system',
        priority: 3,
        system: true,
        active: true,
      },
    ],
  },
  balkanbet: {
    dcUrl: '-rs',
  },
};
