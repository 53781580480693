<template>
  <div
  :class="['notification',
  `notification-${type}`,
  size]">
    <i class="icon" :class="['notification-icon', 'icon', notificationIcon(type)]" />
    <div class="notification-text">
      {{ content || $translate.key('no_message') }}
    </div>
    <i
      v-if="dismissable"
      class="notification-close icon-close icon"
      @click="removeNotification(id)" />
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Notification',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'warning',
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    size: {
      type: String,
    },
  },
  setup(props, context) {
    let timeout;

    const notificationIcon = (type) => {
      if (type === 'success') return 'icon-check';
      if (type === 'error') return 'icon-close-o';
      if (type === 'warning') return 'icon-info';
      return 'icon-info';
    };

    const removeNotification = (id) => {
      context.emit('removeNotification', id);
      clearTimeout(timeout);
    };

    onMounted(() => {
      const timeoutValue = props.timeout;
      if (timeoutValue !== -1) {
        timeout = setTimeout(() => {
          removeNotification(props.id);
        }, timeoutValue);
      }
    });

    return {
      removeNotification,
      notificationIcon,
    };
  },
};
</script>

<style>
</style>
