import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useSports() {
  const store = useStore();

  const setActiveSport = (id) => {
    store.dispatch('setActiveSport', id);
  };

  const enableSportColor = computed(() => store.getters.config?.enableSportColor);

  return {
    enableSportColor,
    setActiveSport,
  };
}
