import Gateway from '@nsftx/seven-gravity-gateway';
import queryParser from '@/utils/queryParser';
import sentry from '@/services/sentry';

let gateway;
let storeDispatch = null;
const serverEnv = process.env.SERVER;
const languageMapper = { sr: 'sr-Latn' }; // Hack for translations to work properly

const appLoad = ({ data }) => {
  gateway.sendMessage({
    action: 'Slave.Loaded',
    data: {},
  });

  const {
    user, device, betshop, company, product, terminal, settings, config, betslip,
  } = data;

  const { cpvUuid } = product;
  const language = languageMapper[data.locale.iso1] || data.locale.iso1;
  const encoding = queryParser.getUrlParameter('encoding') || 'deflate';
  const companyName = queryParser.getUrlParameter('company') || '';
  const companyUuid = company.uuid;

  const token = `${user.uuid}:${user.auth.password}`;
  const authorizationToken = window.btoa(token);

  sentry.setCustomTags({ companyName: company.name, deviceUuid: device.uuid });

  storeDispatch('setBetslipConfig', {
    cpvUuid, companyUuid, language, rules: config.rules,
  });
  storeDispatch('setUserAuthorization', { userType: user.type });
  storeDispatch('setAppSettings', {
    uuid: companyUuid,
    companyName,
  }).then(() => {
    storeDispatch('handleSIRConnect');
    storeDispatch('initBetBuilderSocket', {
      companyUuid: company.uuid,
      feedId: config.feedId,
      language,
    });
    storeDispatch('setBetBuilderBetslipRules');
  });

  storeDispatch('initSocket', {
    cpvUuid, encoding, language, deviceUuid: device.deviceUuid,
  });
  storeDispatch('getTranslations', { language, companyUuid });
  storeDispatch('setDevice', device);
  storeDispatch('setCpvUuid', cpvUuid);
  storeDispatch('setProduct', product);
  storeDispatch('setBetshop', betshop);
  storeDispatch('setCompany', company);
  storeDispatch('setTerminal', terminal);
  storeDispatch('setLanguage', language);
  storeDispatch('setOddType', settings.oddType);
  storeDispatch('setMoneyDenominations', settings.moneyDenominations);
  storeDispatch('setAuthorizationToken', authorizationToken);
  storeDispatch('setWorkingTimeBlocker', betslip.blockers);
};

const setListeners = () => {
  gateway.on('User.BalanceChanged', (data) => storeDispatch('setBalance', data));
  gateway.on('Slave.Shown', (data) => console.log('Slave.Shown', data));
  gateway.on('Slave.Awake', () => storeDispatch('awakeApp'));
  gateway.on('Slave.Snooze', () => storeDispatch('setDefaultState'));
  gateway.on('Widget.SettingsChanged', ({ data }) => storeDispatch('setOddType', data.oddType));
  gateway.on('Ticket.CheckWebCode', ({ data }) => storeDispatch('checkWebCode', data.code));
  gateway.on('Betslip.Blocked', ({ data: { blockers } }) => storeDispatch('setWorkingTimeBlocker', blockers));
  gateway.on('Betslip.Unblocked', ({ data: { blockers } }) => storeDispatch('removeWorkingTimeBlocker', blockers));
  gateway.on('UI.Show', (data) => storeDispatch('handleUIShowEvent', data));
  gateway.on('Tickets.Checked', (data) => storeDispatch('setTicketCheck', data));
  gateway.on('User.AuthorizationChanged', (data) => storeDispatch('setUserAuthorization', data.data));
};

export default {
  instance({ dispatch }) {
    storeDispatch = dispatch;
  },
  init() {
    const gatewayConstructor = Gateway.slave;
    gateway = gatewayConstructor({
      debug: serverEnv !== 'production',
      allowedOrigins: '*',
      slaveId: 'LiveBetting',
      data: {
        title: 'Live terminal',
      },
      eventPropagation: {
        click: true,
        keydown: '*',
      },
      load: appLoad,
    });

    setListeners();
  },
  sendMessage(action, data, enforceEvent = false) {
    const gatewayDataConstruct = { action, data };

    if (enforceEvent) gatewayDataConstruct.enforceEvent = true;

    if (gateway) gateway.sendMessage(gatewayDataConstruct);
  },
  emitAsync(action, data) {
    const gatewayDataConstruct = { action, data };

    return gateway.emitAsync(gatewayDataConstruct);
  },
};
