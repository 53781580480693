<template>
  <div class="generated-ticket-footer">
    <div class="generated-ticket-footer-code">
      <span class="generated-ticket-footer-code-text">{{ $translate.key('generated_code') }}</span>
      <span class="generated-ticket-footer-code-value">{{ generatedCode }}</span>
    </div>

    <!-- HANDLE PRINT LATER -->
    <div
      class="generated-ticket-footer-print"
      @click="printCode"
    >
      {{ $translate.key('print') }}
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
  name: 'GeneratedTicketFooter',
  props: {
    generatedCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const printCode = () => {
      store.dispatch('printGeneratedCode', props.generatedCode);
    };

    return {
      printCode,
    };
  },
};
</script>
