<template>
  <div class="ticket-check-body"> <!-- Ticket bets content -->
    <table>
      <thead>
        <tr>
          <th colspan="1"></th>
          <th colspan="2">{{ $translate.key('general_time') }}</th>
          <th colspan="4">{{ $translate.key('match') }}</th>
          <th colspan="2">{{ $translate.key('bet_name') }}</th>
          <th colspan="1">{{ $translate.key('general_odd') }}</th>
          <th colspan="1">{{ $translate.key('general_pick') }}</th>
          <th colspan="1">{{ $translate.key('result') }}</th>
          <th colspan="1">{{ $translate.key('status') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(bet, index) in ticket.bets"
          :key="`body-row-${index}`">
          <td colspan="1">
            <em :class="['icon', `icon-sport-${bet.idSport}`]"></em>
          </td>
          <td colspan="2" class="highlight">{{ formatDate(bet.matchDateTimeUTC) }}</td>
          <td colspan="4">{{ bet.matchName }}</td>
          <td colspan="2">
            <span
              class="banker-sign"
              v-if="bet.ticketBetBank === '1' && ticket.ticketType.id === 2">
              {{ $translate.key('banker_symbol') }}
            </span>
            <span>{{ formatBetName(bet) }}</span>
          </td>
          <td colspan="1">{{ bet.oddValue }}</td>
          <td colspan="1" v-if="isBetBuilderBet">-</td>
          <td colspan="1" v-else>{{ getOutcomeName(bet) }}</td>
          <td colspan="1">{{ getResult(bet.results) }}</td>
          <td colspan="1">
            <em :class="[
              'icon',
              `icon-${formatStatus(bet.combineStatus)}`,
              getStatusColor(bet.combineStatus),
            ]"></em>
          </td>
        </tr>
        <template v-if="isBetBuilderBet">
          <tr
            v-for="(bet, index) in betBuilderBets"
            :key="`body-row-selection-${index}`">
            <td colspan="1"></td>
            <td colspan="2"></td>
            <td colspan="4"></td>
            <td colspan="2">{{ formatBetName(bet)}}</td>
            <td colspan="1"></td>
            <td colspan="1">{{ getOutcomeName(bet) }}</td>
            <td colspan="1"></td>
            <td colspan="1">
              <em :class="[
                'icon',
                `icon-${formatStatus(bet.combineStatus)}`,
                getStatusColor(bet.combineStatus),
              ]"></em>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div v-if="ticket.ticketCombinationGroups?.length && ticket.type === '2'">
      <table>
        <thead>
          <tr>
            <th>{{ $translate.key('system') }}</th>
            <th>{{ $translate.key('combinations') }}</th>
            <th>{{ $translate.key('general_stake') }}</th>
            <th>{{ $translate.key('min_winning') }}</th>
            <th>{{ $translate.key('max_winning') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(system, index) in ticket.ticketCombinationGroups"
            :key="`systems-row-${index}`">
            <td>{{ system.displayType }}</td>
            <td>{{ system.numberOfCombinations }}</td>
            <td>{{ system.amount.toFixed(2) }}</td>
            <td>{{ system.minGain.toFixed(2) }}</td>
            <td>{{ system.maxGain.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import statusMapper from '@/utils/statusMapper';
import formatter from '@/services/formatter';
import useStatusFormatter from '@/composables/statusFormatter';

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { getStatusColor } = useStatusFormatter();
    const getResult = (results) => _.find(results, { providerResultId: 'FT' })?.matchResultValue || '-';

    const getOutcomeName = (bet) => {
      const outcomeName = bet.betOutcome.betOutcomeDisplayName;
      const displaySpecialValue = bet.bet.betType === 'PLAYER_PROPS_AT_LEAST';
      const specialValue = displaySpecialValue ? ` (${bet.specialValue})` : '';
      return `${outcomeName}${specialValue}`;
    };

    const isBetBuilderBet = computed(() => props.ticket?.bets[0]?.type === 'CUSTOM_BET');

    const betBuilderBets = computed(() => {
      if (!isBetBuilderBet.value) return [];
      return props.ticket.bets[0].selections;
    });

    const formatDate = (date) => format(date, 'yyyy-MM-dd kk:mm');

    const formatStatus = (status) => statusMapper[status];

    const formatBetName = (bet) => {
      if (bet.specialValue && bet.specialValue !== '*') {
        return formatter.formatBetName({
          betName: bet.bet.betDisplayName,
          mbSpecialValue: bet.specialValue,
          grouped: bet.bet.betGroupingEnabled,
        });
      }
      return bet.bet.betDisplayName;
    };

    return {
      formatBetName,
      getOutcomeName,
      getResult,
      isBetBuilderBet,
      betBuilderBets,
      formatDate,
      formatStatus,
      getStatusColor,
    };
  },
};
</script>
