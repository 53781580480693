import { computed, nextTick, watch } from 'vue';
import { useStore } from 'vuex';
import useMatches from '@/composables/matches';
import useSports from '@/composables/sports';

const scrollTopOffset = {
  overviewContent: 137,
  eventviewSidebar: 64,
};

const scrollToMatch = async (position, matchId, parentRef, animate) => {
  await nextTick();

  const matchElement = document.querySelector(`.${parentRef.value.classList[0]} #match-${matchId}`);

  if (!matchElement) return;

  parentRef.value.scrollTo({
    top: matchElement.offsetTop - scrollTopOffset[position],
    behavior: animate ? 'smooth' : 'auto',
  });
};

export function useMatchFocus() {
  const store = useStore();
  const focusedMatch = computed(() => store.getters.focusedMatch || {});
  const focusedMatchSportId = computed(() => store.getters.focusedMatchSportId);
  const animationsEnabled = computed(() => (
    store.getters.config?.enableAnimations));

  return {
    focusedMatch,
    focusedMatchSportId,
    animationsEnabled,
  };
}

export function useOverviewMatchFocus() {
  const { focusedMatch, focusedMatchSportId, animationsEnabled } = useMatchFocus();
  const { selectedSport } = useMatches();
  const { setActiveSport } = useSports();

  const initFocusedMatchWatcher = (parentRef) => {
    watch(
      () => focusedMatch.value,
      ({ idMatch, animationPrevented }) => {
        if (!idMatch) return;

        if (selectedSport.value !== focusedMatchSportId.value.idSport
          && selectedSport.value !== 'all') {
          setActiveSport(focusedMatchSportId.value.idSport);
        }

        scrollToMatch(
          'overviewContent',
          idMatch,
          parentRef,
          animationPrevented && !!animationsEnabled.value,
        );
      },
    );
  };

  return {
    focusedMatch,
    initFocusedMatchWatcher,
  };
}

export function useEventviewMatchFocus() {
  const { focusedMatch, animationsEnabled } = useMatchFocus();

  const initFocusedMatchWatcher = (parentRef) => {
    watch(
      () => focusedMatch.value,
      ({ idMatch, animationPrevented }) => {
        if (!idMatch) return;
        scrollToMatch(
          'eventviewSidebar',
          idMatch,
          parentRef,
          animationPrevented && !!animationsEnabled.value,
        );
      },
    );
  };

  return {
    initFocusedMatchWatcher,
  };
}

export function useMatchSportFocus(sportId) {
  const { focusedMatchSportId } = useMatchFocus();

  const isSportFocused = computed(() => focusedMatchSportId.value.idSport === sportId);

  const initFocusedSportWatcher = (expandSport) => {
    watch(
      () => isSportFocused.value,
      (val) => {
        if (val) {
          expandSport();
        }
      },
    );
  };

  return {
    initFocusedSportWatcher,
    isSportFocused,
  };
}
