<template>
  <div
    :class="['sidebar-row', { selected }]"
    @click="setActiveSport(id)">
    <i
      class="sidebar-row-icon"
      :class="['icon', iconClass]" />
    <div class="sidebar-row-label">{{ label }}</div>
    <div class="sidebar-row-value">{{ value }}</div>
  </div>
</template>

<script>
import useSports from '@/composables/sports';

export default {
  name: 'OverviewSidebarRow',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    iconClass: {
      type: String,
      required: false,
      default: 'icon-specials',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const { setActiveSport } = useSports();

    return {
      setActiveSport,
    };
  },
};
</script>
