<template>
  <TicketCheck />
</template>

<script>
import TicketCheck from '@/components/ticket-check/TicketCheck';

export default {
  components: { TicketCheck },
};

</script>
