<template>
  <div
    class="eventview-sidebar-match"
    ref="sidebarMatch"
    :data-id="match.idMatch"
    :id="`match-${match.idMatch}`">
    <template v-if="isVisible">
      <div
        class="match-locked-overlay"
        v-if="isMatchLocked">
        <i class="icon icon-lock-a" />
      </div>
      <div
        class="eventview-sidebar-match-row"
        :class="{'selected': isMatchSelected,
                'locked': isMatchLocked}"
        @click="selectMatch(match.idMatch, true);">
        <div class="eventview-sidebar-match-details">
          <div class="eventview-sidebar-match-header">
            <div class="match-time">
              <span
                class="period-time"
                v-if="timeStatus">
                {{ timeStatus }}
              </span>
              <span
                class="time-separator"
                v-if="timeStatus && remainingTime">
                |
              </span>
              <span
                class="remaining-time"
                v-if="remainingTime">
                {{ remainingTime }}
              </span>
            </div>
          </div>
          <div class="eventview-sidebar-match-details-body">
            <div class="eventview-sidebar-match-teams">
              <div
                class="team"
                v-for="team in teams"
                :key="team.id">
                <div
                  class="team-name"
                  :class="{'short': team.redCards}">
                  {{ team.shortName || team.name }}
                </div>
                <i
                  class="icon icon-card card"
                  v-for="(redCard, index) in team.redCards"
                  :key="index"
                />
              </div>
            </div>
            <div class="eventview-sidebar-match-results"
                 :class="{'reversed': reverseResult, 'teams-have-server': teamsHaveServer}">
              <div
                v-if="isTennis && score.team1 && score.team2"
                class="result">
                <div>{{score.team1}}</div>
                <div>{{score.team2}}</div>
              </div>
              <div
                class="result"
                v-for="(result, index) in resultsBySport(match.idSport)"
                :key="index">
                <div>{{ result.team1 }}</div>
                <div>{{ result.team2 }}</div>
              </div>
            </div>
            <ServerIcons
              v-if="teamsHaveServer"
              :teams="teams"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';
import useMatch from '@/composables/match';
import matchDetailsMixin from '@/mixins/matchDetails';
import matchResultsMixin from '@/mixins/matchResults';
import ServerIcons from '@/components/common/ServerIcons';

export default {
  name: 'EventviewSidebarMatch',
  components: {
    ServerIcons,
  },
  mixins: [matchDetailsMixin, matchResultsMixin],
  props: {
    match: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { selectedMatchId, selectMatch } = useMatch();
    const sportsWithReversedResults = ['37', '34', '31', '23', '20', '19'];

    const isMatchLocked = computed(() => !props.match.bettingStatus);
    const isMatchSelected = computed(() => selectedMatchId.value === props.match.idMatch);

    const isTennis = computed(() => props.match.idSport === '5');
    const reverseResult = computed(() => sportsWithReversedResults.includes(props.match.idSport));

    return {
      isMatchLocked,
      isMatchSelected,
      isTennis,
      reverseResult,
      selectMatch,
    };
  },
};
</script>
