<template>
  <div class="betslip-modal">
    <div class="betslip-modal-header">
      <div class="betslip-modal-title">
        {{ $translate.key('set_combinations') }}
      </div>

      <i
        class="icon icon-close"
        data-test="close-combinations"
        @click="closeBetslipModal"
      />
    </div>

    <div class="betslip-modal-body scrollbar">
      <div class="betslip-modal-meta">
        <div class="betslip-modal-meta-item">
          <div class="betslip-modal-meta-item-label">
            {{ $translate.key('total_combinations') }}
          </div>

          <div class="betslip-modal-meta-item-value">{{ totalCombinations }}</div>
        </div>

        <div class="betslip-modal-meta-item right">
          <div class="betslip-modal-meta-item-label">
            {{ $translate.key('min_winning') }}
          </div>

          <div class="betslip-modal-meta-item-value">{{ winnings.min.toFixed(2) }}</div>
        </div>

        <div class="betslip-modal-meta-item right">
          <div class="betslip-modal-meta-item-label">
            {{ $translate.key('max_winning') }}
          </div>

          <div class="betslip-modal-meta-item-value">{{ winnings.max.toFixed(2) }}</div>
        </div>
      </div>

      <div class="betslip-modal-systems">
        <div
          v-for="system in systemsCopy"
          :key="system.id"
          class="betslip-modal-system"
          @click="selectTicketSystem(system.id)"
        >
          <i
            class="betslip-modal-system-checkbox"
            :class="[
              {'icon icon-checkbox': !system.selected},
              {'icon icon-checkbox-c selected': system.selected}]"
          />

          <div class="betslip-modal-system-label">
            <span v-if="bankersNumber">
              {{ `${bankersNumber}${$translate.key('banker_symbol')} + ` }}
            </span>
            <span>{{`${system.id}/${system.of}`}}</span>
          </div>

          <div class="betslip-modal-system-combinations">{{ system.combinations }}</div>
        </div>
      </div>
    </div>

    <div class="betslip-modal-footer">
      <ScrollButtons
        v-if="scrollButtonsEnabled"
        :key="systemsCopy"
        scroll-content="betslip-modal-body"
        :has-scroll-to-top="true" />
      <div
        class="betslip-modal-save-button btn"
        @click="closeBetslipModal"
      >
        {{ $translate.key('general_close') }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import ScrollButtons from '@/components/common/ScrollButtons';
import { useScrollButtons } from '@/composables/scrollButtons';

export default {
  name: 'BetslipCombinations',
  components: {
    ScrollButtons,
  },
  props: {
    bankersNumber: {
      type: Number,
      required: true,
      default: 0,
    },
    totalCombinations: {
      type: Number,
      required: true,
      default: 0,
    },
    systems: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    winnings: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const { scrollButtonsEnabled } = useScrollButtons();

    const systemsCopy = ref(_.cloneDeep(props.systems));

    const closeBetslipModal = () => emit('closeBetslipModal');

    const selectTicketSystem = (systemId) => {
      const foundSystemIndex = _.findIndex(systemsCopy.value, { id: systemId });
      systemsCopy.value[foundSystemIndex].selected = !systemsCopy.value[foundSystemIndex].selected;
      store.dispatch('saveSystemCombinations', systemsCopy.value);
    };

    return {
      systemsCopy,
      closeBetslipModal,
      selectTicketSystem,
      scrollButtonsEnabled,
    };
  },
};
</script>
