export default {
  computed: {
    category() {
      return this.$store.getters.matchCategory(this.match.idCategory);
    },
    categoryName() {
      return this.category.categoryName || this.category.categoryShortName;
    },
    categoryIsoName() {
      return this.category.categoryIsoName;
    },
    tournamentName() {
      const tournament = this.$store.getters.matchTournament(this.match.idTournament);
      return tournament.tournamentName || tournament.tournamentShortName;
    },
    teams() {
      return [this.generateTeam(1), this.generateTeam(2)];
    },
    teamsHaveServer() {
      if (!this.teams.length) return false;
      return this.teams[0].isServer || this.teams[1].isServer;
    },
    periodShort() {
      return this.currentMatchStatus?.statusDetails?.translation;
    },
    periodTime() {
      if (!this.currentMatchStatus?.matchtime) return this.periodShort;
      return `${this.currentMatchStatus.matchtime}${this.extendedTime ? '' : "'"}`;
    },
    timeStatus() {
      return this.currentMatchStatus?.periodRemainingTime
        ? this.periodShort
        : this.periodTime;
    },
    extendedTime() {
      if (!this.currentMatchStatus?.matchtimeExtended) return undefined;

      const extendedTime = this.currentMatchStatus.matchtimeExtended.split('+')[1];

      return extendedTime ? `+${extendedTime}'` : undefined;
    },
    remainingTime() {
      return this.currentMatchStatus?.periodRemainingTime || this.extendedTime;
    },
    currentMatchStatus() {
      return this.match.currentStatus;
    },
    score() {
      const score = this.currentMatchStatus?.score || '-:-';
      return {
        team1: score.split(':')[0],
        team2: score.split(':')[1],
      };
    },
    cricketResult() {
      const dismissals = _.has(this.currentMatchStatus, 'htDismissals')
        ? [this.currentMatchStatus.htDismissals, this.currentMatchStatus.atDismissals]
        : [];
      const result = this.currentMatchStatus.score.split(':')
        .map((item, i) => (_.isNumber(dismissals[i]) ? `${item}/${dismissals[i]}` : item))
        .join(':');
      return {
        team1: result.split(':')[0],
        team2: result.split(':')[1],
      };
    },
    sportName() {
      const mappedNames = {
        1: 'soccer',
        5: 'tennis',
      };

      return mappedNames[this.match.idSport] || '';
    },
  },
  methods: {
    generateTeam(num) {
      const id = this.match[`idTeam${num}`];
      const teamName = this.match[`team${num}Name`] || this.match[`team${num}ShortName`] || '';
      const teamShortName = this.match[`team${num}ShortName`] || this.match[`team${num}Name`] || '';
      const name = this.match.tournamentPrefix + teamName;
      const shortName = this.match.tournamentPrefix + teamShortName;
      const server = this.currentMatchStatus ? this.currentMatchStatus.server : this.match.server;
      const isServer = server === num;
      const redCards = this.currentMatchStatus ? this.currentMatchStatus[`redCardsTotal${num}`] : 0;

      return {
        id,
        name,
        shortName,
        isServer,
        redCards,
      };
    },
  },
};
