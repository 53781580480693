<template>
  <div class="betslip-header">
    <template
      v-for="type in ticketTypes"
      :key="type.id"
    >
      <div
        v-if="type.active"
        class="betslip-header-ticket-type"
        :class="{ 'selected': type.id === selectedTicketTypeId }"
        @click="selectTicketType(type.id)"
      >
        {{ $translate.key(type.label) }}

        <div
          v-if="type.id === selectedTicketTypeId && betsCount"
          class="betslip-header-ticket-type-notification"
        >
          {{ betsCount }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import useBetslip from '@/composables/betslip';

export default {
  name: 'BetslipHeader',
  props: {
    betsCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  setup() {
    const { ticketTypes, selectedTicketTypeId, selectTicketType } = useBetslip();

    return {
      ticketTypes,
      selectedTicketTypeId,
      selectTicketType,
    };
  },
};
</script>
