import _ from '@/services/lodash';

export default {
  appSnoozed: (state) => state.appSnoozed,
  activeTicket: (state) => state.activeTicket,
  activeView: (state) => state.activeView,
  appOnline: (state) => state.appOnline,
  authorizationToken: (state) => state.authorizationToken,
  autoCheckerData: (state) => state.autoCheckerData,
  bankers: (state) => _.filter(state.ticketBets, { banker: true }),
  betBuilderAvailableSelections: (state) => state.betBuilderAvailableSelections,
  betBuilderBetslipRulesValues: (state) => state.betBuilderBetslipRulesValues,
  betBuilderEventCalculation: (state) => state.betBuilderEventCalculation,
  betBuilderEventSelection: (state) => state.betBuilderEventSelection,
  betBuilderSelectedOutcomes: (state) => state.betBuilderSelectedOutcomes,
  betGroups: (state) => state.betGroups,
  betshop: (state) => state.betshop,
  betAssistPopupId: (state) => state.betAssistPopupId,
  betsBySport: (state) => (sportId) => state.sportsBets[sportId] || {},
  betslipConfig: (state) => state.betslipConfig,
  betslipGeneralNotifications: (state) => state.betslipGeneralNotifications,
  betslipNotifications: (state) => [
    ...state.betslipGeneralNotifications,
    ...state.betslipRulesNotifications,
  ],
  betslipOddsOrStatusChanged: (state) => state.betslipOddsOrStatusChanged,
  betslipRulesNotifications: (state) => state.betslipRulesNotifications,
  betslipOpen: (state) => state.betslipOpen,
  workingTimeBlocker: (state) => state.workingTimeBlocker,
  bonusConfig: (state) => state.bonusConfig,
  categories: (state) => state.categories,
  checkedTicket: (state) => state.checkedTicket,
  company: (state) => state.company,
  config: (state) => state.config,
  cpvUuid: (state) => state.cpvUuid,
  device: (state) => state.device,
  displayedBetsBySport: (state) => (sportId) => state.sportDisplayedBets[sportId] || [],
  focusedMatch: (state) => state.focusedMatch,
  focusedMatchSportId: (state) => state.focusedMatchSportId,
  generateCodeEnabled: (state) => state.config?.generateCodeEnabled,
  generatedTicket: (state) => state.generatedTicket,
  gridViews: (state) => state.gridViews,
  headersSettings: (state) => state.headersSettings,
  language: (state) => state.language,
  lastTickets: (state) => state.lastTickets,
  lastTicketsLoading: (state) => state.lastTicketsLoading,
  liveMatchTracker: (state) => state.liveMatchTracker ?? {},
  liveMatchTrackerEnabled: (state) => state.liveMatchTracker?.enabled ?? false,
  liveMatchTrackerActive: (state) => state.liveMatchTrackerActive,
  liveMatchTrackerError: (state) => state.liveMatchTrackerError,
  matches: (state) => state.matches,
  matchesBySport: (state) => (sportId) => state.matchesBySport[sportId] || [],
  matchesOrder: (state) => state.matchesOrder,
  matchBetSearchQuery: (state) => state.matchBetSearchQuery.toLowerCase(),
  matchCategory: (state) => (id) => state.categories[id] || {},
  matchNameSearchQuery: (state) => state.matchNameSearchQuery.toLowerCase(),
  matchTournament: (state) => (id) => state.tournaments[id] || {},
  moneyDenominations: (state) => state.moneyDenominations,
  nonBankers: (state) => _.filter(state.ticketBets, (bet) => !bet.banker),
  oddFormatterActive: (state) => state.oddFormatterActive,
  oddType: (state) => state.oddType,
  overviewScrollPosition: (state) => state.overviewScrollPosition,
  pendingTicketRequestUuid: (state) => state.pendingTicketRequestUuid,
  product: (state) => state.product,
  scrollButtons: (state) => state.config?.scrollButtons,
  selectedBet: (state) => state.selectedBet,
  selectedBetGroupId: (state) => state.selectedBetGroupId,
  selectedMatchId: (state) => state.selectedMatchId,
  selectedSport: (state) => state.selectedSport,
  selectedTicketSystems: (state) => _.filter(state.ticketSystems, { selected: true }),
  selectedTicketTypeId: (state) => state.selectedTicketTypeId,
  serverServiceTimeout: (state) => state.serverServiceTimeout,
  serverTimeoutId: (state) => state.serverTimeoutId,
  sevenTaxes: (state) => state.sevenTaxes,
  sports: (state) => state.sports,
  sportsWithMatches: (state) => _.orderBy(state.sportsWithMatches, ['sportPosition', 'sportShortName', 'sportName']),
  stake: (state) => state.stake,
  terminal: (state) => state.terminal,
  ticketBets: (state) => state.ticketBets,
  ticketProcessing: (state) => state.ticketProcessing,
  ticketSystems: (state) => state.ticketSystems,
  ticketTypes: (state) => state.config?.ticketTypes,
  translate: (state) => (key) => state.translations[key] || key,
  translations: (state) => state.translations,
  tournaments: (state) => state.tournaments,
  notifications: (state) => state.notifications,
  payinBtnVisible: (state) => state.config?.payinBtnVisible,
  isScrolled: (state) => state.isScrolled,
  resetTrigger: (state) => state.resetTrigger,
  operatorLoggedIn: (state) => state.operatorLoggedIn,
  companyName: (state) => state.companyName,
  matchesBets: (state) => state.matchesBets,
};
