export const round = (number, d) => {
  let decimals = d;

  if (!decimals) {
    decimals = 0;
  }

  return Math.round((number * (10 ** decimals)) / (10 ** decimals));
};

export const greatestDivisor = (a, b) => {
  if (!b) {
    return a;
  }
  return greatestDivisor(b, a % b);
};

export const toAmerican = (odd) => {
  const multiplier = 100;
  let value = odd;

  if (value <= 1) {
    value = '+0';
  }

  if (value >= 2) {
    value = `+${round(((value - 1) * multiplier), 0)}`;
  } else {
    value = `-${Math.round(Math.abs(multiplier / (value - 1)))}`;
  }

  return value;
};

export const toFractional = (odd) => {
  let value = odd;

  const odds = [
    1.01, 1.02, 1.0303, 1.04, 1.1, 1.125, 1.143, 1.167, 1.2, 1.222, 1.25,
    1.286, 1.3, 1.333, 1.364, 1.4, 1.444, 1.5, 1.533, 1.571, 1.615, 1.667,
    1.727, 1.8, 1.833, 1.909, 2.0, 2.1, 2.2, 2.25, 2.3, 2.375, 2.5, 2.625,
    2.75, 3.0, 3.2, 3.25, 3.4, 3.5, 3.75, 4.0, 4.333, 4.5, 5.0, 5.5, 6.0,
    6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 11.0, 12.0, 13.0, 15.0, 17.0,
    21.0, 26.0, 34.0, 41.0, 51.0, 67.0, 101.0,
  ];

  const oddsMap = {
    '303/10000': '1/33',
    '143/1000': '1/7',
    '167/1000': '1/6',
    '111/500': '2/9',
    '143/500': '2/7',
    '333/1000': '1/3',
    '91/250': '4/11',
    '111/250': '4/9',
    '533/1000': '8/15',
    '571/1000': '4/7',
    '123/200': '8/13',
    '667/1000': '4/6',
    '727/1000': '8/11',
    '833/1000': '5/6',
    '909/1000': '10/11',
    '3333/1000': '10/3',
  };

  const closest = odds.reduce((a, b) => (Math.abs(b - value) < Math.abs(a - value) ? b : a));

  value = Math.abs(closest - value) < 1 ? closest : value;

  const valueString = value.toString();
  const point = valueString.indexOf('.');
  const decimals = (point > -1) ? valueString.substring(point + 1).length : 1;
  const multiplier = (10 ** decimals);
  const number = round(((value - 1) * multiplier), decimals);
  const divisor = greatestDivisor(number, multiplier);

  value = `${(number / divisor)}/${(multiplier / divisor)}`;
  return oddsMap[value] ? oddsMap[value] : value;
};
