const updateMatchDetails = (oldMatch, newMatch) => {
  if (!oldMatch.currentStatus) _.assign(oldMatch, { currentStatus: {} });
  if (!newMatch.statusDetails) _.assign(newMatch, { statusDetails: {} });
  _.assign(oldMatch.currentStatus, {
    active: newMatch.active,
    activeBets: newMatch.activeBets,
    atDismissals: newMatch.atDismissals,
    betStatus: newMatch.betStatus,
    clockStopped: newMatch.clockStopped,
    htDismissals: newMatch.htDismissals,
    matchtime: newMatch.matchtime,
    matchtimeExtended: newMatch.matchtimeExtended,
    periodRemainingTime: newMatch.periodRemainingTime,
    pointsDetails: newMatch.pointsDetails,
    redCardsTotal1: newMatch.redCardsTotal1,
    redCardsTotal2: newMatch.redCardsTotal2,
    yellowCards1: newMatch.yellowCards1,
    yellowCards2: newMatch.yellowCards2,
    corners1: newMatch.corners1,
    corners2: newMatch.corners2,
    remainingTime: newMatch.remainingTime,
    score: newMatch.score,
    server: newMatch.server,
    setScoreDetails: !_.isEmpty(newMatch.setScoreDetails)
      ? newMatch.setScoreDetails
      : [{ period: '', score: '' }],
    gameScore: newMatch.gameScore,
    legScore: newMatch.legScore,
    setScores: newMatch.setScores,
    statusDetails: {
      status: newMatch.statusDetails.status,
      translation: newMatch.statusDetails.translation,
    },
  });
  return oldMatch;
};

const updateOutcomeInBet = (mbOutcome, newOutcome) => {
  const newOddValue = newOutcome.mboOddValue;
  let oddChanged = '';
  if (mbOutcome.mboOddValue === newOddValue) {
    oddChanged = 'no';
  } else {
    oddChanged = newOddValue > mbOutcome.mboOddValue ? 'up' : 'down';
  }
  return _.assign(mbOutcome, {
    oddChanged,
    oldOdd: mbOutcome.mboOddValue,
    newOdd: newOddValue,
    mboActive: newOutcome.mboActive,
    mboType: newOutcome.mboType,
    mboOddValue: newOutcome.mboOddValue,
    mboPosition: newOutcome.mboPosition,
    mboShortDisplayName: newOutcome.mboShortDisplayName,
    mboDisplayName: newOutcome.mboDisplayName,
  });
};

const addOutcomeToBet = (baseBet, newOutcome) => {
  const baseOutcome = baseBet && !_.isEmpty(baseBet.betOutcomes)
    ? baseBet.betOutcomes[newOutcome.idBo]
    : null;
  if (!baseOutcome) return {};
  return {
    idBo: newOutcome.idBo,
    idMbo: newOutcome.idMbo,
    mboActive: newOutcome.mboActive,
    mboType: newOutcome.mboType,
    mboOddValue: newOutcome.mboOddValue,
    mboPosition: newOutcome.mboPosition,
    oddChanged: 'no',
    mboDisplayName: newOutcome.mboDisplayName,
    mboShortDisplayName: newOutcome.mboShortDisplayName,
  };
};

const updateBetInMatch = (matchBet, newBet, baseBet) => {
  _.assign(matchBet, {
    mbPosition: newBet.mbPosition || 1000,
    mbSpecialValue: newBet.mbSpecialValue,
    mostBalanced: newBet.mostBalanced,
  });
  if (_.isEmpty(newBet.mbOutcomes)) return matchBet;
  _.each(newBet.mbOutcomes, (newOutcome) => {
    let mbOutcome = _.find(matchBet.mbOutcomes, { idMbo: newOutcome.idMbo });
    if (mbOutcome) {
      mbOutcome = updateOutcomeInBet(mbOutcome, newOutcome);
    } else {
      const outcomeToAdd = addOutcomeToBet(baseBet, newOutcome);
      if (!matchBet.mbOutcomes) _.assign(matchBet, { mbOutcomes: [] });
      if (!_.isEmpty(outcomeToAdd)) matchBet.mbOutcomes.push(outcomeToAdd);
    }
  });
  return matchBet;
};

const addBetToMatch = (newBet, baseBet) => {
  const matchBet = {
    idBet: newBet.idBet,
    idMb: newBet.idMb,
    mbActive: newBet.mbActive,
    mbChanged: newBet.mbChanged,
    mbPosition: newBet.mbPosition,
    mbSpecialValue: newBet.mbSpecialValue,
    mostBalanced: newBet.mostBalanced,
    mbOutcomes: [],
  };
  if (_.isEmpty(newBet.mbOutcomes)) return matchBet;
  _.each(newBet.mbOutcomes, (newOutcome) => {
    const outcomeToAdd = addOutcomeToBet(baseBet, newOutcome);
    if (!_.isEmpty(outcomeToAdd)) matchBet.mbOutcomes.push(outcomeToAdd);
  });
  return matchBet;
};

export default {
  updateMatchDetails,
  updateBetInMatch,
  addBetToMatch,
};
