<template>
  <div class="generated-ticket-bet">
    <div
      class="generated-ticket-bet-match"
      :class="`status-${bet.status}`"
    >
      <div class="generated-ticket-bet-match-name">{{ bet.matchDisplayName }}</div>
      <div
        v-if="bet.ticketBetBank === '1' && ticketTypeId === 2"
        class="generated-ticket-bet-banker">
        {{ $translate.key('banker_symbol') }}
      </div>
    </div>

    <div class="generated-ticket-bet-time">{{ formatDate(bet.matchDateTimeUTC) }}</div>

    <div class="generated-ticket-bet-market">{{ marketName }}</div>

    <div class="generated-ticket-bet-outcome">
      {{ bet.betOutcome.betOutcomeShortName || bet.betOutcome.betOutcomeDisplayName }}
    </div>

    <div class="generated-ticket-bet-odd">
      {{ $formatOdd(bet.oddValue, oddType, oddFormatterActive) }}
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import formatter from '@/services/formatter';

export default {
  name: 'GeneratedTicketBet',
  props: {
    bet: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    ticketTypeId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { oddType, oddFormatterActive } = inject('oddTypeData');

    const modifyBet = (bet, betName) => ({
      ...bet,
      betName,
      name: betName,
      mbSpecialValue: bet.specialValue,
      grouped: bet.bet.betGroupingEnabled,
    });

    const marketName = computed(() => {
      const { bet } = props;
      const betName = bet.bet.betShortName || bet.bet.betDisplayName;

      if (!bet.specialValue || bet.specialValue === '*') return betName;

      const modifiedBet = modifyBet(bet, betName);
      return formatter.formatBetName(modifiedBet);
    });

    return {
      oddType,
      marketName,
      oddFormatterActive,
      formatDate: formatter.formatDate,
    };
  },
};
</script>
